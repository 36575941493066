import React, { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import checkIcon from 'assets/images/ic-line-check.svg';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import { Icon } from 'auto-design-common';
import { useHistory } from 'react-router-dom';
import emitter, { Events } from 'utils/event';
import { bell, error } from 'utils/sound';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import * as api from 'utils/api';
import { useModalContext } from './ModalContext';
import useTranslation from '../hooks/useTranslation';
import { useAppContext } from './AppContext';

const NotificationContext = React.createContext();

const LIMIT = 10;

const TIMEOUT = 60 * 1000;

export function NotificationContextProvider({
  children,
}) {
  const { request } = useAppContext();
  const [messages, setMessages] = useState([]);
  const history = useHistory();
  const { tNotification } = useTranslation();
  const [notifications, setNotifications] = useState([]);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: (data) => api.getNotificaitons(data.pageParam || {
      after: undefined,
      limit: LIMIT,
    }),
    initialPageParam: {
      after: undefined,
      limit: LIMIT,
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage || lastPage.length === 0) {
        return undefined;
      }

      console.log({
        lastPage,
      });

      return {
        after: lastPage[lastPage.length - 1].id,
        limit: LIMIT,
      };
    },
    refetchIntervalInBackground: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { data: unReadNotificationCountData, refetch: refetchUnreadCount } = useQuery({
    queryKey: ['unread-notification-count'],
    queryFn: () => api.getUnreadNotificationCount(),
  });

  useEffect(() => {
    if (data) {
      const newNotifications = data.pages.flat();
      setNotifications(notifications => [
        ...notifications,
        ...newNotifications.filter(n => !notifications.find(en => en.id === n.id)),
      ]);
    }
  }, [data]);

  const notificationRegistry = useMemo(() => [
    {
      type: ['floor_plan_ready', 'floor_plan_needs_extra_cost', 'floor_plan_needs_extra_files', 'floor_plan_video_ready', 'floor_plan_cad_ready'],
      onClick: (data) => {
        history.push(`/floor-plans/${data.floorPlan.id}`);
      },
      getParams: (data) => ({
        floorPlanName: data.floorPlan.name,
      }),
      notificationType: 'success',
    },
    {
      type: ['floor_plan_refunded'],
      notificaitonType: 'success',
      getParams: (data) => ({
        floorPlanName: data.floorPlan.name,
      }),
    },
    {
      type: ['render_done'],
      onClick: (data) => {
        history.push(`/images/${data.imageId}/view?progressId=${data.progressId}`);
      },
      notificationType: 'success',
    },
    {
      type: ['floor_plan_failed'],
      notificationType: 'error',
      getParams: (data) => ({
        floorPlanName: data.floorPlan.name,
        error: data.error,
      }),
    },
    {
      type: ['image_ready'],
      onClick: (data) => {
        history.push(`/images/${data.image.id}/edit`);
      },
      getParams: (data) => ({
        imageName: data.image.name,
      }),
      notificationType: 'success',
    },
    {
      type: ['image_failed'],
      notificationType: 'error',
      getParams: (data) => ({
        imageName: data.image.name,
        error: data.error,
      }),
    },
    {
      type: ['image_refunded'],
      notificaitonType: 'success',
      getParams: (data) => ({
        floorPlanName: data.image.name,
      }),
    },
  ], [history]);

  const notification = useCallback((message, type, onClick) => {
    const id = uuidv4();

    setMessages(messages => [{
      id,
      type,
      message,
      onClick,
    }, ...messages]);

    setTimeout(() => {
      setMessages(messages => messages.filter(message => message.id !== id));
    }, TIMEOUT);
  }, []);

  const notifyError = useCallback((error, onClick) => {
    notification(error.data?.message || error.message || error || 'Something went wrong', 'error', onClick);
  }, [notification]);

  const notifySuccess = useCallback((message, onClick) => {
    notification(message, 'success', onClick);
  }, [notification]);

  const extracNotification = useCallback((data) => {
    const entry = notificationRegistry.find(entry => entry.type.includes(data.type));

    if (!entry) {
      return {
        notificationType: null,
        text: null,
        onClick: null,
      };
    }

    return {
      notificationType: entry.notificationType,
      text: tNotification(data.type, entry.getParams?.(data.data)),
      onClick: async () => {
        setNotifications(notifications => notifications.map(n => {
          if (n.id === data.id) {
            return {
              ...n,
              isRead: true,
            };
          }

          return n;
        }));
        await request(api.readNotification(data.id));
        refetchUnreadCount();
        entry.onClick?.(data.data);
      },
    };
  }, [notificationRegistry, tNotification, request, refetchUnreadCount]);

  const markAllAsRead = useCallback(async () => {
    await request(api.readAllNotification());
    refetchUnreadCount();
  }, [request, refetchUnreadCount]);

  useEffect(() => {
    const listener = emitter.addListener(Events.NEW_NOTIFICATION, data => {
      const { text, onClick, notificationType } = extracNotification(data);

      if (text) {
        setNotifications(notifications => [data, ...notifications]);

        if (notificationType === 'success') {
          notifySuccess(text, onClick);
          bell.play();
        }

        if (notificationType === 'error') {
          notifyError(text, onClick);
          error.play();
        }
      }
    });

    return () => {
      listener.remove();
    };
  }, [extracNotification, notifySuccess, notifyError]);

  const contextValue = useMemo(() => ({
    messages,
    notifyError,
    notifySuccess,
    setMessages,
    notifications,
    extracNotification,
    loadMore: fetchNextPage,
    hasMore: hasNextPage,
    unreadCount: unReadNotificationCountData?.count || 0,
    markAllAsRead,
  }), [
    messages,
    notifyError,
    notifySuccess,
    setMessages,
    notifications,
    extracNotification,
    fetchNextPage,
    hasNextPage,
    unReadNotificationCountData,
    markAllAsRead,
  ]);

  return (
    <NotificationContext.Provider
      value={contextValue}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export function Notification({
  onModal,
}) {
  const { tCommon } = useTranslation();
  const { messages, setMessages } = useNotificationContext();
  const { modal } = useModalContext();

  if (modal && !onModal) {
    return null;
  }

  return (
    <div className="notification-container">
      {messages.map(message => (
        <div
          key={message.id}
          className={`notification-message ${message.type}`}
          onClick={() => {
            if (message.onClick) {
              message.onClick();
            }

            setMessages(messages => messages.filter(m => message.id !== m.id));
          }}
        >
          {message.type === 'success' && (
            <Icon src={checkIcon} />
          )}
          {message.type === 'error' && (
            <Icon
              src={xIcon}
              fill="#f5222d"
            />
          )}
          {message.message}
          <div
            className="close"
            onClick={(e) => {
              e.stopPropagation();
              setMessages(messages => messages.filter(m => message.id !== m.id));
            }}
          >
            {tCommon('close')}
          </div>
        </div>
      ))}
    </div>
  );
}
