import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FloorPlan2DEditor } from 'auto-design-editor';
import { useParams, useHistory } from 'react-router-dom';
import * as api from 'utils/api';
import { getFileUrl } from 'utils/file';
import { useAppContext } from 'components/AppContext';
import { useToastContext, dataURItoBlob } from 'auto-design-common';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useTranslation from 'hooks/useTranslation';
import { Button } from 'react-bootstrap';
import FloorPlanEditorHeader from 'components/Common/FloorPlanEditorHeader';
import { ReactComponent as SaveIcon } from 'assets/images/ic-save.svg';
import { applyStyle } from 'components/FloorPlanOverview/FloorPlan2D/utils';
import styles from './Edit2D.module.scss';

export default function Edit2D() {
  const editorRef = useRef();
  const { floorPlanId, unitId } = useParams();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { t2dEditor } = useTranslation();
  const { data: floorPlan, isLoading } = useQuery({
    queryKey: ['floorPlan', floorPlanId],
    queryFn: () => api.getFloorPlanDetails(floorPlanId),
  });
  const history = useHistory();
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);

  const { data: floorPlanStyles, isLoading: loadingStyles } = useQuery({
    queryFn: () => api.getFloorPlan2dStyles(),
    queryKey: ['floor-plan-2d-styles'],
  });

  const onSave = useCallback(async (data) => {
    const { error } = await request(api.updateUnit(floorPlanId, unitId, {
      metadata: {
        floorPlan2D: data,
      },
    }));

    if (error) {
      toastError(error);
    }
  }, [floorPlanId, unitId, request, toastError]);

  const unit = useMemo(() => {
    if (!floorPlan) {
      return null;
    }

    return floorPlan.units.find(u => u.id === parseInt(unitId));
  }, [floorPlan, unitId]);

  const handleClickSave = async () => {
    setSaving(true);
    const res = await editorRef.current.getDataUrl();

    if (!res) {
      toastError('Something went wrong');
      setSaving(false);
      return;
    }

    const { data, url } = res;

    const { error } = await request(api.updateUnit(floorPlanId, unitId, {
      metadata: {
        floorPlan2D: data,
      },
    }));

    if (error) {
      toastError(error);
      setSaving(false);
      return;
    }

    const blob = dataURItoBlob(url);
    const { error: error2, result } = await request(api.uploadFloorPlan2dOutputFile(floorPlanId, unitId, blob));

    if (error2) {
      toastError(error2);
      setSaving(false);
      return;
    }

    if (floorPlan.metadata.styleId) {
      const style = floorPlanStyles.find(s => s.id === floorPlan.metadata.styleId);

      if (style) {
        const styledBlob = await applyStyle(result.fileId, style);
        await request(api.uploadFloorPlan2dStyledOutputFile(floorPlanId, unitId, styledBlob));
      }
    } else {
      await request(api.uploadFloorPlan2dStyledOutputFile(floorPlanId, unitId, blob));
    }

    queryClient.invalidateQueries({
      queryKey: ['floorPlan', floorPlanId],
    });
    history.push(`/floor-plans/${floorPlanId}`);
  };

  if (isLoading || !floorPlan || !unit || loadingStyles) {
    return null;
  }

  console.log(getFileUrl(unit.background_2dFileId));

  return (
    <div>
      <div className={styles.pageHeader}>
        <div className={styles.logoContainer}>
          <FloorPlanEditorHeader
            floorPlanId={floorPlanId}
            name={t2dEditor('2dEditor')}
            unitId={unitId}
          />
          {/* <EditorHeader
            className={styles.logo}
            imageId={floorPlanId}
            name={t2dEditor('2dEditor')}
          /> */}
          <Button
            onClick={handleClickSave}
            className={styles.saveButton}
            disabled={saving}
          >
            <SaveIcon width={16} height={16} />
            {t2dEditor('save')}
          </Button>
        </div>
      </div>
      <div
        className={styles.editorContainer}
      >
        <FloorPlan2DEditor
          ref={editorRef}
          backgroundUrl={getFileUrl(unit.background_2dFileId)}
          onSave={onSave}
          initialData={unit.metadata.floorPlan2D}
        />
      </div>
    </div>
  );
}
