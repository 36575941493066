import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { ShepherdTour } from 'react-shepherd';
import classNames from 'classnames';
import { ImageType, useToastContext, Icon } from 'auto-design-common';
import { useQueryParam, NumberParam, BooleanParam } from 'use-query-params';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import ResultViewer from './ResultViewer';
import ResultEditor from './ResultEditor';
import { tourSteps, tourOptions } from './tour';
import useTranslation from '../../hooks/useTranslation';
import FloorPlanResultEditor from './FloorPlanResultEditor';

export function Result() {
  const { tImagesResult } = useTranslation();
  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(null);
  const [progressId, setProgressId] = useQueryParam('progressId', NumberParam);
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const [showHistory, setShowHistory] = useState(false);
  const historyItemsRef = useRef();
  const [editing = false, setEditing] = useQueryParam('editing', BooleanParam);
  const [dirty, setDirty] = useState(false);

  const currentIndex = useMemo(() => image?.progresses.findIndex(p => p.id === progressId), [image?.progresses, progressId]);

  const nextIndex = useMemo(() => {
    if (!progressId) {
      return null;
    }

    if (!(image?.progresses?.length > 0)) {
      return null;
    }

    if (currentIndex === image.progresses.length - 1) {
      return null;
    }

    return currentIndex + 1;
  }, [image, progressId, currentIndex]);

  const prevIndex = useMemo(() => {
    if (!progressId) {
      return null;
    }

    if (!(image?.progresses?.length > 0)) {
      return null;
    }

    if (currentIndex === 0) {
      return null;
    }

    return currentIndex - 1;
  }, [image, progressId, currentIndex]);

  const next = () => {
    if (nextIndex === null) {
      return;
    }

    setProgressId(image.progresses[nextIndex].id);
  };

  const prev = () => {
    if (prevIndex === null) {
      return;
    }
    setProgressId(image.progresses[prevIndex].id);
  };

  const fetchProgress = useCallback(() => {
    if (!progressId) {
      return;
    }

    (async () => {
      const { success, error, result } = await request(api.getProgress(progressId));

      if (success) {
        setProgress(result);
      } else {
        toastError(error);
      }
    })();
  }, [request, progressId, toastError]);

  const save = async (data) => {
    const { success, error } = await request(api.updateFurnitureLayer(progressId, data));

    if (!success) {
      toastError(error);
    } else {
      await fetchProgress();
      setEditing(false);
    }
  };

  useEffect(() => {
    (async () => {
      const { success, error, result } = await request(api.getImage(imageId));

      if (success) {
        setImage(result);
        setProgressId(progressId => progressId || result.progresses[0]?.id);
      } else {
        toastError(error);
      }
    })();
  }, [request, imageId, toastError, setProgressId]);

  useEffect(() => {
    fetchProgress();
  }, [fetchProgress]);

  useEffect(() => {
    setDirty(false);
  }, [editing, progressId]);

  if (!image) {
    return null;
  }

  if (!progress) {
    return null;
  }

  return (
    <div className={classNames('result', showHistory && 'show-history')}>
      {!editing && (
        <ResultViewer
          image={image}
          progress={progress}
          prevIndex={prevIndex}
          nextIndex={nextIndex}
          currentIndex={currentIndex}
          prev={prev}
          next={next}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          historyItemsRef={historyItemsRef}
          setEditing={setEditing}
        />
      )}

      {editing && image.type !== ImageType.FLOOR_PLAN && (
        <ResultEditor
          image={image}
          progress={progress}
          setEditing={setEditing}
          setShowHistory={setShowHistory}
          setDirty={setDirty}
          save={save}
        />
      )}

      {editing && image.type === ImageType.FLOOR_PLAN && (
        <FloorPlanResultEditor
          image={image}
          progress={progress}
          setEditing={setEditing}
          setShowHistory={setShowHistory}
          setDirty={setDirty}
          save={save}
        />
      )}

      <div className="history">
        <div className="history-header">
          <div className="history-title">{tImagesResult('historyTitle')}</div>
          <div
            className="close-history-button"
            onClick={() => {
              setShowHistory(false);
            }}
          >
            <Icon
              src={xIcon}
              fill="#212529"
            />
          </div>
        </div>
        <div
          ref={historyItemsRef}
          className="history-items"
        >
          {image.progresses.map(p => (
            <div
              key={p.id}
              className={classNames('history-item', p.id === progressId && 'active')}
              onClick={() => {
                if (editing && dirty) {
                  showModal(ModalKey.CONFIRM_SWITCHING_VERSION, {
                    onAccept: () => {
                      setProgressId(p.id);
                    },
                  });
                } else {
                  setProgressId(p.id);
                }
              }}
              id={`progress-${progress.id}`}
            >
              <img alt="Thumbnail" src={p.thumbnail} />
              <div className="history-item-name">
                {moment.utc(p.createdAt).local().format('MM-DD-yyyy HH:mm')}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ResultWrapper() {
  return (
    <ShepherdTour steps={tourSteps} tourOptions={tourOptions}>
      <Result />
    </ShepherdTour>
  );
}

export default ResultWrapper;
