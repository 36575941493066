import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'auto-design-common';
import * as api from 'utils/api';
import emitter, { Events } from 'utils/event';
import { useAppContext } from './AppContext';
import { useNotificationContext } from './Notification';

const ProgressContext = React.createContext();

export function ProgressContextProvider({
  children,
}) {
  const history = useHistory();
  const { user, request } = useAppContext();
  const { notifyError, notifySuccess } = useNotificationContext();
  const { toastError } = useToastContext();
  const [progresses, setProgresses] = useState([]);
  const [loading, setLoading] = useState(true);

  const markProgressAsRead = useCallback(async (progressId) => request(api.markProgressAsRead(progressId)), [request]);

  const markAllProgressesAsRead = useCallback(async () => {
    const res = await request(api.markAllProgressesAsRead());
    setProgresses([]);
    return res;
  }, [request]);

  useEffect(() => {
    if (!user) {
      return () => {};
    }

    const getProgresses = async () => {
      setLoading(true);

      const { result: progresses, error } = await request(api.getProgresses());

      if (progresses) {
        setProgresses(progresses);
      } else {
        toastError(error);
      }

      setLoading(false);
    };

    getProgresses();

    const listener1 = emitter.addListener(Events.PROGRESS_ADDED, () => {
      getProgresses();
    });

    const listener2 = emitter.addListener(Events.PROGRESS_UPDATED, () => {
      getProgresses();
    });
    const listener3 = emitter.addListener(Events.LOCATION_DELETED, getProgresses);

    return () => {
      listener1.remove();
      listener2.remove();
      listener3.remove();
      setProgresses([]);
    };
  }, [toastError, user, request, notifySuccess, notifyError, history]);

  const contextValue = useMemo(() => ({
    progresses,
    loading,
    markProgressAsRead,
    markAllProgressesAsRead,
  }), [
    progresses,
    loading,
    markProgressAsRead,
    markAllProgressesAsRead,
  ]);

  return (
    <ProgressContext.Provider value={contextValue}>
      {children}
    </ProgressContext.Provider>
  );
}

export function useProgressContext() {
  return React.useContext(ProgressContext);
}
