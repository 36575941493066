import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { getLocation } from 'utils/api';
import { useToastContext, Icon, ImageType } from 'auto-design-common';
import penIcon from 'assets/images/ic-line-pen.svg';
import eyeIcon from 'assets/images/ic-line-eye.svg';
import { ModalKey } from 'constants/modal';
import { useModalContext } from 'components/ModalContext';
import classNames from 'classnames';
import emitter, { Events } from 'utils/event';
import threeDotIcon from 'assets/images/ic-fill-3dot-vertical.svg';
import panoMark from 'assets/images/360-mark.svg';
import CreateTourButton from './CreateTourButton';
import useTranslation from '../../hooks/useTranslation';

export default function Location() {
  const { tLocations, tCommon } = useTranslation();
  const history = useHistory();
  const { showModal } = useModalContext();
  const { locationId } = useParams();
  const [location, setLocation] = useState(null);
  const { toastError } = useToastContext();

  const numOfRenders = useMemo(() => {
    if (!location) {
      return 0;
    }

    let sum = 0;

    location.images.forEach(image => {
      sum += image.progresses.length;
    });

    return sum;
  }, [location]);

  const fetchLocation = useCallback(async () => getLocation(locationId)
    .then(setLocation)
    .catch(toastError), [locationId, toastError]);

  useEffect(() => {
    fetchLocation();
  }, [fetchLocation]);

  useEffect(() => {
    const listener1 = emitter.addListener(Events.LOCATION_UPDATED, fetchLocation);
    const listener2 = emitter.addListener(Events.LOCATION_DELETED, id => {
      if (id === parseInt(locationId)) {
        history.push('/locations');
      }
    });

    return () => {
      listener1.remove();
      listener2.remove();
    };
  }, [locationId, history, fetchLocation]);

  if (!location) {
    return null;
  }

  return (
    <div className="location">
      <div className="header">
        <div className="header-left">
          <div className="title">
            {location.name}
          </div>
          <div className="info">
            <div className="images">
              {location.images.length}
              {' '}
              {tCommon('images')}
            </div>
            <div className="separator" />
            <div className="renders">
              {numOfRenders}
              {' '}
              {tCommon('renders')}
            </div>

          </div>
        </div>
        <CreateTourButton locationId={location.id} />
      </div>
      <div className="image-list row">
        {location.images.map(image => (
          <div className="col-xxl-3 col-lg-4 col-md-6" key={image.id}>
            <div className="image">
              <Dropdown>
                <Dropdown.Toggle className="options">
                  <img src={threeDotIcon} alt="options" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={image.progresses.length === 0}
                    onClick={() => {
                      showModal(ModalKey.VERSION_HISTORY, {
                        imageId: image.id,
                      });
                    }}
                  >
                    {tLocations('versionHistory')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      showModal(ModalKey.EDIT_IMAGE, {
                        image: {
                          ...image,
                          location: {
                            id: location.id,
                            name: location.name,
                          },
                        },
                        onClose: () => {
                          fetchLocation();
                        },
                      });
                    }}
                  >
                    {tLocations('editImage')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="" />
              {image.type === ImageType.PANO && (
              <img
                className="mark"
                src={panoMark}
                alt="360-degree"
              />
              )}
              <img src={image.thumbnail} alt={image.name} />
              <div className="name">
                {image.name}
              </div>
              <div className="renders">
                {image.progresses.length}
                {' '}
                {tCommon('renders')}
              </div>
              <div className="separator" />
              <div className="buttons">
                {image.type === ImageType.FLOOR_PLAN ? (
                  <div
                    className="button"
                    onClick={() => {
                      history.push(`/images/${image.id}/overview`);
                    }}
                  >
                    <Icon src={penIcon} fill="#212529" />
                    {tCommon('open')}
                  </div>
                ) : (
                  <div
                    className="button"
                    onClick={() => {
                      history.push(`/images/${image.id}/edit`);
                    }}
                  >
                    <Icon src={penIcon} fill="#212529" />
                    {tCommon('edit')}
                  </div>
                )}
                <div className="buttons-separator" />
                <div
                  className={classNames(
                    'button',
                    image.progresses.length === 0 && 'disabled',
                  )}
                  onClick={() => {
                    if (image.progresses.length > 0) {
                      history.push(`/images/${image.id}/view`);
                    }
                  }}
                >
                  <Icon
                    src={eyeIcon}
                    fill="#212529"
                  />
                  {tCommon('view')}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
