import { FloorPlanFilesProvider, ContinueButtonWrapper, FloorPlanFiles } from 'components/Common/FloorPlanFiles';
import { useParams } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import React, { useEffect, useMemo, useState } from 'react';
import { useToastContext, FloorPlanFileSource } from 'auto-design-common';
import { Button, Form } from 'react-bootstrap';
import * as api from 'utils/api';
import { getFileUrl } from 'utils/file';
import { useAppContext } from 'components/AppContext';
import { useQuery } from '@tanstack/react-query';
import styles from './FloorPlanInputFiles.module.scss';
import useFloorPlan from './useFloorPlan';

export default function FloorPlanInputFiles({
  readOnly,
}) {
  const { floorPlanId } = useParams();
  const { useTranslationOrigin, tCreateFloorPlan } = useTranslation();
  const { t: tFloorPlanInputFiles } = useTranslationOrigin('translation', { keyPrefix: 'floorplanOverview.floorPlanInputFiles' });
  const { data: allServerFiles, refetch } = useQuery({
    queryKey: ['floor-plan', floorPlanId, 'floor-plan-files'],
    queryFn: () => api.getFloorPlanFiles(floorPlanId),
  });
  const { refetchFloorPlan, floorPlan } = useFloorPlan({
    floorPlanId,
  });
  const { request } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [notes, setNotes] = useState('');

  const serverFiles = useMemo(() => allServerFiles?.filter(f => f.source === FloorPlanFileSource.USER_UPLOAD) || [], [allServerFiles]);

  useEffect(() => {
    if (!serverFiles) {
      return;
    }

    const urls = serverFiles.filter(f => f.type === 'url')
      .map(u => ({
        id: u.id,
        url: u.fileUrl,
        readOnly: true,
      }));
    setUrls(urls);

    const files = serverFiles.filter(f => f.type !== 'url')
      .map(f => ({
        id: f.id,
        file: {
          name: f.name,
        },
        downloadUrl: getFileUrl(f.fileId),
        readOnly: true,
      }));
    setFiles(files);
  }, [serverFiles]);

  useEffect(() => {
    setNotes(floorPlan?.notes || '');
  }, [floorPlan?.notes]);

  const onSave = async () => {
    setSubmitting(true);

    try {
      // eslint-disable-next-line guard-for-in
      for (const file of files) {
        if (!file.readOnly) {
          // eslint-disable-next-line no-await-in-loop
          const { key } = await api.uploadFile(file.file, false);
          // eslint-disable-next-line no-await-in-loop
          const { error } = await request(api.uploadFloorPlanFile(floorPlanId, {
            fileKey: key,
            fileType: file.file.type,
            fileName: file.file.name,
          }));

          if (error) {
            throw error;
          }
        }
      }

      // eslint-disable-next-line guard-for-in
      for (const url of urls) {
        if (!url.readOnly) {
          // eslint-disable-next-line no-await-in-loop
          const { error } = await request(api.uploadFloorPlanFileUrl(floorPlanId, url.url));
          if (error) {
            throw error;
          }
        }
      }

      await request(api.endFileRequest(floorPlanId, {
        userNotes: notes,
      }));

      toastSuccess(tFloorPlanInputFiles('saveSuccess'));
    } catch (e) {
      toastError(e);
    }

    refetchFloorPlan();
    refetch();
    setSubmitting(false);
  };

  return (

    <FloorPlanFilesProvider
      urls={urls}
      files={files}
      setUrls={setUrls}
      setFiles={setFiles}
    >
      <div className={styles.inputWrapper}>
        <FloorPlanFiles readOnly={readOnly} />
      </div>

      <div className={styles.notes}>
        <Form.Group>
          <Form.Label>{tCreateFloorPlan('information.notes')}</Form.Label>
          <Form.Control
            readOnly={readOnly}
            value={notes}
            as="textarea"
            rows={5}
            onChange={e => {
              setNotes(e.target.value);
            }}
          />
        </Form.Group>
      </div>

      {!readOnly && (
      <ContinueButtonWrapper>
        {({ disabled }) => (
          <Button
            disabled={disabled || submitting}
            className={styles.continueButton}
            onClick={() => {
              onSave();
            }}
          >
            {tFloorPlanInputFiles('save')}
          </Button>
        )}
      </ContinueButtonWrapper>
      )}

    </FloorPlanFilesProvider>

  );
}
