import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import threeDotIcon from 'assets/images/ic-fill-3dot-vertical.svg';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import penIcon from 'assets/images/ic-line-pen.svg';
import eyeIcon from 'assets/images/ic-line-eye.svg';
import { Icon } from 'auto-design-common';
import classNames from 'classnames';
import useTranslation from 'hooks/useTranslation';
import styles from './FloorPlan3D.module.scss';
import useFloorPlan from './useFloorPlan';

export default function FloorPlan3D() {
  const { tLocations, tCommon } = useTranslation();
  const { floorPlanId } = useParams();

  const { floorPlan } = useFloorPlan({ floorPlanId });

  const history = useHistory();
  const { showModal } = useModalContext();

  const units = useMemo(() => {
    if (!floorPlan) {
      return [];
    }

    return floorPlan.units.filter(u => !!u.image);
  }, [floorPlan]);

  return (
    <div className="row">
      {units.map(unit => (
        <div className="col-xxl-3 col-lg-4 col-md-6" key={unit.id}>
          <div className={styles.image}>
            <Dropdown>
              <Dropdown.Toggle className={styles.options}>
                <img src={threeDotIcon} alt="options" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={unit.image.progresses.length === 0}
                  onClick={() => {
                    showModal(ModalKey.FLOOR_PLAN_3D_VERSION_HISTORY, {
                      imageId: unit.image.id,
                    });
                  }}
                >
                  {tLocations('versionHistory')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {unit.image.thumbnail ? (
              <img src={unit.image.thumbnail} alt={unit.name} />
            ) : (
              <div
                style={{
                  height: 184,
                }}
              />
            )}

            <div className={styles.name}>
              {unit.name}
            </div>
            <div className={styles.renders}>
              {unit.image.progresses.length}
              {' '}
              {tCommon('renders')}
            </div>
            <div className={styles.separator} />
            <div className={styles.buttons}>
              <div
                className={styles.button}
                onClick={() => {
                  history.push(`/images/${unit.image.id}/edit`);
                }}
              >
                <Icon src={penIcon} fill="#212529" />
                {tCommon('edit')}
              </div>
              <div className={styles.buttonSeparator} />
              <div
                className={classNames(
                  styles.button,
                  unit.image.progresses.length === 0 && styles.disabled,
                )}
                onClick={() => {
                  if (unit.image.progresses.length > 0) {
                    history.push(`/images/${unit.image.id}/view`);
                  }
                }}
              >
                <Icon
                  src={eyeIcon}
                  fill="#212529"
                />
                {tCommon('view')}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
