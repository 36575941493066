import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import * as THREE from 'three';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useResizeDetector } from 'react-resize-detector';
import * as api from 'utils/api';
import { useToastContext, Icon, withMinimumScreenWidth } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import { TOUR_ZOOM_DURATION } from 'constants/common';
import { setupTourEditor, createAnimator, calculateMousePosition, createHotpot, zoomTo, removeAllHotpots } from 'utils/tourEditorHelper';
import config from 'configuration';
import { getToken } from 'utils/auth';
import ButtonTooltip from 'components/Common/ButtonTooltip';
import { Button } from 'react-bootstrap';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import hotpotIcon from 'assets/images/ic-line-hotpot.svg';
import locationIcon from 'assets/images/ic-line-location.svg';
import playIcon from 'assets/images/ic-fill-play.svg';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useHideTawkWidget } from 'components/TawkContext';
import { getEventCoords } from 'utils/mouse';
import PanoZoomButtons from 'components/Common/PanoZoomButtons';
import SelectLinkedImage from './SelectLinkedImage';
import VRButton from '../VRButton';
import useTranslation from '../../../hooks/useTranslation';

const AddingHotpotPhase = {
  SELECT_LINKED_IMAGE: 'SELECT_LINKED_IMAGE',
  SELECT_POSITION: 'SELECT_POSITION',
};

const Mode = {
  PREVIEW: 'PREVIEW',
  EDIT: 'EDIT',
};
function TourEditor() {
  const { tTourEditor, tCommon, tError } = useTranslation();
  useHideTawkWidget();
  const history = useHistory();
  const { showModal } = useModalContext();
  const { toastError, toastSuccess } = useToastContext();
  const { request } = useAppContext();
  const { tourId } = useParams();
  const [tour, setTour] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [webGLError, setWebGLError] = useState(null);
  const containerRef = useRef();
  const canvasData = useRef({});
  const [renderer, setRenderer] = useState(null);
  const { ref: backgroundRef, height, width } = useResizeDetector();
  const [addingHotpotPhase, setAddingHotpotPhase] = useState(null);
  const [hotpotFlatPosition, doSetHotpotFlatPosition] = useState({ x: -999, y: -999 });
  const linkedImageRef = useRef(null);
  const [selectedHotpot, setSelectedHotpot] = useState(null);
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [mode, setMode] = useState(Mode.EDIT);

  const renderedImages = tour?.renderedImages;

  const addingHotpot = addingHotpotPhase !== null;

  const setHotpotFlatPosition = useCallback((e) => {
    const { x, y } = getEventCoords(e);

    doSetHotpotFlatPosition({
      x: x - containerRef.current.getBoundingClientRect().left,
      y: y - containerRef.current.getBoundingClientRect().top,
    });
  }, []);

  const selectedImage = useMemo(() => {
    if (!tour) {
      return null;
    }

    return tour.renderedImages.find(image => image.id === selectedImageId);
  }, [selectedImageId, tour]);

  const deleteHotpot = useCallback(() => {
    setDirty(true);
    setTour(tour => ({
      ...tour,
      metadata: {
        ...tour.metadata,
        hotpots: tour.metadata.hotpots.filter(h => h.id !== selectedHotpot.hotpot.id),
      },
    }));
    setSelectedHotpot(null);
  }, [selectedHotpot]);

  const goToDestination = useCallback(async (predefinedHotpot = null) => {
    const hotpot = predefinedHotpot || selectedHotpot.hotpot;
    setSelectedHotpot(null);

    removeAllHotpots(canvasData);

    if (canvasData.current.renderer.xr.isPresenting) {
      setSelectedImageId(hotpot.destination);
    } else {
      setTimeout(() => {
        setSelectedImageId(hotpot.destination);
      }, TOUR_ZOOM_DURATION * 1000);
      await zoomTo(hotpot.sphere.position, canvasData);
    }
  }, [selectedHotpot]);

  const saveDraft = useCallback(async () => {
    setSaving(true);

    const { success, error } = await request(api.saveTour(tour.id, {
      metadata: tour.metadata,
    }));

    if (!success) {
      toastError(error);
    } else {
      setDirty(false);
      toastSuccess('Save draft successfully.');
    }

    setSaving(false);
  }, [tour, request, toastError, toastSuccess]);

  const publish = useCallback(async () => {
    setSaving(true);

    await saveDraft();

    const { success, error, result } = await request(api.publishTour(tour.id));

    if (!success) {
      toastError(error);
    } else {
      showModal(ModalKey.TOUR_PUBLISH_SUCCESSFULLY, {
        url: `${window.origin}/published-tours/${result.slug}`,
      });
    }

    setSaving(false);
  }, [request, tour, toastError, showModal, saveDraft]);

  useEffect(() => {
    request(api.getTour(tourId))
      .then(({ success, result, error }) => {
        if (!success) {
          toastError(error);
        } else {
          setTour(result);
          setSelectedImageId(result.renderedImages[0].id);
        }
      });
  }, [tourId, request, toastError]);

  useEffect(() => {
    if (!renderedImages) {
      return null;
    }

    const { animate, cancel } = createAnimator();

    let canvas;
    let cancelled = false;

    (async () => {
      try {
        const images = {};

        renderedImages.forEach(image => {
          images[image.id] = `${config.apiUrl}/files/${image.outputFileId}?accessToken=${getToken()}`;
        });

        const data = await setupTourEditor({
          images,
          width,
          height,
        });

        if (!cancelled) {
          canvas = data.renderer.domElement;
          containerRef.current?.appendChild(canvas);

          canvasData.current = {
            ...canvasData.current,
            ...data,
          };

          setRenderer(data.renderer);

          if (mode === Mode.PREVIEW) {
            data.renderer.xr.enabled = true;
          }

          animate({
            canvasData,
          });
        }
      } catch (e) {
        setWebGLError(e);
      }
    })();

    return () => {
      cancel();
      cancelled = true;
      const scene = canvasData.current?.scene;

      if (scene) {
        for (let i = scene.children.length - 1; i >= 0; i--) {
          const obj = scene.children[i];
          scene.remove(obj);
        }
      }

      canvasData.current?.renderer?.dispose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      canvas && containerRef.current?.removeChild(canvas);
    };
  }, [renderedImages, width, height, mode]);

  useEffect(() => {
    if (!renderer) {
      return;
    }

    const { spheres } = canvasData.current;

    Object.keys(spheres).forEach(id => {
      const sphere = spheres[id];
      sphere.visible = parseInt(id) === selectedImageId;
    });
  }, [selectedImageId, renderer]);

  useEffect(() => {
    if (!renderer) {
      return () => { };
    }

    const hotpots = tour.metadata.hotpots?.filter(hotpot => hotpot.source === selectedImage.id) || [];

    hotpots
      .forEach(hotpot => {
        const [x, y, z] = hotpot.position;
        createHotpot({
          id: hotpot.id,
          position: new THREE.Vector3(x, y, z),
          source: hotpot.source,
          destination: hotpot.destination,
          canvasData,
        });
      });

    return () => {
      removeAllHotpots(canvasData);
    };
  }, [selectedImage, tour, renderer]);

  useEffect(() => {
    renderer?.setSize(width, height);
  }, [width, height, renderer]);

  useEffect(() => {
    if (!renderer || !selectedImage || webGLError) {
      return () => { };
    }

    const { panoViewer, controllers } = canvasData.current;

    const onMouseMove = (e) => {
      panoViewer.handleMouseMove(e);

      if (addingHotpot) {
        setHotpotFlatPosition(e);
      }

      e.preventDefault();
      e.stopPropagation();
    };

    const onMouseDown = (e) => {
      setSelectedHotpot(null);

      const { raycaster, camera, scene, hotpots } = canvasData.current;

      const { x, y } = calculateMousePosition({
        event: e,
        containerRef,
        width,
        height,
      });

      raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

      const placeHolders = hotpots
        .filter(hotpot => hotpot.source === selectedImage.id)
        .map(hotpot => hotpot.placeHolder);

      const intersects = raycaster.intersectObjects(placeHolders);

      if (intersects.length > 0) {
        const placeholder = intersects[0].object;
        const hotpot = canvasData.current.hotpots.find(h => h.id === placeholder.objectId);
        const { x, y } = getEventCoords(e);
        const containerRect = containerRef.current.getBoundingClientRect();
        if (mode === Mode.EDIT) {
          setSelectedHotpot({
            hotpot,
            x: x - containerRect.left,
            y: y - containerRect.top,
          });
        } else {
          goToDestination(hotpot);
        }
      } else if (!addingHotpot) {
        panoViewer.handleMouseDown(e);
      } else if (linkedImageRef.current) {
        const allMeshes = [];

        scene.traverse(obj => {
          if (obj.type === 'Mesh') {
            allMeshes.push(obj);
          }
        });

        const intersects = raycaster.intersectObjects(allMeshes);

        if (intersects.length > 0) {
          const point = intersects[0].point;
          setDirty(true);
          setTour(tour => ({
            ...tour,
            metadata: {
              ...tour.metadata,
              hotpots: [
                ...(tour.metadata.hotpots || []),
                {
                  id: uuidv4(),
                  source: selectedImage.id,
                  destination: linkedImageRef.current.id,
                  position: [point.x, point.y, point.z],
                },
              ],
            },
          }));
          setAddingHotpotPhase(null);
        }
      }
    };

    const onDocumentMouseWheel = (event) => {
      event.preventDefault();
      const { camera } = canvasData.current;
      camera.fov = panoViewer.calculateFov(event, camera.fov);
      camera.updateProjectionMatrix();
    };

    const onMouseUp = () => {
      panoViewer.handleMouseUp();
    };

    const xrOnSelectEnd = (event) => {
      const controller = event.target;
      const tempMatrix = new THREE.Matrix4();
      tempMatrix.identity().extractRotation(controller.matrixWorld);
      const { controllerRaycaster, hotpots } = canvasData.current;
      controllerRaycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      controllerRaycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
      const intersects = controllerRaycaster.intersectObjects(hotpots.map(h => h.placeHolder), false);

      if (intersects.length > 0) {
        const placeHolder = intersects[0].object;
        const hotpot = canvasData.current.hotpots.find(h => h.id === placeHolder.objectId);
        goToDestination(hotpot);
      }
    };

    containerRef.current.addEventListener('mousemove', onMouseMove, false);
    containerRef.current.addEventListener('mousedown', onMouseDown, false);
    containerRef.current.addEventListener('mouseup', onMouseUp, false);
    containerRef.current.addEventListener('touchmove', onMouseMove, false);
    containerRef.current.addEventListener('touchstart', onMouseDown, false);
    containerRef.current.addEventListener('touchend', onMouseUp, false);
    containerRef.current.addEventListener('touchcancel', onMouseUp, false);
    containerRef.current.addEventListener('wheel', onDocumentMouseWheel, false);

    controllers.forEach(controller => {
      controller.addEventListener('selectend', xrOnSelectEnd);
    });

    return () => {
      containerRef.current?.removeEventListener('mousemove', onMouseMove, false);
      containerRef.current?.removeEventListener('mousedown', onMouseDown, false);
      containerRef.current?.removeEventListener('mouseup', onMouseUp, false);
      containerRef.current?.removeEventListener('touchmove', onMouseMove, false);
      containerRef.current?.removeEventListener('touchstart', onMouseDown, false);
      containerRef.current?.removeEventListener('touchend', onMouseUp, false);
      containerRef.current?.removeEventListener('touchcancel', onMouseUp, false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeEventListener('wheel', onDocumentMouseWheel, false);

      controllers.forEach(controller => {
        controller.removeEventListener('selectend', xrOnSelectEnd);
      });
    };
  }, [renderer, addingHotpot, setHotpotFlatPosition, height, width, selectedImage, webGLError, goToDestination, mode]);

  useEffect(() => {
    setSelectedHotpot(null);
  }, [selectedImageId]);

  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        setAddingHotpotPhase(null);
      }
    });
  }, []);

  useEffect(() => {
    if (addingHotpotPhase === null) {
      linkedImageRef.current = null;
    }
  }, [addingHotpotPhase]);

  if (!tour) {
    return null;
  }

  return (
    <div className="tour-editor">
      {mode === Mode.EDIT && (
        <div className="header">
          <div className="info">
            <div className="title">
              {tour.name}
            </div>
            <div className="date">
              {moment.utc(tour.createdAt).local().format('MM-DD-yyyy HH:mm')}
            </div>
          </div>
          <div className="buttons">
            <div
              className="back"
              onClick={() => {
                history.push('/tours');
              }}
            >
              <Icon
                src={xIcon}
                fill="#212529"
              />
            </div>
          </div>
        </div>
      )}
      <div className="content">
        <div className={classNames('editor', mode === Mode.PREVIEW && 'preview')}>
          {addingHotpotPhase === AddingHotpotPhase.SELECT_POSITION && (
            <div
              className="add-hotpot-cursor"
              style={{
                top: hotpotFlatPosition.y - 24,
                left: hotpotFlatPosition.x - 24,
              }}
            >
              <Icon
                src={hotpotIcon}
              />
            </div>
          )}

          {mode === Mode.EDIT && (
            <div className="image-actions">
              <ButtonTooltip
                show={addingHotpot ? false : undefined}
                tooltip="Create a link to another photo"
              >
                <div
                  className="action add-hotpot"
                  onClick={() => {
                    setAddingHotpotPhase(AddingHotpotPhase.SELECT_LINKED_IMAGE);
                  }}
                  style={{
                    opacity: addingHotpotPhase === AddingHotpotPhase.SELECT_POSITION ? 0 : 1,
                  }}
                >
                  <Icon
                    src={hotpotIcon}
                  />
                </div>
              </ButtonTooltip>
              <ButtonTooltip
                tooltip="Make this photo the entry point of the tour"
              >
                <div
                  className="action set-entry"
                  onClick={() => {
                    if (tour.metadata.entry !== selectedImageId) {
                      setDirty(true);
                      setTour(tour => ({
                        ...tour,
                        metadata: {
                          ...tour.metadata,
                          entry: selectedImageId,
                        },
                      }));
                    }
                  }}
                >
                  <Icon src={locationIcon} fill={tour.metadata.entry === selectedImageId ? 'white' : undefined} />
                </div>
              </ButtonTooltip>
            </div>
          )}

          {mode === Mode.PREVIEW && (
            <div
              className="exit-preview-btn"
              onClick={() => {
                setMode(Mode.EDIT);
              }}
            >
              {tTourEditor('exitPreviewButton')}
            </div>
          )}

          <div
            className="pano-viewer"
            ref={backgroundRef}
          >
            <PanoZoomButtons
              onZoomIn={() => {
                canvasData.current.panoViewer.zoomIn(canvasData.current.camera);
              }}
              onZoomOut={() => {
                canvasData.current.panoViewer.zoomOut(canvasData.current.camera);
              }}
            />
            {webGLError ? (
              <div className="error">
                <div className="title">
                  {tError('webGLError.title')}
                </div>
                <div className="description">
                  {tError('webGLError.descriptionPrefix')}
                  {' '}
                  {webGLError.message}
                </div>
              </div>
            ) : (
              <div
                ref={containerRef}
                className="viewer"
                style={{
                  opacity: addingHotpot ? 0.5 : 1,
                }}
              />
            )}
            {mode === Mode.PREVIEW && (
              <VRButton renderer={renderer} />
            )}
          </div>
          {addingHotpotPhase === AddingHotpotPhase.SELECT_LINKED_IMAGE && (
            <SelectLinkedImage
              renderedImages={tour.renderedImages.filter(image => image.id !== selectedImageId)}
              onSelect={(e, image) => {
                setAddingHotpotPhase(AddingHotpotPhase.SELECT_POSITION);
                setHotpotFlatPosition(e);
                linkedImageRef.current = image;
              }}
              onCancel={() => {
                setAddingHotpotPhase(null);
              }}
            />
          )}
          {selectedHotpot && (
            <div
              className="hotpot-actions"
              style={{
                top: selectedHotpot.y,
                left: selectedHotpot.x,
              }}
            >
              <div
                className="action"
                onClick={() => goToDestination()}
              >
                {tCommon('go')}
              </div>
              <div
                className="action negative"
                onClick={deleteHotpot}
              >
                {tCommon('delete')}
              </div>
            </div>
          )}
        </div>
        {mode === Mode.EDIT && (
          <div className="images">
            {tour.renderedImages.map(image => (
              <div
                key={image.id}
                className={classNames('image', image.id === selectedImageId && 'selected')}
                onClick={() => {
                  setSelectedImageId(image.id);
                }}
              >
                <div className="thumbnail">
                  <img
                    src={image.thumbnail}
                    alt={image.name}
                  />
                  {image.id === tour?.metadata.entry && (
                    <div className="is-entry">
                      <Icon
                        src={locationIcon}
                        fill="white"
                      />
                    </div>
                  )}
                </div>
                <div className="title">
                  {image.name}
                </div>
              </div>
            ))}
          </div>
        )}

      </div>
      {mode === Mode.EDIT && (
        <div className="buttons">
          <Button
            variant="white"
            style={{
              minWidth: 0,
              marginLeft: 12,
            }}
            onClick={() => {
              setMode(Mode.PREVIEW);
              setSelectedImageId(tour.metadata.entry);
            }}
          >
            <Icon
              src={playIcon}
            />
            {tTourEditor('previewTour')}
          </Button>

          <div className="right">
            <Button
              variant="secondary"
              className="save-draft"
              onClick={() => {
                saveDraft();
              }}
              disabled={saving || !dirty}
            >
              {tTourEditor('saveDraft')}
            </Button>
            <Button
              variant="primary"
              className="publish"
              disabled={saving}
              onClick={() => {
                publish();
              }}
            >
              {tCommon('publish')}
            </Button>
          </div>
        </div>
      )}

    </div>
  );
}

export default withMinimumScreenWidth(768)(TourEditor);
