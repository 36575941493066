import Konva from 'konva';
import { getFileUrl } from 'utils/file';
import { dataURItoBlob } from 'auto-design-common';

const loadImage = (url) => new Promise(resolve => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    resolve(img);
  };
  img.src = url;
});

export const applyStyle = async (outputFileId, style) => {
  const container = document.createElement('DIV');
  document.body.appendChild(container);
  const img = await loadImage(getFileUrl(outputFileId));
  const { width, height } = img;

  const stage = new Konva.Stage({
    container,
    width,
    height,
  });
  const layer = new Konva.Layer();
  stage.add(layer);

  const background = new Konva.Image({
    x: 0,
    y: 0,
    image: img,
    width,
    height,
  });
  layer.add(background);

  const text = new Konva.Text({
    text: style.config.disclaimerText,
    x: 0,
    y: height - 40,
    width,
    align: 'center',
  });
  layer.add(text);

  if (style.config.logoFileId) {
    const logoImg = await loadImage(getFileUrl(style.config.logoFileId));
    const logoHeight = 100;
    // eslint-disable-next-line no-mixed-operators
    const logoWidth = 100 * logoImg.width / logoImg.height;
    const logo = new Konva.Image({
      x: 10,
      y: height - 110,
      height: logoHeight,
      width: logoWidth,
      image: logoImg,
    });
    layer.add(logo);
  }

  const url = stage.toDataURL({
    x: 0,
    y: 0,
    width,
    height,
  });

  container.remove();

  return dataURItoBlob(url);
};
