import React, { useEffect } from 'react';
import moment from 'moment';
import Div100vh from 'react-div-100vh';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Icon, ImageType } from 'auto-design-common';
import { getToken } from 'utils/auth';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import prevIcon from 'assets/images/ic-line-chevron-left.svg';
import nextIcon from 'assets/images/ic-line-chevron-right.svg';
import furnitureIcon from 'assets/images/ic-fill-arm-chair.svg';
import downloadIcon from 'assets/images/ic-line-download.svg';
import historyIcon from 'assets/images/ic-line-history.svg';
import penIcon from 'assets/images/ic-line-pen.svg';
import { getItem } from 'utils/storage';
import PanoViewer from 'components/Common/PanoViewer';
import { TourEvent, useTourContext } from './tour';
import useTranslation from '../../hooks/useTranslation';

export default function ResultViewer({
  image,
  progress,
  prevIndex,
  nextIndex,
  currentIndex,
  prev,
  next,
  showHistory,
  setShowHistory,
  historyItemsRef,
  setEditing,
}) {
  const { tImagesResult } = useTranslation();
  const history = useHistory();
  const tour = useTourContext();

  useEffect(() => {
    if (!tour.emit(TourEvent.OPEN_VIEWER) && !getItem('dont-show-edit-intro')) {
      tour.emit(TourEvent.FORCE_SHOW_START_EDIT);
    }
  }, [tour]);

  return (
    <Div100vh className="result-viewer">
      <div className="header">
        <div className="buttons">
          <div
            className="back"
            onClick={() => {
              tour.tour.hide();
              if (image.location) {
                history.push(`/locations/${image.location.id}`);
              }

              if (image.unit) {
                history.push(`/floor-plans/${image.unit.floorPlan.id}`);
              }
            }}
          >
            <Icon
              src={xIcon}
              fill="#212529"
            />
          </div>
        </div>
        <div className="info">
          <div className="title">
            <span>
              {image.name}
            </span>

            {image.location && (
              <>
                {' '}
                {tImagesResult('at')}
                {' '}
                <span>
                  {image.location.name}
                </span>
              </>
            )}

          </div>
          <div className="date">
            {moment.utc(progress.createdAt).local().format('MM-DD-yyyy HH:mm')}
          </div>
        </div>
      </div>
      <div
        className="output"
      >
        {(image.type === ImageType.PANO || progress.metadata.type === 'pano') ? (
          <PanoViewer
            imageUrl={`${progress.metadata.output}?accessToken=${getToken()}`}
          />
        ) : (
          <img
            src={progress.preview || `${progress.metadata.output}?accessToken=${getToken()}`}
            alt="Result"
          />
        )}

        <div
          className={classNames(
            'nav-button prev',
            prevIndex === null && 'disabled',
          )}
          onClick={prev}
        >
          <Icon src={prevIcon} stroke="white" />
        </div>

        <div
          className={classNames(
            'nav-button next',
            nextIndex === null && 'disabled',
          )}
          onClick={next}
        >
          <Icon src={nextIcon} stroke="white" />
        </div>
      </div>

      <div className="actions">
        <div
          className="action show-history"
          onClick={() => {
            if (!showHistory) {
              tour.emit(TourEvent.SHOW_HISTORY);
              setShowHistory(true);

              setTimeout(() => {
                historyItemsRef.current.scroll(0, currentIndex * 184);
              }, 200);
            } else {
              setShowHistory(false);
            }
          }}
        >
          <Icon
            stroke="white"
            src={historyIcon}
          />
        </div>
        <div
          className="action start-edit"
          onClick={() => {
            setEditing(true);
            setShowHistory(false);
          }}
        >
          <Icon
            fill="white"
            src={penIcon}
          />
        </div>
        {progress.metadata.furnitureOutput && (
          <div
            className="action"
            onClick={() => {
              window.open(`${progress.metadata.furnitureOutput}?accessToken=${getToken()}`);
            }}
          >
            <Icon
              fill="white"
              src={furnitureIcon}
            />
          </div>
        )}
        <div
          className="action"
          onClick={() => {
            window.open(`${progress.metadata.output}?accessToken=${getToken()}`);
          }}
        >
          <Icon
            fill="white"
            src={downloadIcon}
          />
        </div>
      </div>
    </Div100vh>
  );
}
