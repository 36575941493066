import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import * as api from 'utils/api';
import { useMutation } from '@tanstack/react-query';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { getFileUrl } from 'utils/file';
import styles from './Sidebar.module.scss';

export default function Sidebar({
  floorPlan,
  unit,
  refetchFloorPlan,
}) {
  const { tFloorPlans } = useTranslation();
  const [editingName, setEditingName] = useState(false);
  const [tempName, setTempName] = useState(unit.name);
  const { mutateAsync: save, isPending: saving } = useMutation({
    mutationFn: (data) => api.updateUnit(floorPlan.id, unit.id, data),
  });
  const history = useHistory();
  const { showModal } = useModalContext();
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setTempName(unit.name);
  }, [unit.name]);

  const downloadAll = async () => {
    setDownloading(true);
    const blobs = await Promise.map(floorPlan.units, async (unit) => fetch(getFileUrl(unit.styled_2dFileId))
      .then(resp => resp.blob()), {
      concurrency: 5,
    });

    const zip = JsZip();
    blobs.forEach((blob, i) => {
      zip.file(`${i + 1}_${floorPlan.units[i].name}.png`, blob);
    });
    zip.generateAsync({ type: 'blob' }).then(zipFile => {
      const currentDate = new Date().getTime();
      const fileName = `${floorPlan.name}_${currentDate}.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
    setDownloading(false);
  };

  return (
    <div className={styles.sidebar}>
      {editingName ? (
        <div className={styles.editName}>
          <Form.Control
            value={tempName}
            onChange={(e) => {
              setTempName(e.target.value);
            }}
          />
          <div className={styles.buttons}>
            <Button
              variant="secondary"
              className={styles.editButton}
              onClick={async () => {
                await save({
                  name: tempName.trim(),
                });
                await refetchFloorPlan();
                setEditingName(false);
              }}
              disabled={saving}
            >
              {tFloorPlans('save')}
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.name}>
          {unit.name}
          {' '}
          <Button
            onClick={() => {
              setEditingName(true);
            }}
            variant="link"
          >
            {tFloorPlans('edit')}
          </Button>
        </div>
      )}
      <Button
        className="small"
        onClick={() => {
          history.push(`/floor-plans/${floorPlan.id}/units/${unit.id}/edit-2d`);
        }}
      >
        {tFloorPlans('quickEdit')}
      </Button>
      <Button
        variant="secondary"
        className="small"
        onClick={() => {
          showModal(ModalKey.FLOOR_PLAN_2D_STYLES, {
            floorPlanId: floorPlan.id,
            selectedStyleId: floorPlan.metadata.styleId,
            onClose: () => {
              refetchFloorPlan();
            },
          });
        }}
      >
        {tFloorPlans('changeStyle')}
      </Button>
      <Button
        variant="secondary"
        className="small"
        onClick={() => {
          window.open(getFileUrl(unit.styled_2dFileId));
        }}
      >
        {tFloorPlans('download')}
      </Button>
      <Button
        disabled={downloading}
        variant="secondary"
        className="small"
        onClick={() => {
          downloadAll();
        }}
      >
        {tFloorPlans('downloadAll')}
      </Button>
    </div>
  );
}
