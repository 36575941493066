import { useMemo } from 'react';
import { FloorPlanPackage } from 'auto-design-common';
import standard2dImg from 'assets/images/create-floor-plan/standard_2d.jpg';
import standard2dSiteImg from 'assets/images/create-floor-plan/standard_2d_site.jpg';
import color2dImg from 'assets/images/create-floor-plan/color_2d.jpg';
import color2dSiteImg from 'assets/images/create-floor-plan/color_2d_site.jpg';
import { useAppContext } from 'components/AppContext';
import { getPriceKey } from 'components/CreateFloorPlan/utils';

export default function use2DPackages() {
  const { configs } = useAppContext();

  const PACKAGES_2D = useMemo(() => [
    {
      id: FloorPlanPackage.STANDARD_2D,
      name: '2D Standard Floor Plan',
      img: standard2dImg,
      price: configs[getPriceKey(FloorPlanPackage.STANDARD_2D)],
    },
    {
      id: FloorPlanPackage.STANDARD_2D_WITH_SITE_PLAN,
      name: '2D Standard Floor Plan With Site Plan',
      img: standard2dSiteImg,
      price: configs[getPriceKey(FloorPlanPackage.STANDARD_2D_WITH_SITE_PLAN)],
    },
    {
      id: FloorPlanPackage.COLOR_2D,
      name: '2D Colorful Floor Plan',
      img: color2dImg,
      price: configs[getPriceKey(FloorPlanPackage.COLOR_2D)],
    },
    {
      id: FloorPlanPackage.COLOR_2D_WITH_SITE_PLAN,
      name: '2D Colorful Floor Plan With Site Plan',
      img: color2dSiteImg,
      price: configs[getPriceKey(FloorPlanPackage.COLOR_2D_WITH_SITE_PLAN)],
    },
  ], [configs]);

  const PACKAGE_2D_IDS = useMemo(() => PACKAGES_2D.map(p => p.id), [PACKAGES_2D]);

  return {
    PACKAGES_2D,
    PACKAGE_2D_IDS,
  };
}
