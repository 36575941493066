import React, { useMemo } from 'react';
import useTranslation from 'hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useToastContext } from 'auto-design-common';
import { Button, Form, Badge, Card } from 'react-bootstrap';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import { useForm } from 'react-hook-form';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import moment from 'moment';
import styles from './ChangeRequests.module.scss';

export default function ChangeRequests() {
  const { floorPlanId } = useParams();
  const { tFloorplanOverview } = useTranslation();
  const { data: logsData, refetch: refetchLogs } = useQuery({
    queryKey: ['floor-plan', floorPlanId, 'logs'],
    queryFn: () => api.getFloorPlanLogs(floorPlanId),
  });

  const logs = useMemo(() => logsData?.logs || [], [logsData]);

  const { configs, request, getInfo } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();

  const submittedChanges = useMemo(() => logs.filter(l => l.name === 'user_submit_change_request'), [logs]);
  const isFree = submittedChanges.length < 2;

  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm({
    defaultValues: {
      changes: '',
    },
  });

  const hasJustSubmitted = logs[0]?.name === 'user_submit_change_request';

  const onClickSubmit = () => {
    handleSubmit(async (values) => {
      const { success, error } = await request(api.submitChangeRequest(floorPlanId, values.changes));

      if (!success) {
        toastError(error);
      } else {
        toastSuccess(tFloorplanOverview('submittedChanges'));
        refetchLogs();
        getInfo();
        reset();
      }
    })();
  };

  return (
    <div className={styles.container}>
      <Card style={{
        width: 400,
      }}
      >
        <Card.Body>
          <Form.Group>
            <Form.Label>
              {tFloorplanOverview('changes')}
            </Form.Label>
            <Form.Control
              as="textarea"
              {...register('changes', {
                validate: (value) => {
                  if (value.trim().length === 0) {
                    return tFloorplanOverview('changeEmpty');
                  }

                  if (value.trim().length > 1000) {
                    return tFloorplanOverview('changeMaxLength');
                  }

                  return true;
                },
              })}
              isInvalid={!!errors.changes}
            />
            {errors.changes && <Form.Control.Feedback type="invalid">{errors.changes.message}</Form.Control.Feedback>}
          </Form.Group>

          <div className={styles.costWrapper}>
            <div className={styles.costTitle}>
              {tFloorplanOverview('cost')}
              :
            </div>
            <div>
              {isFree ? (
                <Badge pill bg="success">
                  {tFloorplanOverview('free')}
                </Badge>
              ) : (
                <div>
                  {configs.floor_plan_change_request_fee}
                  {' '}
                  x
                  {' '}
                  <CoinIcon />
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <div>
              {hasJustSubmitted && tFloorplanOverview('weAreProcessing')}
            </div>
            <Button
              variant="danger"
              onClick={onClickSubmit}
              disabled={isSubmitting || hasJustSubmitted}
            >
              {tFloorplanOverview('submit')}
            </Button>
          </div>
        </Card.Body>
      </Card>

      <div>
        <div style={{
          marginBottom: 16,
        }}
        >
          {tFloorplanOverview('lastRequests')}
        </div>
        {logs.filter(l => l.name === 'user_submit_change_request').map(l => (
          <Card style={{
            width: 400,
            marginBottom: 16,
          }}
          >
            <Card.Body>
              {l.metadata.changes}
              <div className={styles.createdAt}>
                {moment.utc(l.createdAt).local().format('MM-DD-yyyy HH:mm')}

              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}
