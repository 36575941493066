import React, { useMemo } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Icon } from 'auto-design-common';
import * as api from 'utils/api';
import { getToken } from 'utils/auth';
import downloadIcon from 'assets/images/ic-line-download.svg';
import config from 'configuration';
import { useQuery } from '@tanstack/react-query';
import useTranslation from '../../hooks/useTranslation';
import styles from './FloorPlan3DVersionHistory.module.scss';

export default function FloorPlan3DVersionHistory({
  onClose,
  imageId,
}) {
  const { useTranslationOrigin } = useTranslation();
  const { t: tVersionHistoryModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.versionHistory' });
  const { data: image } = useQuery({
    queryFn: () => api.getImage(imageId),
    queryKey: ['image', imageId],
  });

  const versions = useMemo(() => image?.progresses || [], [image]);
  const history = useHistory();

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      size="md"
      id="modal-version-history"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tVersionHistoryModal('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {versions.map(version => (
          <div
            className={styles.version}
            onClick={() => {
              history.push(`/images/${imageId}/view?progressId=${version.id}`);
              onClose();
            }}
          >
            <img
              className={styles.preview}
              src={version.preview || `${config.apiUrl}/files/${version.metadata.outputFileId}?accessToken=${getToken()}`}
              alt="Preview"
            />
            <div className={styles.name}>
              {moment.utc(version.createdAt).local().format('MM-DD-yyyy HH:mm')}
            </div>
            <div className={styles.actions}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`${config.apiUrl}/files/${version.metadata.outputFileId}?accessToken=${getToken()}`);
                }}
              >
                <Icon
                  fill="#575762"
                  src={downloadIcon}
                />
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}
