/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { Button } from 'react-bootstrap';
import fx from 'glfx';
import ReactSlider from 'react-slider';
import Div100vh from 'react-div-100vh';
import moment from 'moment';
import ReactLoading from 'components/Common/ReactLoading';
import { useResizeDetector } from 'react-resize-detector';
import { Icon, CheckBox, dataURItoBlob } from 'auto-design-common';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import { getToken } from 'utils/auth';
import eraserIcon from 'assets/images/ic-fill-eraser.svg';
import classNames from 'classnames';
import { useHideTawkWidget } from 'components/TawkContext';
import { getFileUrl } from 'utils/file';
import { TourEvent, useTourContext } from './tour';
import Watermarks from './Watermarks';
import WatermarkConfig from './WatermarkConfig';
import styles from './ResultEditor.module.scss';
import useTranslation from '../../hooks/useTranslation';

const INITIAL_FILTER_VALUES = {
  saturation: 0,
  contrast: 0,
  brightness: 0,
  hue: 0,
  shadow: 0,
  light: 0,
};

const INITIAL_ERASER_SIZE = 50;

function FilterGroup({
  children,
  title,
  className,
}) {
  return (
    <div className={classNames('filter-group', className)}>
      <div className="filter-title">
        {title}
      </div>
      <div className="input">
        {children}
      </div>
    </div>
  );
}

function Filter({
  label,
  onChange,
  value,
  min,
  max,
}) {
  return (
    <div className="filter">
      {label && (
        <div className="label">{label}</div>
      )}
      <ReactSlider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
      <div className="value">
        {value}
      </div>
    </div>
  );
}

// TODO: Hung - Refactor this constant
const SmallAdjustOption = {
  SATURATION: 'SATURATION',
  CONTRAST: 'CONTRAST',
  BRIGHTNESS: 'BRIGHTNESS',
  HUE: 'HUE',
  ERASE: 'ERASE',
  SHADOW: 'SHADOW',
  LIGHT: 'LIGHT',
};

const EraseMode = {
  KEEP: 'Keep',
  REMOVE: 'Remove',
};

export default function ResultEditor({
  image,
  progress,
  setEditing,
  setShowHistory,
  setDirty,
  save,
}) {
  const { tImagesResult, tCommon } = useTranslation();

  const [loading, setLoading] = useState(true);
  const canvasRef = useRef();
  const overlayCanvasRef = useRef();
  const softnessCanvasRef = useRef();
  const draggingRef = useRef(false);
  const lastMousePositionRef = useRef(null);
  const itemCanvasRef = useRef(document.createElement('canvas'));
  const lightCanvasRef = useRef(document.createElement('canvas'));
  const shadowCanvasRef = useRef(document.createElement('canvas'));
  const maskCanvasRef = useRef(document.createElement('canvas'));
  const lightImageRef = useRef();
  const itemImageRef = useRef();
  const shadowImageRef = useRef();
  const backgroundImageRef = useRef();
  const maskImageRef = useRef();
  const [mousePosition, setMousePosition] = useState(null);
  const [filterValues, setFilterValues] = useState(INITIAL_FILTER_VALUES);
  const [debouncedFilterValues] = useDebounce(filterValues, 100, {
    leading: true,
    trailing: true,
  });
  const debouncedFilterValuesRef = useRef();
  debouncedFilterValuesRef.current = debouncedFilterValues;
  const [erasing, setErasing] = useState(false);
  const [brushSize, setBrushSize] = useState(INITIAL_ERASER_SIZE);
  const [softness, setSoftness] = useState(0.01);
  const { ref: photoAreaRef, height: photoAreaHeight, width: photoAreaWidth } = useResizeDetector();
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [submitting, setSubmitting] = useState(false);
  const [smallAdjustOption, setSmallAdjustOption] = useState(SmallAdjustOption.SATURATION);
  const [eraseMode, setEraseMode] = useState(EraseMode.REMOVE);
  const [watermark, setWatermark] = useState({
    use: false,
    images: [],
  });
  const [hideBackground, setHideBackground] = useState(false);
  const hideBackgroundRef = useRef(false);
  hideBackgroundRef.current = hideBackground;
  const tour = useTourContext();

  const widthScale = size.width / photoAreaWidth;
  const heightScale = size.height / photoAreaHeight;
  const scale = 1 / (widthScale > heightScale ? widthScale : heightScale);
  const filledDots = useRef([]);
  const watermarksRef = useRef();

  const realBrushSize = useMemo(() => brushSize / scale, [brushSize, scale]);

  const getDistance = (p1, p2) => Math.sqrt(
    (p1.x - p2.x) ** 2
  + (p1.y - p2.y) ** 2,
  );

  const createDot = (x, y) => {
    for (const dot of filledDots.current) {
      if (getDistance(dot, { x, y }) <= realBrushSize / 4) {
        return;
      }
    }

    const ctx = overlayCanvasRef.current.getContext('2d');
    ctx.beginPath();
    const radgrad = ctx.createRadialGradient(x, y, 0, x, y, realBrushSize / 2);
    radgrad.addColorStop(0, 'rgba(228,0,0,1)');
    radgrad.addColorStop(softness, 'rgba(228,0,0,1)');
    radgrad.addColorStop(1, 'rgba(228,0,0,0)');
    ctx.fillStyle = radgrad;
    ctx.fillRect(x - realBrushSize / 2, y - realBrushSize / 2, realBrushSize, realBrushSize);
    filledDots.current.push({
      x,
      y,
    });
  };

  const getMouseCoordinate = (e) => {
    const canvasBoundingRect = canvasRef.current.getBoundingClientRect();

    const x = e.pageX - canvasBoundingRect.x;
    const y = e.pageY - canvasBoundingRect.y;

    return { x: x / scale, y: y / scale };
  };

  const resetCanvas = (canvas, img) => {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  const drawImage = (canvas, img, blend = 'source-over') => {
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = blend;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  const doDrawMainCanvas = useCallback((hideBackground) => {
    const { width, height } = canvasRef.current;
    const filterValues = debouncedFilterValuesRef.current;

    // Apply mask
    const applyMask = (canvas, mask) => {
      const maskedCanvas = document.createElement('canvas');
      maskedCanvas.width = canvas.width;
      maskedCanvas.height = canvas.height;
      drawImage(maskedCanvas, canvas);
      drawImage(maskedCanvas, mask, 'destination-out');
      return maskedCanvas;
    };

    const maskedLightCanvas = applyMask(lightCanvasRef.current, maskCanvasRef.current);
    const maskedShadowCanvas = applyMask(shadowCanvasRef.current, maskCanvasRef.current);
    const maskedItemCanvas = applyMask(itemCanvasRef.current, maskCanvasRef.current);

    // Apply filter to item canvas
    const filteredItemCanvas = fx.canvas();
    filteredItemCanvas.width = width;
    filteredItemCanvas.height = height;

    const itemTexture = filteredItemCanvas.texture(maskedItemCanvas);
    filteredItemCanvas.draw(itemTexture);
    filteredItemCanvas.brightnessContrast(filterValues.brightness / 100, filterValues.contrast / 100);
    filteredItemCanvas.hueSaturation(filterValues.hue / 100, filterValues.saturation / 100);
    filteredItemCanvas.update();
    itemTexture.destroy();

    // Apply filter to shadow
    const filteredShadowCanvas = document.createElement('canvas');
    filteredShadowCanvas.width = width;
    filteredShadowCanvas.height = height;
    const filteredShadowContext = filteredShadowCanvas.getContext('2d');

    if (filterValues.shadow > 50) {
      filteredShadowContext.drawImage(maskedShadowCanvas, 0, 0);
      filteredShadowContext.drawImage(maskedShadowCanvas, 0, 0);
      filteredShadowContext.globalAlpha = (filterValues.shadow - 50) / 50;
      filteredShadowContext.drawImage(maskedShadowCanvas, 0, 0);
    } else if (filterValues.shadow > 0) {
      filteredShadowContext.drawImage(maskedShadowCanvas, 0, 0);
      filteredShadowContext.globalAlpha = filterValues.shadow / 50;
      filteredShadowContext.drawImage(maskedShadowCanvas, 0, 0);
    } else {
      filteredShadowContext.globalAlpha = 1 + filterValues.shadow / 100;
      filteredShadowContext.drawImage(maskedShadowCanvas, 0, 0);
    }

    // Apply filter to light
    let filteredLightCanvas;

    if (filterValues.light > 0) {
      filteredLightCanvas = fx.canvas();
      filteredLightCanvas.width = width;
      filteredLightCanvas.height = height;

      const lightTexture = filteredLightCanvas.texture(maskedLightCanvas);
      filteredLightCanvas.draw(lightTexture);
      filteredLightCanvas.brightnessContrast(filterValues.light / 500, 0);
      filteredLightCanvas.update();
      lightTexture.destroy();
    } else {
      filteredLightCanvas = document.createElement('canvas');
      filteredLightCanvas.width = width;
      filteredLightCanvas.height = height;
      const filteredLightContext = filteredLightCanvas.getContext('2d');
      filteredLightContext.globalAlpha = 1 + filterValues.light / 100;
      filteredLightContext.drawImage(maskedLightCanvas, 0, 0);
    }

    // Draw canvas based on item, light and shadow
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    if (!hideBackground) {
      drawImage(canvas, backgroundImageRef.current);
    }
    drawImage(canvas, filteredItemCanvas);
    drawImage(canvas, filteredShadowCanvas);

    if (!hideBackground) {
      drawImage(canvas, filteredLightCanvas, 'screen');
    }

    return canvas;
  }, []);

  const drawMainCanvas = useCallback(() => {
    const canvas = doDrawMainCanvas(hideBackgroundRef.current);
    const { width, height } = canvasRef.current;
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(canvas, 0, 0);
  }, [doDrawMainCanvas]);

  const onMouseDown = () => {
    if (!erasing) {
      return;
    }

    filledDots.current = [];

    draggingRef.current = true;
  };

  const onMouseUp = (e) => {
    if (!erasing) {
      return;
    }

    const { width, height } = size;

    draggingRef.current = false;
    const overlayCtx = overlayCanvasRef.current.getContext('2d');

    const { x, y } = getMouseCoordinate(e);

    const process = () => {
      if (eraseMode === EraseMode.REMOVE) {
        // drawImage(itemCanvasRef.current, overlayCanvasRef.current, 'destination-out');
        // drawImage(lightCanvasRef.current, overlayCanvasRef.current, 'destination-out');
        // drawImage(shadowCanvasRef.current, overlayCanvasRef.current, 'destination-out');
        drawImage(maskCanvasRef.current, overlayCanvasRef.current);
        drawMainCanvas();
      } else {
        drawImage(maskCanvasRef.current, overlayCanvasRef.current, 'destination-out');
        // const keep = (canvas, originalImage) => {
        //   const keepCanvas = document.createElement('canvas');
        //   keepCanvas.width = width;
        //   keepCanvas.height = height;
        //   const keepCtx = keepCanvas.getContext('2d');
        //   keepCtx.drawImage(originalImage, 0, 0);
        //   keepCtx.globalCompositeOperation = 'destination-in';
        //   keepCtx.drawImage(overlayCanvasRef.current, 0, 0);
        //   drawImage(canvas, overlayCanvasRef.current, 'destination-out');
        //   drawImage(canvas, keepCanvas, 'destination-over');
        // };

        // keep(itemCanvasRef.current, itemImageRef.current);
        // keep(shadowCanvasRef.current, shadowImageRef.current);
        // keep(lightCanvasRef.current, lightImageRef.current);
        drawMainCanvas();
      }
    };

    if (!lastMousePositionRef.current) {
      createDot(x, y);
      process();
      overlayCtx.clearRect(0, 0, width, height);
    } else {
      process();
      lastMousePositionRef.current = null;
      overlayCtx.clearRect(0, 0, width, height);
    }

    if (eraseMode === EraseMode.REMOVE) {
      tour.emit(TourEvent.ERASING_END);
    } else {
      tour.emit(TourEvent.KEEP_END);
    }

    filledDots.current = [];
  };

  const onMouseMove = (e) => {
    if (!erasing) {
      return;
    }
    const { x, y } = getMouseCoordinate(e);

    setMousePosition({
      x,
      y,
    });

    const lastMousePosition = lastMousePositionRef.current;

    if (draggingRef.current) {
      if (!lastMousePosition) {
        createDot(x, y);

        lastMousePositionRef.current = {
          x,
          y,
        };
      } else {
        const distance = getDistance(lastMousePosition, { x, y });

        let i = 0;

        const oldX = lastMousePosition.x;
        const oldY = lastMousePosition.y;

        while (i < distance) {
          createDot(oldX + (x - oldX) * i / distance, oldY + (y - oldY) * i / distance);
          i += realBrushSize / 4;
        }

        lastMousePositionRef.current = {
          x,
          y,
        };
      }
    }
  };

  useEffect(() => {
    setWatermark(progress.metadata.watermark || {
      use: false,
      images: [],
    });
    setFilterValues(progress.metadata.filterValues || INITIAL_FILTER_VALUES);
    setHideBackground(progress.metadata.hideBackground || false);
  }, [progress]);

  useEffect(() => {
    setLoading(true);

    const destroyed = false;

    const loadImage = (id) => {
      if (!id) {
        return Promise.resolve(null);
      }

      const image = new Image();
      image.crossOrigin = true;
      image.src = getFileUrl(id);

      return new Promise(resolve => {
        image.onload = () => {
          resolve(image);
        };
      });
    };

    const setCanvasSize = (canvas, width, height) => {
      canvas.height = height;
      canvas.width = width;
    };

    Promise.resolve()
      .then(() => {
        if (backgroundImageRef.current) {
          return [backgroundImageRef.current, itemImageRef.current, lightImageRef.current, shadowImageRef.current, maskImageRef.current];
        }
        return Promise.all([
          loadImage(progress.metadata.backgroundFileId),
          loadImage(progress.metadata.itemOutputFileId),
          loadImage(progress.metadata.lightOutputFileId),
          loadImage(progress.metadata.shadowOutputFileId),
          progress.metadata.maskFileId ? loadImage(progress.metadata.maskFileId) : Promise.resolve(undefined),
        ]);
      })
      .then(([backgroundImage, itemImage, lightImage, shadowImage, maskImage]) => {
        const width = itemImage.width;
        const height = itemImage.height;

        if (destroyed) {
          return;
        }

        setSize({
          height,
          width,
        });

        setCanvasSize(canvasRef.current, width, height);
        setCanvasSize(overlayCanvasRef.current, width, height);
        setCanvasSize(itemCanvasRef.current, width, height);
        setCanvasSize(shadowCanvasRef.current, width, height);
        setCanvasSize(lightCanvasRef.current, width, height);

        backgroundImageRef.current = backgroundImage;
        lightImageRef.current = lightImage;
        shadowImageRef.current = shadowImage;
        itemImageRef.current = itemImage;

        if (maskImage) {
          maskImageRef.current = maskImage;
        } else {
          maskImageRef.current = new Image();
          maskImageRef.current.width = width;
          maskImageRef.current.height = height;
        }

        resetCanvas(itemCanvasRef.current, itemImage);

        if (lightImage) {
          resetCanvas(lightCanvasRef.current, lightImage);
        }

        if (shadowImage) {
          resetCanvas(shadowCanvasRef.current, shadowImage);
        }

        resetCanvas(maskCanvasRef.current, maskImageRef.current);
        drawMainCanvas();

        setLoading(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [image, progress, setDirty, drawMainCanvas]);

  useEffect(() => {
    if (erasing) {
      setSmallAdjustOption(SmallAdjustOption.ERASE);
    } else {
      setSmallAdjustOption(SmallAdjustOption.SATURATION);
    }
  }, [erasing, eraseMode, tour]);

  useHideTawkWidget();

  useEffect(() => {
    tour.emit(TourEvent.OPEN_EDITOR);
  }, [tour]);

  useEffect(() => {
    if (erasing) {
      tour.emit(TourEvent.USE_ERASER);
    }
  }, [erasing, tour]);

  useEffect(() => {
    if (!erasing || !softnessCanvasRef.current) {
      return () => {};
    }

    const brushSize = 40;
    const x = 25;
    const y = 25;
    const ctx = softnessCanvasRef.current.getContext('2d');
    ctx.beginPath();
    const radgrad = ctx.createRadialGradient(x, y, 0, x, y, brushSize / 2);
    radgrad.addColorStop(0, 'rgba(228,0,0,1)');
    // radgrad.addColorStop(0.1, 'rgba(228,0,0,0.5)');
    radgrad.addColorStop(softness, 'rgba(228,0,0,1)');
    radgrad.addColorStop(1, 'rgba(255,255,255,0)');
    ctx.fillStyle = radgrad;
    ctx.fillRect(x - brushSize / 2, y - brushSize / 2, brushSize, brushSize);

    return () => {
      ctx.clearRect(0, 0, 100, 100);
    };
  }, [erasing, softness]);

  useEffect(() => {
    // Redraw whenever filters change or toggle hide background
    if (!loading) {
      drawMainCanvas();
    }
  }, [drawMainCanvas, debouncedFilterValues, hideBackground, loading]);

  const canvasBoundingRect = canvasRef.current?.getBoundingClientRect();
  const photoAreaRect = photoAreaRef.current?.getBoundingClientRect();

  const offsetX = canvasBoundingRect?.x - photoAreaRect?.x;
  const offsetY = canvasBoundingRect?.y - photoAreaRect?.y;

  const renderSaturation = () => (
    <FilterGroup
      title={tImagesResult('saturation').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.saturation}
        onChange={(value) => {
          tour.emit(TourEvent.CHANGE_SATURATION);
          setDirty(true);
          setFilterValues({
            ...filterValues,
            saturation: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderContrast = () => (
    <FilterGroup
      title={tImagesResult('contrast').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.contrast}
        onChange={(value) => {
          tour.emit(TourEvent.CHANGE_CONTRAST);
          setDirty(true);
          setFilterValues({
            ...filterValues,
            contrast: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderBrightness = () => (
    <FilterGroup
      title={tImagesResult('brightess').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.brightness}
        onChange={(value) => {
          tour.emit(TourEvent.CHANGE_BRIGHTNESS);
          setDirty(true);
          setFilterValues({
            ...filterValues,
            brightness: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderHue = () => (
    <FilterGroup
      title={tImagesResult('hue').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.hue}
        onChange={(value) => {
          tour.emit(TourEvent.CHANGE_HUE);
          setDirty(true);
          setFilterValues({
            ...filterValues,
            hue: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderShadow = () => progress.metadata.shadowOutputFileId && (
    <FilterGroup
      title="SHADOW"
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.shadow}
        onChange={(value) => {
          setDirty(true);
          setFilterValues({
            ...filterValues,
            shadow: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderLight = () => progress.metadata.shadowOutputFileId && (
    <FilterGroup
      title="LIGHT"
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.light}
        onChange={(value) => {
          setDirty(true);
          setFilterValues({
            ...filterValues,
            light: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderSaveButton = () => (
    <Button
      className="save-button"
      disabled={submitting}
      onClick={async () => {
        setSubmitting(true);

        const furnitureOutputCanvas = doDrawMainCanvas(true);
        const outputCanvas = doDrawMainCanvas(false);

        maskCanvasRef.current.toBlob(maskBlob => {
          furnitureOutputCanvas.toBlob((furnitureOutputBlob) => {
            outputCanvas.toBlob(async (outputBlob) => {
              let data = {
                furnitureOutputBlob,
                outputBlob,
                maskBlob,
                watermark,
                filterValues,
                hideBackground,
              };

              if (watermark.use) {
                const watermarkDataURL = await watermarksRef.current.getDataURL();
                const watermarkBlob = dataURItoBlob(watermarkDataURL);
                data = {
                  ...data,
                  watermarkBlob,
                };
              }

              tour.emit(TourEvent.SAVE);
              await save(data);
              setSubmitting(false);
            });
          });
        });
      }}
    >
      {tCommon('save')}
    </Button>
  );

  const renderBrushSize = () => (
    <>
      <FilterGroup
        title="SIZE"
        className="eraser-size"
      >
        <Filter
          min={5}
          max={500}
          value={brushSize}
          onChange={(value) => {
            tour.emit(TourEvent.CHANGE_ERASER_SIZE);
            setBrushSize(value);
          }}
        />
      </FilterGroup>
      <FilterGroup
        title="SOFTNESS"
        className="softness"
      >
        <Filter
          min={1}
          max={100}
          value={Math.round(softness * 100)}
          onChange={(value) => {
            setSoftness(value / 100);
          }}
        />
      </FilterGroup>
    </>
  );

  const renderEraseMode = () => (
    <div className="erase-mode">
      {[EraseMode.REMOVE, EraseMode.KEEP].map(mode => (
        <div
          key={mode}
          className={classNames('mode', eraseMode === mode && 'active', mode)}
          onClick={() => {
            if (mode === EraseMode.KEEP) {
              tour.emit(TourEvent.SWITCH_TO_KEEP);
            }

            setEraseMode(mode);
          }}
        >
          {tImagesResult(mode)}
        </div>
      ))}
    </div>
  );

  return (
    <Div100vh className="result-editor">
      <div className="left">
        <div className="header">
          <div className="buttons">
            <div
              className="back"
              onClick={() => {
                tour.tour.hide();
                setEditing(false);
              }}
            >
              <Icon
                src={xIcon}
                fill="#212529"
              />
            </div>
          </div>
          <div className="info">
            <div className="title">
              {tImagesResult('editingPhoto')}
            </div>
            <div className="date">
              {tImagesResult('version')}
              :
              {' '}
              {moment.utc(progress.createdAt).local().format('MM-DD-yyyy HH:mm')}
              <span
                className="change-button"
                onClick={() => setShowHistory(true)}
              >
                {tCommon('change')}
              </span>
            </div>
          </div>
        </div>
        <div
          className="photo-area"
          ref={photoAreaRef}
        >
          <div
            className="canvas"
            style={{
              transform: `scale(${scale})`,
              position: 'relative',
            }}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            onMouseEnter={(e) => {
              const { x, y } = getMouseCoordinate(e);
              setMousePosition({
                x,
                y,
              });
            }}
            onMouseLeave={(e) => {
              setMousePosition(null);
              if (draggingRef.current) {
                onMouseUp(e);
              }
            }}
          >
            {loading && (
              <ReactLoading />
            )}
            {watermark.use && (
              <Watermarks
                ref={watermarksRef}
                size={size}
                images={watermark.images}
                erasing={erasing}
                onChange={(images) => {
                  setWatermark({
                    ...watermark,
                    images,
                  });
                }}
              />
            )}
            <canvas
              ref={canvasRef}
              style={{
                zIndex: 2,
                cursor: erasing ? 'none' : undefined,
              }}
              className={styles.mainCanvas}
            />
            <canvas
              style={{
                zIndex: 3,
                pointerEvents: 'none',
                opacity: 0.7,
              }}
              ref={overlayCanvasRef}
            />
            <img
              alt="background"
              src={`${image.background}?accessToken=${getToken()}`}
              style={{
                height: size.height,
                zIndex: 1,
                opacity: hideBackground ? 0 : 1,
              }}
            />

          </div>
          {erasing && (
          <div
            className="cursor"
            style={{
              zIndex: 3,
              left: mousePosition ? mousePosition.x * scale + offsetX - brushSize / 2 : 0,
              top: mousePosition ? mousePosition.y * scale + offsetY - brushSize / 2 : 0,
              height: brushSize,
              width: brushSize,
              borderRadius: brushSize,
              display: mousePosition ? 'block' : 'none',
            }}
          />
          )}

        </div>
      </div>
      <div className="adjust">
        <div className="title">
          {tImagesResult('adjust')}
        </div>
        <div className="content">
          {renderSaturation()}
          {renderContrast()}
          {renderBrightness()}
          {renderHue()}
          {renderShadow()}
          {!hideBackground && renderLight()}
          <div style={{
            marginTop: 24,
            marginLeft: 24,
            marginRight: 24,
          }}
          >
            <CheckBox
              title={tImagesResult('keepFurnitureLayer')}
              checked={hideBackground}
              onChange={() => {
                setHideBackground(!hideBackground);
              }}
            />
          </div>
          <WatermarkConfig
            loading={loading}
            data={watermark}
            onChange={setWatermark}
          />
          <Button
            variant={erasing ? 'primary' : 'secondary'}
            onClick={() => {
              setErasing(!erasing);
            }}
            className="erase-button"
          >
            <Icon
              src={eraserIcon}
              fill={erasing ? 'white' : 'black'}
            />
            {tImagesResult('eraseWithEraserBrush')}
          </Button>
          {erasing && (
            <>
              {renderEraseMode()}
              {renderBrushSize()}
            </>
          )}
          <div className="sample-brush">
            <canvas
              ref={softnessCanvasRef}
              width={50}
              height={50}
              style={{
                width: 50,
                height: 50,
              }}
            />
          </div>
        </div>
        <div className="adjust-footer">
          {renderSaveButton()}
        </div>
      </div>

      <div className="small-adjust">
        <div className="filter-area">
          {smallAdjustOption === SmallAdjustOption.SATURATION && renderSaturation()}
          {smallAdjustOption === SmallAdjustOption.CONTRAST && renderContrast()}
          {smallAdjustOption === SmallAdjustOption.BRIGHTNESS && renderBrightness()}
          {smallAdjustOption === SmallAdjustOption.HUE && renderHue()}
          {smallAdjustOption === SmallAdjustOption.SHADOW && renderShadow()}
          {smallAdjustOption === SmallAdjustOption.LIGHT && renderLight()}
          {erasing && (
            <>
              {renderEraseMode()}
              {renderBrushSize()}
            </>
          )}
        </div>
        <div className="tools">
          <div className="filter-selections">
            {[
              SmallAdjustOption.SATURATION,
              SmallAdjustOption.CONTRAST,
              SmallAdjustOption.BRIGHTNESS,
              SmallAdjustOption.HUE,
              progress.metadata.shadowOutputFileId && SmallAdjustOption.SHADOW,
              progress.metadata.lightOutputFileId && SmallAdjustOption.LIGHT,
            ].filter(val => !!val).map(option => (
              <div
                key={option}
                className={classNames('filter-selection', smallAdjustOption === option && 'active')}
                onClick={() => {
                  setSmallAdjustOption(option);
                  setErasing(false);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
        {renderSaveButton()}
      </div>
    </Div100vh>
  );
}
