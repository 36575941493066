import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import TransparentInput from 'components/Common/TransparentInput';
import { useToastContext } from 'auto-design-common';
import Layout from 'components/Common/Layout';
import useTranslation from '../../hooks/useTranslation';

export default function Profile() {
  const { tProfile, tCommon, tSuccess } = useTranslation();
  const { user, updateInfo } = useAppContext();
  const { toastSuccess, toastError } = useToastContext();
  const [name, setName] = useState(user.name);
  const [error, setError] = useState({});

  useEffect(() => {
    const error = {};

    if (name.length === 0) {
      error.name = 'Name can not be empty';
    }

    setError(error);
  }, [name]);

  return (
    <Layout className="profile">
      <div className="title">
        {tProfile('title')}
      </div>
      <div className="fields">
        <TransparentInput
          label={tCommon('name')}
          value={name}
          onChange={e => setName(e.target.value)}
          error={error.name}
          onSubmit={async () => {
            try {
              await updateInfo({
                name,
              });
              toastSuccess(tSuccess('updatedSuccessfully'));
            } catch (e) {
              toastError(e);
            }
          }}
        />

        <TransparentInput
          label={tCommon('email')}
          value={user.email}
          readOnly
        />

        <Form.Group className="form-group transparent-form-group long-form">
          <Form.Label>
            <span>
              {tProfile('imageReadyForEdit')}
            </span>
          </Form.Label>
          <div className="right">
            <div className="input">
              <Form.Check
                checked={user.settings.image_process_email}
                onChange={async () => {
                  try {
                    await updateInfo({
                      settings: {
                        image_process_email: !user.settings.image_process_email,
                      },
                    });

                    toastSuccess(tSuccess('updatedSuccessfully'));
                  } catch (e) {
                    toastError(e);
                  }
                }}
              />
            </div>
          </div>
        </Form.Group>

        <Form.Group className="form-group transparent-form-group long-form">
          <Form.Label>
            <span>
              {tProfile('renderResultIsReady')}
            </span>
          </Form.Label>
          <div className="right">
            <div className="input">
              <Form.Check
                checked={user.settings.image_render_email}
                onChange={async () => {
                  try {
                    await updateInfo({
                      settings: {
                        image_render_email: !user.settings.image_render_email,
                      },
                    });

                    toastSuccess(tSuccess('updatedSuccessfully'));
                  } catch (e) {
                    toastError(e);
                  }
                }}
              />
            </div>
          </div>
        </Form.Group>
      </div>
    </Layout>
  );
}
