import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useToastContext, Icon, withMinimumScreenWidth } from 'auto-design-common';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import penIcon from 'assets/images/ic-line-pen.svg';
import eyeIcon from 'assets/images/ic-line-eye.svg';
import threeDotIcon from 'assets/images/ic-fill-3dot-vertical.svg';
import Layout from 'components/Common/Layout';
import NoTour from './NoTour';
import useTranslation from '../../../hooks/useTranslation';

function EditButton({
  tour,
}) {
  const { tCommon } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div
        className="button"
        onClick={() => {
          history.push(`/tours/${tour.id}/edit`);
        }}
      >
        <Icon src={penIcon} fill="#212529" />
        {tCommon('edit')}
      </div>
      <div className="buttons-separator" />
    </>
  );
}

const WrappedEditButton = withMinimumScreenWidth(768, false)(EditButton);
export default function Tours() {
  const { tCommon, tMyTours } = useTranslation();
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { success, result, error } = await request(api.getTours());

      if (!success) {
        toastError(error);
      } else {
        setTours(result);
      }

      setLoading(false);
    })();
  }, [request, toastError]);

  return (
    <Layout className="tours">
      <div className="header">
        <div className="title">
          {tMyTours('title')}
        </div>
      </div>
      <div className="body">
        {!loading && tours.length === 0 && (
        <NoTour />
        )}
        <div className="list row">
          {tours.map((tour => (
            <div className="col-xxl-3 col-lg-4 col-md-6" key={tour.id}>
              <div className="tour">
                <Dropdown>
                  <Dropdown.Toggle className="options">
                    <img src={threeDotIcon} alt="options" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        showModal(ModalKey.TOUR_VERSIONS, {
                          tourId: tour.id,
                        });
                      }}
                    >
                      {tMyTours('publishedVersions')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="" />
                <img src={tour.thumbnail} alt={tour.name} />
                <div className="name">
                  {tour.name}
                </div>
                <div className="time">
                  {moment.utc(tour.createdAt).local().format('MM-DD-yyyy HH:mm')}
                </div>
                <div className="separator" />
                <div className="buttons">
                  <WrappedEditButton tour={tour} />
                  <div
                    className={classNames(
                      'button',
                      tour.versions.length === 0 && 'disabled',
                    )}
                    onClick={() => {
                      if (tour.versions.length > 0) {
                        window.open(`/published-tours/${tour.versions[0].slug}`);
                      }
                    }}
                  >
                    <Icon
                      src={eyeIcon}
                      fill="#212529"
                    />
                    {tCommon('view')}
                  </div>
                </div>
              </div>
            </div>
          )))}
        </div>
      </div>
    </Layout>
  );
}
