import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as MoneyImg } from 'assets/images/money.svg';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { useAppContext } from 'components/AppContext';
import { useHistory } from 'react-router-dom';
import * as api from 'utils/api';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import styles from './ExtraCost.module.scss';

export default function ExtraCost({
  floorPlan,
  refetchFloorPlan,
}) {
  const { tFloorplanOverview, tCreateFloorPlan } = useTranslation();
  const { request, user, getInfo } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const { showModal } = useModalContext();

  const hasEnoughBalance = user.balance >= floorPlan.metadata.extraCost.coins;

  console.log({
    user,
  });

  const requestRefund = async () => {
    setSubmitting(true);
    const { success } = await request(api.requestRefund(floorPlan.id));
    if (success) {
      history.push('/floor-plans');
    }
    setSubmitting(false);
  };

  const payExtraCost = async () => {
    setSubmitting(true);
    const { success } = await request(api.approveExtraCost(floorPlan.id));
    if (success) {
      refetchFloorPlan();
      getInfo();
    }
    setSubmitting(false);
  };

  return (
    <div className={styles.extraCost}>
      <MoneyImg height={200} />
      <div className={styles.description}>
        <p>
          {tFloorplanOverview('extraCostDescription1')}
        </p>
        <p>
          {tFloorplanOverview('reason')}
          {' '}
        </p>
        <p>
          {tFloorplanOverview('extraCostDescription2')}
        </p>
      </div>
      {!hasEnoughBalance && (
      <div className={styles.notEnoughCoins}>
        {tCreateFloorPlan('notEnoughCoins')}
        <Button
          variant="link"
          onClick={() => {
            showModal(ModalKey.TOP_UP);
          }}
        >
          {tCreateFloorPlan('addMoreCoins')}
        </Button>
      </div>
      )}
      <div className={styles.buttons}>
        <Button
          disabled={submitting}
          variant="danger"
          onClick={requestRefund}
        >
          {tFloorplanOverview('requestRefund')}
        </Button>
        <Button
          disabled={submitting || !hasEnoughBalance}
          onClick={payExtraCost}
        >
          {tFloorplanOverview('pay')}
          {' '}
          {floorPlan.metadata.extraCost.coins}
          {' x '}
          <CoinIcon />
        </Button>
      </div>
    </div>
  );
}
