import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Layout from 'components/Common/Layout';
import { FloorPlanStatus } from 'auto-design-common';
import ReactLoading from 'components/Common/ReactLoading';
import { useQueryParam, StringParam } from 'use-query-params';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as BackIcon } from 'assets/images/ic-line-arrow-left.svg';
import FloorPlanStatusBadge from 'components/Common/FloorPlanStatusBadge';
import { ReactComponent as LoadingImg } from 'assets/images/loading-monitor.svg';
import styles from './FloorPlanOverview.module.scss';
import FloorPlan2D from './FloorPlan2D';
import FloorPlanInputFiles from './FloorPlanInputFiles';
import FloorPlan3D from './FloorPlan3D';
import ChangeRequests from './ChangeRequests';
import ExtraServices from './ExtraServices';
import ExtraCost from './ExtraCost';
import useFloorPlan from './useFloorPlan';

const Tab = {
  TWO_D: '2d',
  THREE_D: '3d',
  INPUTS: 'inputs',
  CHANGE_REQUESTS: 'change_requests',
  EXTRA_SERVICES: 'extra_services',
};

export default function FloorPlanOverview() {
  const { floorPlanId } = useParams();
  const history = useHistory();
  const { tFloorplanOverview, tFloorPlanPackageShort } = useTranslation();
  const [showDesignerNotes, setShowDesignerNotes] = useState(false);

  const {
    floorPlan,
    refetchFloorPlan,
    has2D,
    has3D,
  } = useFloorPlan({ floorPlanId });

  const [currentTab = Tab.TWO_D, setTab] = useQueryParam('tab', StringParam);

  const isExtraCostApproval = floorPlan?.status === FloorPlanStatus.EXTRA_COST_APPROVAL;
  const isProcessing = floorPlan?.status === FloorPlanStatus.CREATED;
  const isFileRequest = floorPlan?.status === FloorPlanStatus.REQUEST_FILES;
  const isUnusable = isExtraCostApproval || isProcessing || isFileRequest;

  console.log({
    isFileRequest,
  });

  const TABS = useMemo(() => [
    has2D && {
      id: Tab.TWO_D,
      name: tFloorplanOverview('tabs.2d'),
    },
    has3D && {
      id: Tab.THREE_D,
      name: tFloorplanOverview('tabs.3d'),
    },
    {
      id: Tab.INPUTS,
      name: tFloorplanOverview('tabs.inputs'),
    },
    {
      id: Tab.CHANGE_REQUESTS,
      name: tFloorplanOverview('tabs.changeRequests'),
    },
    {
      id: Tab.EXTRA_SERVICES,
      name: tFloorplanOverview('tabs.extraServices'),
    },
  ].filter(i => !!i), [tFloorplanOverview, has2D, has3D]);

  useEffect(() => {
    if (has3D && !has2D && currentTab === Tab.TWO_D) {
      setTab(Tab.THREE_D);
    }
  }, [currentTab, has2D, has3D, setTab]);

  useEffect(() => {
    if (has2D && !has3D && currentTab === Tab.THREE_D) {
      setTab(Tab.TWO_D);
    }
  }, [currentTab, has2D, has3D, setTab]);

  if (!floorPlan) {
    return (
      <Layout>
        <div className={styles.loaderWrapper}>
          <ReactLoading />
        </div>
      </Layout>
    );
  }

  return (
    <Layout contentStyle={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={styles.header}>
        <div className={styles.info}>
          <button
            className={styles.backButton}
            type="button"
            onClick={() => {
              history.push('/floor-plans');
            }}
          >
            <BackIcon fill="white" />
          </button>
          <div className={styles.name}>
            {floorPlan.name}
          </div>
          <FloorPlanStatusBadge status={floorPlan.status} />

          <div className={styles.packages}>
            {floorPlan.packages.map(p => (
              <span className={styles.package} key={p}>
                {tFloorPlanPackageShort(p)}
              </span>
            ))}
          </div>
        </div>

        {floorPlan.designerNotes.trim() && (
        <Alert
          variant="warning"
          className={styles.designerNotes}
          onClick={() => {
            setShowDesignerNotes(!showDesignerNotes);
          }}
        >
          {showDesignerNotes ? floorPlan.designerNotes : tFloorplanOverview('seeDesignerNotes')}
        </Alert>
        )}

        <div className={styles.tabs}>
          {!isUnusable && TABS.map(tab => (
            <div
              key={tab.id}
              className={classNames(styles.tab, currentTab === tab.id && styles.active)}
              onClick={() => {
                setTab(tab.id);
              }}
            >
              {tab.name}
            </div>
          ))}
        </div>

      </div>

      {!isUnusable && (
        <>
          {currentTab === Tab.TWO_D && (
          <FloorPlan2D />
          )}
          {currentTab === Tab.INPUTS && (
          <div className={styles.filesContainer}>
            <FloorPlanInputFiles readOnly />
          </div>
          )}
          {currentTab === Tab.THREE_D && (
          <FloorPlan3D />
          )}
          {currentTab === Tab.CHANGE_REQUESTS && (
          <ChangeRequests />
          )}
          {currentTab === Tab.EXTRA_SERVICES && (
          <ExtraServices />
          )}
        </>
      )}

      {isExtraCostApproval && (
      <ExtraCost
        floorPlan={floorPlan}
        refetchFloorPlan={refetchFloorPlan}
      />
      )}

      {isProcessing && (
        <div className={styles.processing}>
          <LoadingImg height={200} />
          <div className={styles.description}>
            {tFloorplanOverview('processing')}
          </div>
        </div>
      )}

      {isFileRequest && (
        <div className={styles.filesContainer}>
          <Alert variant="warning" className={styles.alert}>
            <p>
              {tFloorplanOverview('requestFilesTitle')}
            </p>
            <p>
              {floorPlan.metadata.fileRequest.reason}
            </p>
          </Alert>

          <FloorPlanInputFiles />

        </div>
      )}

    </Layout>
  );
}
