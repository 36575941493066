import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ImageType } from 'auto-design-common';
import * as api from 'utils/api';
import darkLogo from 'assets/generated/images/logo-dark.png';
import NotificationDropdown from './NotificationDropdown';

export default function EditorHeader({
  className,
  imageId,
  name,
}) {
  const { data: image } = useQuery({
    queryKey: ['image', imageId],
    queryFn: () => api.getImage(imageId),
  });
  const history = useHistory();

  const onClickLogo = useCallback(() => {
    history.push('/');
  }, [history]);

  if (!image) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 32,
        flexGrow: 1,
        marginRight: 32,
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: 32,
        flexGrow: 1,
      }}
      >
        <img
          alt="Logo"
          src={darkLogo}
          className={className}
          onClick={() => {
            onClickLogo();
          }}
          height={56}
        />
        {image?.type !== ImageType.FLOOR_PLAN && (
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => {
            history.push(`/locations/${image.location.id}`);
          }}
          >
            {image.location.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {image.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{name}</Breadcrumb.Item>
        </Breadcrumb>
        )}
        {image.type === ImageType.FLOOR_PLAN && (
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => {
            history.push(`/floor-plans/${image.unit.floorPlan.id}`);
          }}
          >
            {image.unit.floorPlan.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {image.unit.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{name}</Breadcrumb.Item>
        </Breadcrumb>
        )}
      </div>
      <NotificationDropdown />
    </div>
  );
}
