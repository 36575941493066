import configs from 'configuration';

export const STORAGE_PREFIX = 'auto_design';

export const TOUR_ZOOM_DURATION = 1; // seconds

export const INSTRUCTION_2D_VIDEO = configs.instruction2dVideo || 'https://www.youtube.com/watch?v=cO6su8TdBQY';
export const INSTRUCTION_360_VIDEO = 'https://www.youtube.com/watch?v=KJ2ZG7vER6s';

export const SidebarMenuState = {
  COLLAPSED: 1,
  MENU: 2,
  DETAILS: 3,
};

export const DEFAULT_MAX_FLOOR_PLAN_CAMERAS = 3;
