import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as api from 'utils/api';
import intersection from 'lodash/intersection';
import { FloorPlanPackage } from 'auto-design-common';

export default function useFloorPlan({
  floorPlanId,
}) {
  const { data: floorPlan, refetch: refetchFloorPlan } = useQuery({
    queryKey: ['floorPlan', floorPlanId],
    queryFn: () => api.getFloorPlanDetails(floorPlanId),
  });

  const packages = useMemo(() => floorPlan?.packages || [], [floorPlan]);

  const has2D = useMemo(() => intersection(packages, [FloorPlanPackage.COLOR_2D, FloorPlanPackage.COLOR_2D_WITH_SITE_PLAN, FloorPlanPackage.STANDARD_2D, FloorPlanPackage.STANDARD_2D_WITH_SITE_PLAN]).length > 0, [packages]);

  const has3D = useMemo(() => packages.includes(FloorPlanPackage.STANDARD_3D), [packages]);

  const requestedVideo = useMemo(() => packages.includes(FloorPlanPackage.VIDEO), [packages]);

  const requestedCAD = useMemo(() => packages.includes(FloorPlanPackage.CAD), [packages]);

  const requestedTour = useMemo(() => packages.includes(FloorPlanPackage.TOUR), [packages]);

  return {
    floorPlan,
    refetchFloorPlan,
    has2D,
    has3D,
    requestedVideo,
    requestedCAD,
    requestedTour,
  };
}
