import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from 'components/Common/Layout';
import RoomCategories from './RoomCategories';
import Rooms from './Rooms';
import RoomItems from './RoomItems';
import Categories from './Categories';
import CategoryItems from './CategoryItems';

export default function Locations() {
  return (
    <Layout className="catalog">
      <Switch>
        <Route path="/catalog/rooms/:roomId/categories/:childRoomId/items">
          <RoomItems />
        </Route>
        <Route path="/catalog/rooms/:roomId/categories">
          <RoomCategories />
        </Route>
        <Route path="/catalog/rooms">
          <Rooms />
        </Route>
        <Route path="/catalog/categories/:categoryId/items">
          <CategoryItems />
        </Route>
        <Route path="/catalog/categories">
          <Categories />
        </Route>
        <Redirect to="/catalog/rooms" />
      </Switch>
    </Layout>
  );
}
