export const ModalKey = {
  TOP_UP: 'TOP_UP',
  CHECKOUT: 'CHECKOUT',
  ITEM_DETAIL: 'ITEM_DETAIL',
  RENDER_SENT: 'RENDER_SENT',
  EDIT_LOCATION: 'EDIT_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  WHAT_WE_DO: 'WHAT_WE_DO',
  CONTACT_FOR_SERVICE: 'CONTACT_FOR_SERVICE',
  IMAGE_UPLOADED: 'IMAGE_UPLOADED',
  VERSION_HISTORY: 'VERSION_HISTORY',
  EDIT_IMAGE: 'EDIT_IMAGE',
  CONFIRM_SWITCHING_VERSION: 'CONFIRM_SWITCHING_VERSION',
  PANO_IMAGE_INTRO: 'PANO_IMAGE_INTRO',
  RESET_PASSWORD: 'RESET_PASSWORD',
  TOUR_PUBLISH_SUCCESSFULLY: 'TOUR_PUBLISH_SUCCESSFULLY',
  VR_NOT_SUPPORTED: 'VR_NOT_SUPPORTED',
  TOUR_VERSIONS: 'TOUR_VERSIONS',
  ADD_WATERMARK: 'ADD_WATERMARK',
  EDIT_PERSONAL_COLLECTION: 'EDIT_PERSONAL_COLLECTION',
  ADD_ITEM_TO_PERSONAL_COLLECTIONS: 'ADD_ITEM_TO_PERSONAL_COLLECTIONS',
  LOADING: 'LOADING',
  CHOOSE_RENDER_TO_COPY: 'CHOOSE_RENDER_TO_COPY',
  INVITE_FRIENDS: 'INVITE_FRIENDS',
  ADD_API_KEY: 'ADD_API_KEY',
  ADD_WEBHOOK: 'ADD_WEBHOOK',
  AUTO_STAGING_SELECT_BUNDLES: 'AUTO_STAGING_SELECT_BUNDLES',
  FILES_UPLOADED: 'FILES_UPLOADED',
  FLOOR_PLAN_3D_VERSION_HISTORY: 'FLOOR_PLAN_3D_VERSION_HISTORY',
  FLOOR_PLAN_2D_STYLES: 'FLOOR_PLAN_2D_STYLES',
  CONFIRM_PAY_EXTRA_SERVICE: 'CONFIRM_PAY_EXTRA_SERVICE',
  FLOOR_PLAN_2D_PACKAGES: 'FLOOR_PLAN_2D_PACKAGES',
  CONFIRM_PURCHASE_EXTRA_CAMERA: 'CONFIRM_PURCHASE_EXTRA_CAMERA',
};
