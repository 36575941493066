import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as api from 'utils/api';
import darkLogo from 'assets/generated/images/logo-dark.png';

export default function FloorPlanEditorHeader({
  className,
  floorPlanId,
  unitId,
  name,
}) {
  const { data: floorPlan } = useQuery({
    queryKey: ['floor-plan', floorPlanId],
    queryFn: () => api.getFloorPlanDetails(floorPlanId),
  });
  const history = useHistory();

  const onClickLogo = useCallback(() => {
    history.push('/');
  }, [history]);

  const unit = useMemo(() => {
    if (!floorPlan) {
      return null;
    }

    return floorPlan.units.find(u => u.id === parseInt(unitId));
  }, [floorPlan, unitId]);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 32,
    }}
    >
      <img
        alt="Logo"
        src={darkLogo}
        className={className}
        onClick={() => {
          onClickLogo();
        }}
        height={56}
      />
      {floorPlan && (
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => {
            history.push(`/floor-plans/${floorPlan.id}`);
          }}
          >
            {floorPlan.name}
          </Breadcrumb.Item>
          {unit && (
            <Breadcrumb.Item active>
              {unit.name}
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>{name}</Breadcrumb.Item>
        </Breadcrumb>
      )}

    </div>
  );
}
