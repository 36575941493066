import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import creditCardPaymentImage from 'assets/images/credit-card-payment.svg';
import Layout from 'components/Common/Layout';
import useTranslation from '../../hooks/useTranslation';

export default function PaymentSuccessful() {
  const { tPaymentSuccessful } = useTranslation();
  const history = useHistory();

  return (
    <Layout className="payment-successful">
      <img
        src={creditCardPaymentImage}
        alt="Payment"
      />
      <div className="title">
        {tPaymentSuccessful('title')}
      </div>
      <div className="sub-title">
        {tPaymentSuccessful('subTitle')}
      </div>
      <Button
        onClick={() => {
          history.push('/location');
        }}
      >
        {tPaymentSuccessful('button')}
      </Button>
    </Layout>
  );
}
