import Layout from 'components/Common/Layout';
import React from 'react';
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';
import { NumberParam, useQueryParam } from 'use-query-params';
import * as api from 'utils/api';
import { Table } from 'react-bootstrap';
import CustomPagination from 'components/Common/CustomPagination';
import { FloorPlanPackageName } from 'auto-design-common';
import styles from './TransactionHistory.module.scss';

const ITEMS_PER_PAGE = 10;

export default function TransactionHistory() {
  const { tTransactionHistory } = useTranslation();
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const { data } = useQuery({
    queryKey: ['transactions', page],
    queryFn: () => api.getTransactions({
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
    }),
  });

  return (
    <Layout>
      <div className={styles.title}>
        {tTransactionHistory('title')}
      </div>
      <div className="container">
        {data && (
        <>
          <div className={styles.tableWrapper}>
            <Table className={styles.table}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Description</th>
                  <th>Coins</th>
                  <th>Created At</th>
                  <th>{' '}</th>
                </tr>
              </thead>
              <tbody>
                {data.transactions.map(transaction => (
                  <tr key={transaction.id}>
                    <td>
                      {transaction.id}
                    </td>
                    <td>
                      {tTransactionHistory(`transactionName.${transaction.type}`)}
                      {transaction.floorPlan && (
                      <>
                        {' ('}
                        {tTransactionHistory('floorPlan')}
                        :
                        {' '}
                        <a
                          href="#"
                          onClick={() => {
                            window.open(`/floor-plans/${transaction.floorPlan.id}`);
                          }}
                        >
                          {transaction.floorPlan.name}
                        </a>
                        )
                      </>
                      )}
                      {transaction.image && (
                      <>
                        {' ('}
                        {tTransactionHistory('image')}
                        :
                        {' '}
                        <a
                          href="#"
                          onClick={() => {
                            window.open(`/images/${transaction.image.id}/edit`);
                          }}
                        >
                          {transaction.image.name}
                        </a>
                        )
                      </>
                      )}
                      {transaction.info.packages && transaction.info.packages.map(p => (
                        <div>
                          {FloorPlanPackageName[p]}
                        </div>
                      ))}
                    </td>
                    <td>
                      {transaction.quantity}
                    </td>
                    <td>
                      {moment.utc(transaction.createdAt).local().format('MM-DD-yyyy HH:mm')}
                    </td>
                    <td>{' '}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className={styles.pagination}>
            <CustomPagination
              page={page}
              setPage={setPage}
              numPages={Math.ceil(data.count / ITEMS_PER_PAGE)}
            />
          </div>
        </>
        )}
      </div>

    </Layout>
  );
}
