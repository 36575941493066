import useTranslation from 'hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Form, Button, Modal } from 'react-bootstrap';
import { getBase64Strings } from 'exif-rotate-js';
import { getFileUrl } from 'utils/file';
import emitter, { Events } from 'utils/event';
import styles from './FloorPlan2dStyles.module.scss';

const FormGroup = ({ children }) => (
  <Form.Group className={styles.formGroup}>{children}</Form.Group>
);

export default ({
  onCancel,
  style,
  onDone,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { tFloorPlan2dStylesModal } = useTranslation();

  const { register, handleSubmit, isSubmitting, control, setValue } = useForm({
    defaultValues: {
      name: style?.name || 'New style',
      disclaimerText: style?.config.disclaimerText || 'FLOOR PLAN CREATED BY BETATES',
      logo: null,
      logoFileId: style?.config.logoFileId,
    },
  });

  const { field: logoField } = useController({
    control,
    name: 'logo',
  });

  const { field: logoFileIdField } = useController({
    control,
    name: 'logoFileId',
  });

  const [logoSrc, setLogoSrc] = useState(null);

  useEffect(() => {
    if (logoField.value) {
      getBase64Strings([logoField.value[0]], {
        maxSize: 10000,
        quality: 1,
      })
        .then(res => {
          setLogoSrc(res[0]);
        });
    } if (logoFileIdField.value) {
      setLogoSrc(getFileUrl(logoFileIdField.value));
    }
  }, [logoField.value, logoFileIdField.value]);

  useEffect(() => {
    emitter.addListener(Events.CHANGE_FLOOR_PLAN_2D_STYLE_NAME, name => {
      setValue('name', name);
    });
  }, [setValue]);

  return (
    <>
      <Modal.Body>
        <FormGroup>
          <Form.Label>{tFloorPlan2dStylesModal('name')}</Form.Label>
          <Form.Control {...register('name', {
          })}
          />
        </FormGroup>
        <FormGroup>
          <Form.Label>{tFloorPlan2dStylesModal('disclaimerText')}</Form.Label>
          <Form.Control {...register('disclaimerText', {
          })}
          />
        </FormGroup>
        <FormGroup>
          <Form.Label>{tFloorPlan2dStylesModal('logo')}</Form.Label>
          {logoSrc && (
            <div>
              <img
                style={{
                  width: 100,
                }}
                src={logoSrc}
                alt="Logo"
              />
            </div>
          )}
          <Form.Control
            type="file"
            {...register('logo', {

            })}
          />
        </FormGroup>

      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isSubmitting || submitting}
          variant="secondary"
          onClick={() => {
            onCancel();
          }}
        >
          {tFloorPlan2dStylesModal('cancel')}
        </Button>
        <Button
          disabled={isSubmitting || submitting}
          onClick={() => {
            handleSubmit(async (data) => {
              setSubmitting(true);
              await onDone(data);
              setSubmitting(false);
            })();
          }}
        >
          {tFloorPlan2dStylesModal('save') }
        </Button>
      </Modal.Footer>
    </>
  );
};
