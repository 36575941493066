import classNames from 'classnames';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import styles from './StatusBadge.module.scss';

export const ProcessingBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.processing)}>
      {tStatusBadge('processing')}
    </div>
  );
};

export const ReadyBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.ready)}>
      {tStatusBadge('ready')}
    </div>
  );
};

export const FailedBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.failed)}>
      {tStatusBadge('failed')}
    </div>
  );
};

export const RefundedBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.refund)}>
      {tStatusBadge('refunded')}
    </div>
  );
};

export const ExtraCostApprovalBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.processing)}>
      {tStatusBadge('extra_cost_approval')}
    </div>
  );
};

export const RequestRefundBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.failed)}>
      {tStatusBadge('request_refund')}
    </div>
  );
};

export const RequestFilesBadge = () => {
  const { tStatusBadge } = useTranslation();
  return (
    <div className={classNames(styles.badge, styles.processing)}>
      {tStatusBadge('request_files')}
    </div>
  );
};
