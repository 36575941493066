import React, { useState, useRef } from 'react';
import { useNotificationContext } from 'components/Notification';
import moment from 'moment';
import { Badge, Button, Overlay } from 'react-bootstrap';
import useTranslation from 'hooks/useTranslation';
import { useHandleClickOutside } from 'auto-design-common';
import { ReactComponent as BellIcon } from 'assets/images/bell.svg';
import styles from './NotificationDropdown.module.scss';

export default function NotificationDropdown() {
  const { notifications, extracNotification, hasMore: hasMoreNotifications, loadMore: loadMoreNotifications, unreadCount, markAllAsRead } = useNotificationContext();
  const [showNotifications, setShowNotifications] = useState(false);
  const { tSidebar } = useTranslation();
  const notificationsRef = useRef();
  const buttonRef = useRef();

  useHandleClickOutside([notificationsRef, buttonRef], () => {
    setShowNotifications(false);
  });

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div>
      <button
        className={styles.notificationButton}
        type="button"
        onClick={toggleNotifications}
        ref={buttonRef}
      >
        {unreadCount > 0 && (
        <Badge className={styles.badge} bg="danger">{unreadCount}</Badge>
        )}
        <BellIcon width={24} height={24} />
      </button>
      <Overlay target={buttonRef} show={showNotifications} placement="bottom-end">
        <div className={styles.notifications} ref={notificationsRef}>
          <div className={styles.markAllAsReadWrapper}>
            <Button
              variant="link"
              onClick={() => {
                markAllAsRead();
                setShowNotifications(false);
              }}
            >
              {tSidebar('markAllAsRead')}
            </Button>
          </div>

          {notifications.map(notification => {
            const { text, onClick } = extracNotification(notification);
            const createdAt = moment.utc(notification.createdAt).local();
            const now = moment();

            let timeStr;

            if (now.diff(createdAt, 'd') >= 1) {
              timeStr = createdAt.format('MM-DD-yyyy HH:mm');
            } else {
              timeStr = createdAt.from();
            }

            return (
              <div
                onClick={() => {
                  onClick();
                  setShowNotifications(false);
                }}
                className={styles.notification}
                key={notification.id}
              >
                <div className={styles.content}>
                  <div className={styles.text}>
                    {text}
                  </div>
                  <div className={styles.time}>
                    {timeStr}
                  </div>
                </div>
                {!notification.isRead && (
                  <div className={styles.dot} />
                )}
              </div>
            );
          })}
          {hasMoreNotifications && (
            <div className={styles.loadMoreWrapper}>
              <Button variant="link" onClick={loadMoreNotifications}>{tSidebar('loadMore')}</Button>
            </div>
          )}

        </div>
      </Overlay>
    </div>
  );
}
