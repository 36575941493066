import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import useTranslation from 'hooks/useTranslation';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as api from 'utils/api';
import makeAnimated from 'react-select/animated';
import emitter, { Events } from 'utils/event';
import { getFileUrl } from 'utils/file';
import { applyStyle } from 'components/FloorPlanOverview/FloorPlan2D/utils';
import cssStyles from './FloorPlan2dStyles.module.scss';
import EditStyle from './EditStyle';

const animatedComponents = makeAnimated();

const Screen = {
  SELECTION: 'selection',
  CREATE: 'create',
  EDIT: 'edit',
};

export default function FloorPlan2dStyles({
  onClose,
  floorPlanId,
  selectedStyleId,
}) {
  const { tFloorPlan2dStylesModal } = useTranslation();
  const { data: styles, refetch: refetchStyles } = useQuery({
    queryFn: () => api.getFloorPlan2dStyles(),
    queryKey: ['floor-plan-2d-styles'],
  });
  const [currentStyle, setCurrentStyle] = useState(null);
  const [screen, setScreen] = useState(Screen.SELECTION);
  const [applying, setApplying] = useState(false);

  const { mutateAsync: updateStyle } = useMutation({
    mutationFn: ({ id, data }) => api.updateFloorPlan2dStyle(id, data),
    onSuccess: (data) => {
      setCurrentStyle(data);
    },
  });

  const { mutateAsync: updateFloorPlan } = useMutation({
    mutationFn: (data) => api.updateFloorPlan(floorPlanId, data),
  });

  const { mutateAsync: createStyle } = useMutation({
    mutationFn: data => api.createFloorPlan2dStyle(data),
    onSuccess: (data) => {
      setCurrentStyle(data);
    },
  });

  const { data: floorPlan } = useQuery({
    queryKey: ['floorPlan', floorPlanId],
    queryFn: () => api.getFloorPlanDetails(floorPlanId),
  });

  const options = useMemo(() => styles?.map(s => ({
    value: s.id,
    label: s.name,
  })) || [], [styles]);

  useEffect(() => {
    if (!styles || !selectedStyleId) {
      return;
    }

    setCurrentStyle(value => styles.find(s => s.id === (value?.id || selectedStyleId)));
  }, [styles, selectedStyleId]);

  if (!styles) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      size="md"
    >
      <Modal.Header closeButton>
        {tFloorPlan2dStylesModal('title')}
      </Modal.Header>

      {screen === Screen.SELECTION && (
        <>
          <Modal.Body style={{
            minHeight: 260,
          }}
          >
            <Form.Group>
              <Form.Label>
                {tFloorPlan2dStylesModal('chooseStyle')}
              </Form.Label>
              <CreatableSelect
                isClearable={false}
                maxMenuHeight={150}
                value={currentStyle ? {
                  value: currentStyle.id,
                  label: currentStyle.name,
                } : null}
                components={animatedComponents}
                closeMenuOnSelect
                options={options}
                onChange={(value) => {
                  setCurrentStyle(value ? styles.find(s => s.id === value.value) : null);
                }}
                onCreateOption={name => {
                  setScreen(Screen.CREATE);
                  setTimeout(() => {
                    emitter.emit(Events.CHANGE_FLOOR_PLAN_2D_STYLE_NAME, name);
                  });
                }}
              />
            </Form.Group>
            {currentStyle && (
              <div className={cssStyles.currentStyle}>
                <div className={cssStyles.field}>
                  <label>{tFloorPlan2dStylesModal('name')}</label>
                  <div>{currentStyle.name}</div>
                </div>
                <div className={cssStyles.field}>
                  <label>{tFloorPlan2dStylesModal('disclaimerText')}</label>
                  <div>{currentStyle.config.disclaimerText}</div>
                </div>
                <div className={cssStyles.field}>
                  <label>{tFloorPlan2dStylesModal('logo')}</label>
                  <div>
                    {currentStyle.config.logoFileId ? (
                      <img
                        style={{
                          width: 100,
                        }}
                        src={getFileUrl(currentStyle.config.logoFileId)}
                        alt="logo"
                      />
                    ) : 'None'}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {currentStyle && (
              <Button
                variant="secondary"
                onClick={() => {
                  setScreen(Screen.EDIT);
                }}
              >
                {tFloorPlan2dStylesModal('edit')}
              </Button>
            )}
            <Button
              disabled={!currentStyle || applying}
              onClick={async () => {
                setApplying(true);
                try {
                  for (const unit of floorPlan.units) {
                    // eslint-disable-next-line no-await-in-loop
                    const blob = await applyStyle(unit.output_2dFileId, currentStyle);
                    // eslint-disable-next-line no-await-in-loop
                    await api.uploadFloorPlan2dStyledOutputFile(floorPlanId, unit.id, blob);
                  }

                  await updateFloorPlan({
                    styleId: currentStyle.id,
                  });
                  onClose();
                } finally {
                  setApplying(false);
                }
              }}
            >
              {tFloorPlan2dStylesModal('applyStyle')}
            </Button>
          </Modal.Footer>
        </>
      )}

      {screen === Screen.CREATE && (
        <EditStyle
          onCancel={() => {
            setScreen(Screen.SELECTION);
          }}
          onDone={async (data) => {
            const res = await createStyle({
              ...data,
              logo: data.logo?.[0],
            });
            console.log({ res });
            setScreen(Screen.SELECTION);
            await refetchStyles();
          }}
        />
      )}

      {screen === Screen.EDIT && currentStyle && (
        <EditStyle
          style={currentStyle}
          onCancel={() => {
            setScreen(Screen.SELECTION);
          }}
          onDone={async (data) => {
            await updateStyle({ id: currentStyle.id,
              data: {
                ...data,
                logo: data.logo?.[0],
              },
            });
            setScreen(Screen.SELECTION);
            refetchStyles();
          }}
        />
      )}

    </Modal>
  );
}
