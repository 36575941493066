import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from 'utils/api';
import videoImg from 'assets/images/3d-video-render.png';
import cadImg from 'assets/images/cad.jpg';
import vrTourImg from 'assets/images/landing/tour-service.jpg';
import { FloorPlanFileSource, FloorPlanPackage, useToastContext } from 'auto-design-common';
import { getFileUrl } from 'utils/file';
import useTranslation from 'hooks/useTranslation';
import { useAppContext } from 'components/AppContext';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import styles from './ExtraServices.module.scss';
import useFloorPlan from '../useFloorPlan';
import FloorPlan3DPackage from './FloorPlan3DPackage';
import FloorPlan2DPackage from './FloorPlan2DPackage';

function Service({
  name,
  description,
  img,
  renderResult,
  renderActionButton,
}) {
  return (
    <div className={styles.service}>
      <div className={styles.content}>
        <img alt="Thumbnail" className={styles.thumbnail} src={img} />
        <div className={styles.title}>
          {name}
        </div>
        <div className={styles.description}>
          {description}
        </div>
        {renderResult?.()}
      </div>
      <div className={styles.separator} />
      <div className={styles.buttons}>
        {renderActionButton()}
      </div>
    </div>
  );
}

export default function ExtraServices() {
  const { showModal } = useModalContext();
  const { toastError } = useToastContext();
  const { request, configs, getInfo, user } = useAppContext();
  const { tFloorplanOverview, tCommon } = useTranslation();
  const { floorPlanId } = useParams();
  const { data: files } = useQuery({
    queryKey: ['floor-plan', floorPlanId, 'floor-plan-files'],
    queryFn: () => api.getFloorPlanFiles(floorPlanId),
  });

  const { has2D, has3D, requestedVideo, requestedCAD, requestedTour, refetchFloorPlan, floorPlan } = useFloorPlan({
    floorPlanId,
  });

  const history = useHistory();

  const videoFiles = useMemo(() => files?.filter(f => f.source === FloorPlanFileSource.VIDEO) || [], [files]);

  const cadFiles = useMemo(() => files?.filter(f => f.source === FloorPlanFileSource.CAD) || [], [files]);

  const gotVideo = videoFiles.length > 0;
  const gotCad = cadFiles.length > 0;

  const requestVideo = async () => {
    const showConfirm = () => {
      showModal(ModalKey.CONFIRM_PAY_EXTRA_SERVICE, {
        price: configs.floor_plan_video_price,
        onAccept: async () => {
          await request(api.purchasePackages(floorPlanId, [FloorPlanPackage.VIDEO]));
          getInfo();
          await refetchFloorPlan();
        },
      });
    };
    if (user.balance >= configs.floor_plan_video_price) {
      showConfirm();
    } else {
      showModal(ModalKey.TOP_UP, {
        onDone: showConfirm,
      });
    }
  };

  const requestCAD = async () => {
    const showConfirm = () => {
      showModal(ModalKey.CONFIRM_PAY_EXTRA_SERVICE, {
        price: configs.floor_plan_cad_price,
        onAccept: async () => {
          await request(api.purchasePackages(floorPlanId, [FloorPlanPackage.CAD]));
          getInfo();
          await refetchFloorPlan();
        },
      });
    };

    if (user.balance >= configs.floor_plan_cad_price) {
      showConfirm();
    } else {
      showModal(ModalKey.TOP_UP, {
        onDone: showConfirm,
      });
    }
  };

  const onClickTour = async () => {
    if (requestedTour) {
      history.push(`/tours/${floorPlan.tourId}/edit`);
    } else {
      const showConfirm = () => {
        showModal(ModalKey.CONFIRM_PAY_EXTRA_SERVICE, {
          price: configs.floor_plan_tour_price,
          onAccept: async () => {
            const { result, success, error } = await request(api.createFloorPlanTour(floorPlanId));
            getInfo();
            await refetchFloorPlan();
            if (success) {
              history.push(`/tours/${result.id}/edit`);
            } else {
              toastError(error);
            }
          },
        });
      };

      if (user.balance >= configs.floor_plan_tour_price) {
        showConfirm();
      } else {
        showModal(ModalKey.TOP_UP, {
          onDone: showConfirm,
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-md-4">
          <Service
            img={videoImg}
            name={tFloorplanOverview('3dVideoTour')}
            description={tFloorplanOverview('3dVideoTourDescription')}
            renderResult={() => gotVideo && (
            <div className={styles.files}>
              <div className={styles.filesTitle}>
                {tFloorplanOverview('result')}
              </div>
              {videoFiles.map(f => (
                <a key={f.id} href={getFileUrl(f.fileId)} className={styles.fileLink}>
                  {f.name}
                </a>
              ))}
            </div>
            )}
            renderActionButton={() => (
              <button
                onClick={requestVideo}
                className={styles.button}
                type="button"
                disabled={gotVideo || requestedVideo}
              >
                {(requestedVideo ? tFloorplanOverview('requested') : (
                  <>
                    {tFloorplanOverview('request')}
                    {' ('}
                    {configs.floor_plan_video_price}
                    {' x '}
                    <CoinIcon />
                    {') '}
                  </>
                )) }
              </button>
            )}
          />
        </div>
        <div className="col-md-4">
          <Service
            img={cadImg}
            name={tFloorplanOverview('cadFile')}
            description={tFloorplanOverview('cadFileDescription')}
            renderResult={() => gotCad && (
              <div className={styles.files}>
                <div className={styles.filesTitle}>
                  {tFloorplanOverview('result')}
                </div>
                {cadFiles.map(f => (
                  <a key={f.id} href={getFileUrl(f.fileId)} className={styles.fileLink}>
                    {f.name}
                  </a>
                ))}
              </div>
            )}
            renderActionButton={() => (
              <button
                onClick={requestCAD}
                className={styles.button}
                type="button"
                disabled={gotCad || requestedCAD}
              >
                {(requestedCAD ? tFloorplanOverview('requested') : (
                  <>
                    {tFloorplanOverview('request')}
                    {' ('}
                    {configs.floor_plan_cad_price}
                    {' x '}
                    <CoinIcon />
                    {') '}
                  </>
                )) }
              </button>
            )}
          />
        </div>
        {!has3D && (
          <div className="col-md-4">
            <FloorPlan3DPackage />
          </div>
        )}
        {!has2D && (
          <div className="col-md-4">
            <FloorPlan2DPackage />
          </div>
        )}
        {has3D && (
          <div className="col-md-4">
            <Service
              name={tFloorplanOverview('vrTour')}
              description={tFloorplanOverview('vrTourDescription')}
              img={vrTourImg}
              renderActionButton={() => (
                <button
                  onClick={onClickTour}
                  className={styles.button}
                  type="button"
                >
                  {(requestedTour ? tCommon('view') : (
                    <>
                      {tFloorplanOverview('request')}
                      {' ('}
                      {configs.floor_plan_tour_price}
                      {' x '}
                      <CoinIcon />
                      {') '}
                    </>
                  )) }
                </button>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
