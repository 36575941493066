import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default function IntegerInput({
  value,
  onChange,
  ...props
}) {
  const [tempValue, setTempValue] = useState(value.toString());

  useEffect(() => {
    setTempValue(value.toString());
  }, [value]);

  return (
    <Form.Control
      type="number"
      step={1}
      value={tempValue}
      onChange={e => {
        setTempValue(e.target.value);
      }}
      onBlur={() => {
        const number = parseInt(tempValue);
        onChange(Number.isNaN(number) ? 0 : number);
      }}
      {...props}
    />
  );
}
