import QueryString from 'query-string';
import { post, get, put, del } from 'utils/request';
import { dataURItoBlob } from 'auto-design-common';
import configs from 'configuration';
import { getToken } from './auth';
import { extractNameBaseOnTranslation } from './extractNameBaseOnTranslation';

export const loginWithGoogle = (idToken, referToken) => post('/auth/google', {
  idToken,
  referToken,
});

export const getInfo = () => get('/me/info');

export const updateInfo = (data) => put('/me/info', data);

export const getReferToken = () => get('/me/refer-token');

export const findAccount = email => get('/users/find-account', {
  email,
});

export const login = (email, password) => post('/auth/login', {
  email,
  password,
});

export const signup = (email, password, referToken) => post('/auth/signup', {
  email,
  password,
  referToken,
});

export const getConfigs = () => get('/configs');

export const createTopUpOrder = (quantity) => post('/me/transactions', {
  quantity,
  type: 'top_up',
});

export const captureOrder = (paypalOrderId) => post('/me/transactions/captures', {
  paypalOrderId,
});

export const getLocations = (searchText) => {
  const url = '/me/locations';

  if (searchText) {
    return get(`${url}?name=${searchText}`);
  }

  return get(url);
};

export const createLocation = (name) => post('/me/locations', {
  name,
});

export const updateLocation = (id, data) => put(`/me/locations/${id}`, data);

export const deleteLocation = id => del(`/me/locations/${id}`);

export const getLocation = (id) => get(`/me/locations/${id}`);

export const uploadImage = ({
  name,
  locationId,
  background,
  type,
  autoStaging,
}) => {
  console.log(background);
  const formData = new FormData();
  formData.append('background', dataURItoBlob(background.data), background.file.name);
  formData.append('name', name);
  formData.append('locationId', locationId);
  formData.append('type', type);
  formData.append('autoStaging', JSON.stringify(autoStaging));
  return post('/me/images', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const getImage = (id) => get(`/me/images/${id}`);

export const updateImage = (id, data) => put(`/me/images/${id}`, data);

export const render = (id, data) => post(`/me/images/${id}/renders`, data);

export const getProgresses = () => get('/me/progresses');

export const getProgress = id => get(`/me/progresses/${id}`);

export const markProgressAsRead = (progressId) => put(`/me/progresses/${progressId}`, {
  read: true,
});

export const updateFurnitureLayer = (progressId, {
  furnitureOutputBlob,
  outputBlob,
  watermark,
  watermarkBlob,
  maskBlob,
  filterValues,
  hideBackground,
}) => {
  const formData = new FormData();
  if (furnitureOutputBlob) {
    formData.append('furnitureOutput', furnitureOutputBlob, 'furniture.png');
  }

  formData.append('output', outputBlob, 'output.png');
  formData.append('watermark', JSON.stringify(watermark));
  formData.append('watermarkBlob', watermarkBlob);
  formData.append('mask', maskBlob, 'mask.png');
  formData.append('filterValues', JSON.stringify(filterValues));
  formData.append('hideBackground', hideBackground);
  return post(`/me/progresses/${progressId}/furniture-layer`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const markAllProgressesAsRead = () => post('/me/progresses/read-all');

export const getCategories = async () => {
  const categories = await get('/furniture/categories');
  return categories.map((category) => ({
    ...category,
    name: extractNameBaseOnTranslation(category),
  }));
};

export const getRooms = async () => {
  const rooms = await get('/furniture/rooms');
  return rooms.map((room) => ({
    ...room,
    name: extractNameBaseOnTranslation(room),
    children: room.children.map((category) => ({
      ...category,
      name: extractNameBaseOnTranslation(category),
    })),
  }));
};

export const getRoom = async (roomId) => {
  const room = await get(`/furniture/rooms/${roomId}`);
  return {
    ...room,
    name: extractNameBaseOnTranslation(room),
    children: room.children.map((category) => ({
      ...category,
      name: extractNameBaseOnTranslation(category),
    })),
  };
};

export const getItems = async (params) => {
  const query = QueryString.stringify(params);
  const items = await get(`/furniture/items?${query}`);
  return items.map((item) => ({
    ...item,
    name: extractNameBaseOnTranslation(item),
  }));
};

export const getItem = async (id) => {
  const item = await get(`/furniture/items/${id}`);
  return {
    ...item,
    name: extractNameBaseOnTranslation(item),
  };
};

export const submitServiceRequest = data => post('/service-requests', data);

export const requestPasswordReset = (email) => post('/auth/password-reset', {
  email,
});

export const resetPassword = (token, password) => post('/auth/password-reset-confirmation', {
  token,
  password,
});

export const createTour = (locationId) => post('/me/tours', {
  locationId,
});

export const getTour = id => get(`/me/tours/${id}`);

export const saveTour = (id, data) => put(`/me/tours/${id}`, data);

export const publishTour = (id) => post(`/me/tours/${id}/versions`);

export const getTourVersion = (id) => get(`/tour-versions/${id}`);

export const getTours = () => get('/me/tours');

export const getTourVersions = tourId => get(`/me/tours/${tourId}/versions`);

export const deleteTourVersion = (tourId, versionId) => del(`/me/tours/${tourId}/versions/${versionId}`);

export const getWatermarks = () => get('/me/watermarks');

export const deleteWatermark = (id) => del(`/me/watermarks/${id}`);

export const uploadWatermark = (file) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return post('/me/watermarks', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const createPersonalCollection = (data) => post('/me/personal-collections', data);

export const editPersonalCollection = (id, data) => put(`/me/personal-collections/${id}`, data);

export const getPersonalCollections = () => get('/me/personal-collections');

export const deletePersonalCollection = id => del(`/me/personal-collections/${id}`);

export const addItemToPersonalCollections = (itemId, collectionIds) => put(`/me/personal-collections/items/${itemId}`, {
  collectionIds,
});

export const getPersonalCollectionsOfAnItem = (itemId) => get(`/me/personal-collections?itemId=${itemId}`);

export const getPersonalCollectionItems = async (collectionId) => {
  const items = await get(`/me/personal-collections/${collectionId}/items`);
  return items.map((item) => ({
    ...item,
    name: extractNameBaseOnTranslation(item),
  }));
};

export const getItemCategories = async (itemId) => {
  const categories = await get(`/furniture/items/${itemId}/categories`);
  return categories.map((category) => ({
    ...category,
    name: extractNameBaseOnTranslation(category),
  }));
};

export const getApiKeys = () => get('/me/api-keys');

export const createApiKey = (data) => post('/me/api-keys', data);

export const deleteApiKey = (id) => del(`/me/api-keys/${id}`);

export const getWebhooks = () => get('/me/webhooks');

export const createWebhook = (data) => post('/me/webhooks', data);

export const deleteWebhook = (id) => del(`/me/webhooks/${id}`);

export const cleanImageAutomatically = (file) => {
  const formData = new FormData();
  formData.append('image', file, file.name);
  return post('/image-clean/auto', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const cleanImageManually = async (image, mask) => {
  const formData = new FormData();
  formData.append('image', image, 'image.png');
  formData.append('mask', mask, 'mask.png');

  const response = await fetch(`${configs.apiUrl}/image-clean/manual`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'x-tenant-id': configs.tenantId,
    },
    body: formData,
  });

  return response.blob();
};

export const createFloorPlan = async (data) => post('/me/floor-plans', data);

export const uploadFloorPlanFile = async (floorPlanId, data) => post(`/me/floor-plans/${floorPlanId}/floor-plan-files`, data);

export const uploadFloorPlanFileUrl = async (floorPlanId, url) => post(`/me/floor-plans/${floorPlanId}/floor-plan-files`, {
  fileType: 'url',
  fileUrl: url,
});

export const finishUploadFloorPlan = (floorPlanId) => post(`/me/floor-plans/${floorPlanId}/actions`, {
  action: 'finish_upload',
});

export const getFloorPlanLogs = (floorPlanId) => get(`/me/floor-plans/${floorPlanId}/logs`);

export const submitChangeRequest = (imageId, changes) => post(`/me/floor-plans/${imageId}/actions`, {
  action: 'request_changes',
  changes,
});

export const getFloorPlanFiles = async (floorPlanId) => get(`/me/floor-plans/${floorPlanId}/floor-plan-files`);

export const uploadFile = async (file, isPublic = false) => {
  const { url, key } = await post('/files', {
    fileName: file.name,
    contentType: file.type,
    isPublic,
  });

  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });

  return {
    key,
  };
};

export const getFloorPlans = async () => get('/me/floor-plans');

export const getFloorPlanDetails = async (id) => get(`/me/floor-plans/${id}`);

export const updateUnit = async (floorPlanId, unitId, data) => put(`/me/floor-plans/${floorPlanId}/units/${unitId}`, data);

export const uploadFloorPlan2dOutputFile = (floorPlanId, unitId, blob) => {
  const formData = new FormData();

  formData.append('file', blob, 'output.png');

  return post(`/me/floor-plans/${floorPlanId}/units/${unitId}/2d-output`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const uploadFloorPlan2dStyledOutputFile = (floorPlanId, unitId, blob) => {
  const formData = new FormData();

  formData.append('file', blob, 'styled.png');

  return post(`/me/floor-plans/${floorPlanId}/units/${unitId}/styled-2d-output`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const getFloorPlan2dStyles = async () => get('/me/floor-plan-2d-styles');

export const createFloorPlan2dStyle = async (data) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('disclaimerText', data.disclaimerText);

  if (data.logo) {
    formData.append('logo', data.logo, data.logo.name);
  }

  return post('/me/floor-plan-2d-styles', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const updateFloorPlan2dStyle = async (id, data) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('disclaimerText', data.disclaimerText);

  if (data.logo) {
    formData.append('logo', data.logo, data.logo.name);
  }

  return put(`/me/floor-plan-2d-styles/${id}`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const deleteFloorPlan2dStyles = async (id) => del(`/me/floor-plan-2d-styles/${id}`);

export const applyStyle = (floorPlanId, styleId) => post(`/me/floor-plans/${floorPlanId}/actions`, {
  action: 'apply_style',
  styleId,
});

export const updateFloorPlan = (floorPlanId, data) => put(`/me/floor-plans/${floorPlanId}`, data);

export const requestRefund = (floorPlanId) => post(`/me/floor-plans/${floorPlanId}/refund-requests`, {});

export const approveExtraCost = (floorPlanId) => post(`/me/floor-plans/${floorPlanId}/charge-approvals`, {});

export const purchasePackages = (floorPlanId, packages) => post(`/me/floor-plans/${floorPlanId}/packages`, {
  packages,
});

export const endFileRequest = (floorPlanId, data) => post(`/me/floor-plans/${floorPlanId}/actions`, {
  action: 'end_file_request',
  ...data,
});

export const purchaseExtraCamera = (imageId) => post(`/me/images/${imageId}/extra-camera-purchases`);

export const getTransactions = (params) => get('/me/transactions', params);

export const getNotificaitons = params => get('/me/notifications', params);

export const readNotification = (id) => post(`/me/notifications/${id}/read`, {});

export const readAllNotification = () => post('/me/notifications/read-all', {});

export const getUnreadNotificationCount = () => get('/me/notifications/unread-count');

export const createFloorPlanTour = (floorPlanId) => post(`/me/floor-plans/${floorPlanId}/tours`);
