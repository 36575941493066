import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { getFileUrl } from 'utils/file';
import orderBy from 'lodash/orderBy';
import styles from './FloorPlan2D.module.scss';
import Sidebar from './Sidebar';
import useFloorPlan from '../useFloorPlan';

export default function FloorPlan2D() {
  const { floorPlanId } = useParams();
  const { floorPlan, refetchFloorPlan } = useFloorPlan({
    floorPlanId,
  });

  const units = useMemo(() => orderBy(floorPlan?.units || [], ['index', 'ASC']).filter(u => !!u.styled_2dFileId), [floorPlan]);
  const [selectedUnitId, setSelectedUnitId] = useState(null);

  const selectedUnit = useMemo(() => units.find(u => u.id === selectedUnitId), [units, selectedUnitId]);

  useEffect(() => {
    setSelectedUnitId(id => id || units[0]?.id || null);
  }, [units]);

  return (
    <div className={styles.container}>
      <div className={styles.thumbnails}>
        {units.map(unit => (
          <div
            className={classNames(styles.unitThumbnail, selectedUnit?.id === unit.id && styles.selected)}
            key={unit.id}
            onClick={() => {
              setSelectedUnitId(unit.id);
            }}
          >
            <img
              className={styles.thumbnail}
              src={getFileUrl(unit.styled_2dFileId)}
              alt={unit.name}
            />
            <div className={styles.name}>
              {unit.name}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.preview}>
        {selectedUnit && (
          <div>
            <img
              key={selectedUnit.styled_2dFileId}
              src={getFileUrl(selectedUnit.styled_2dFileId)}
              alt={selectedUnit.name}
            />
          </div>
        )}

      </div>
      {selectedUnit && (
        <Sidebar
          unit={selectedUnit}
          floorPlan={floorPlan}
          refetchFloorPlan={refetchFloorPlan}
        />
      )}
    </div>
  );
}
