import React, { useEffect, useMemo, useState } from 'react';
import intersection from 'lodash/intersection';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as FileIcon } from 'assets/images/create-floor-plan/file.svg';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { Button, Form } from 'react-bootstrap';
import { ReactComponent as UploadIcon } from 'assets/images/create-floor-plan/upload.svg';

import classNames from 'classnames';
import IntegerInput from 'components/Common/IntegerInput';
import { useAppContext } from 'components/AppContext';
import { FloorPlanPackage } from 'auto-design-common';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import styles from './Information.module.scss';
import { getPriceKey } from './utils';

export default function Information({
  files,
  urls,
  info,
  setInfo,
  onSubmit,
  submitting,
  packages,
  setPackages,
}) {
  const { configs, user } = useAppContext();
  const { tCreateFloorPlan } = useTranslation();
  const [previewedFile, setPreviewedFile] = useState(null);
  const { showModal } = useModalContext();

  const price = useMemo(() => {
    const base = packages.reduce((acc, p) => acc + configs[getPriceKey(p)], 0);

    return base * info.floors;
  }, [packages, configs, info.floors]);

  const hasEnoughBalance = user.balance >= price;

  const disabledUpload = submitting || !info.name.trim() || !hasEnoughBalance;

  const has2D = useMemo(() => intersection(packages, [FloorPlanPackage.COLOR_2D, FloorPlanPackage.COLOR_2D_WITH_SITE_PLAN, FloorPlanPackage.STANDARD_2D, FloorPlanPackage.STANDARD_2D_WITH_SITE_PLAN]).length > 0, [packages]);

  const has3D = useMemo(() => packages.includes(FloorPlanPackage.STANDARD_3D), [packages]);

  const insertFurniturePrice = useMemo(() => {
    let price = 0;

    if (has3D) {
      price += configs.floor_plan_insert_cameras_and_furniture_3d_price;
    }

    if (has2D) {
      price += configs.floor_plan_insert_furniture_2d_price;
    }

    return price;
  }, [has3D, has2D, configs]);

  useEffect(() => {
    if (info.selectedLocation) {
      setInfo(info => ({
        ...info,
        locationName: info.selectedLocation.name,
      }));
    }
  }, [info.selectedLocation, setInfo]);

  useEffect(() => {
    if (files.length > 0) {
      setPreviewedFile(files[0]);
    }
  }, [files]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          {tCreateFloorPlan('information.title')}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.filesAndInfo}>
          <div className={styles.filesSection}>
            {previewedFile && (
            <div className={styles.previewedFile}>
              {!previewedFile.thumbnailData && (
              <div className={styles.defaultThumbnail}>
                <FileIcon />
              </div>
              )}
              {previewedFile.thumbnailData && (
              <img src={previewedFile.thumbnailData} alt={previewedFile.name} className={styles.image} />
              )}
            </div>
            )}

            {files.length > 0 && (
            <div className={styles.files}>
              {files.map(file => (
                <div
                  key={file.id}
                  className={classNames(styles.file, previewedFile?.id === file.id && styles.selectedFile)}
                  onClick={() => {
                    setPreviewedFile(file);
                  }}
                >
                  <div className={styles.thumbnail}>
                    {!file.thumbnailData && (
                    <div className={styles.defaultThumbnail}>
                      <FileIcon />
                    </div>
                    )}
                    {file.thumbnailData && (
                    <img src={file.thumbnailData} alt={file.name} className={styles.image} />
                    )}
                  </div>
                  <div className={styles.name}>
                    {file.file.name}
                  </div>
                </div>
              ))}
            </div>
            )}

            {urls.length > 0 && (
            <div className={styles.urls}>
              {urls.map(url => (
                <div key={url.id} className={styles.url}>
                  <a href={url.url} target="_blank" rel="noreferrer">
                    {url.url}
                  </a>
                </div>
              ))}
            </div>
            )}

          </div>
          <div className={styles.informationSection}>
            <Form.Group className="form-group">
              <Form.Label>{tCreateFloorPlan('information.name')}</Form.Label>
              <Form.Control
                placeholder={tCreateFloorPlan('information.egName')}
                value={info.name}
                onChange={e => {
                  setInfo(info => ({
                    ...info,
                    name: e.target.value,
                  }));
                }}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{tCreateFloorPlan('information.howManyFloors')}</Form.Label>
              <IntegerInput
                value={info.floors}
                onChange={value => {
                  setInfo(info => ({
                    ...info,
                    floors: value,
                  }));
                }}
              />
            </Form.Group>
            <div
              className={styles.allowInsertContainer}
              onClick={() => {
                if (has2D) {
                  setPackages(packages => {
                    if (packages.includes(FloorPlanPackage.INSERT_FURNITURE_2D)) {
                      return packages.filter(p => p !== FloorPlanPackage.INSERT_FURNITURE_2D);
                    }
                    return [...packages, FloorPlanPackage.INSERT_FURNITURE_2D];
                  });
                }

                if (has3D) {
                  setPackages(packages => {
                    if (packages.includes(FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D)) {
                      return packages.filter(p => p !== FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D);
                    }
                    return [...packages, FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D];
                  });
                }
              }}
            >
              <div className={styles.text}>
                <div className={styles.title}>
                  {tCreateFloorPlan('information.allowInsert')}
                </div>
                <div className={styles.subtitle}>
                  {tCreateFloorPlan('information.extraFee')}
                  :
                  {' '}
                  <span className={styles.number}>
                    {insertFurniturePrice}
                    {' '}
                    x
                    {' '}
                  </span>
                  <CoinIcon />
                </div>
              </div>
              <Form.Check
                className={styles.checkbox}
                checked={intersection(packages, [FloorPlanPackage.INSERT_FURNITURE_2D, FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D]).length > 0}
                onChange={() => { }}
              />
            </div>
            <Form.Group className="form-group">
              <Form.Label>{tCreateFloorPlan('information.notes')}</Form.Label>
              <Form.Control
                value={info.userNotes}
                as="textarea"
                rows={5}
                onChange={e => {
                  setInfo(info => ({
                    ...info,
                    userNotes: e.target.value,
                  }));
                }}
              />
            </Form.Group>
            <div className={styles.extraCost}>
              {tCreateFloorPlan('extraCost')}
            </div>

          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.footer}>
          {!hasEnoughBalance && (
          <div className={styles.notEnoughCoins}>
            {tCreateFloorPlan('notEnoughCoins')}
            <Button
              variant="link"
              onClick={() => {
                showModal(ModalKey.TOP_UP);
              }}
            >
              {tCreateFloorPlan('addMoreCoins')}
            </Button>
          </div>
          )}
          <Button
            disabled={disabledUpload}
            onClick={onSubmit}
          >
            <UploadIcon />
            {tCreateFloorPlan('information.upload')}
          </Button>
        </div>
      </div>
    </div>
  );
}
