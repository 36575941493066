import React, { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import * as api from 'utils/api';
import { setAuth, isAuth, logout as clearAuth } from 'utils/auth';
import * as pusher from 'utils/pusher';
import emitter, { Events } from 'utils/event';
import { getReferToken } from 'utils/refer';

const AppContext = React.createContext();

export function AppContextProvider({
  children,
}) {
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingConfigs, setLoadingConfigs] = useState(true);
  const [user, setUser] = useState(null);
  const [configs, setConfigs] = useState(null);

  const loading = useMemo(() => loadingUser || loadingConfigs, [loadingUser, loadingConfigs]);

  const logout = useCallback(() => {
    clearAuth();
    setUser(null);
  }, []);

  const request = useCallback(async (promise) => {
    try {
      const result = await promise;
      return {
        success: true,
        result,
      };
    } catch (error) {
      console.error(error);
      if (error.code === 401) {
        logout();
      }
      return {
        success: false,
        error,
      };
    }
  }, [logout]);

  const getInfo = useCallback(async () => {
    const { success, result: user } = await request(api.getInfo());
    if (success) {
      setUser(user);
    }
  }, [request]);

  const loginWithGoogle = useCallback(async (idToken) => {
    const { success, error, result } = await request(api.loginWithGoogle(idToken, getReferToken()));
    if (success) {
      setAuth({
        accessToken: result.accessToken,
      });

      await getInfo();
    }

    return { success, error };
  }, [getInfo, request]);

  const login = useCallback(async (email, password) => {
    const { success, error, result } = await request(api.login(email, password));

    if (success) {
      setAuth({
        accessToken: result.accessToken,
      });

      await getInfo();
    }

    return { success, error };
  }, [getInfo, request]);

  const signup = useCallback(async (email, password) => {
    const { success, error, result: { accessToken } } = await request(api.signup(email, password, getReferToken()));

    if (success) {
      setAuth({
        accessToken,
      });

      await getInfo();
    }

    return { success, error };
  }, [getInfo, request]);

  const updateInfo = useCallback(async (data) => {
    const res = await request(api.updateInfo(data));
    await getInfo();
    return res;
  }, [getInfo, request]);

  useEffect(() => {
    if (isAuth()) {
      (async () => {
        await getInfo();
        setLoadingUser(false);
      })();
    } else {
      setLoadingUser(false);
    }
  }, [getInfo, request]);

  useEffect(() => {
    (async () => {
      const { result } = await request(api.getConfigs());

      if (result) {
        const configs = {};
        result.forEach(config => {
          try {
            configs[config.key] = JSON.parse(config.value);
          } catch (e) {
            configs[config.key] = config.value;
          }
        });
        setConfigs(configs);
        setLoadingConfigs(false);
      }
    })();
  }, [request]);

  useEffect(() => {
    if (user) {
      pusher.connect();
      pusher.subscribe(`presence-user-${user.id}`);

      pusher.bind(`presence-user-${user.id}`, 'progress_added', (data) => {
        emitter.emit(Events.PROGRESS_ADDED, data);
      });

      pusher.bind(`presence-user-${user.id}`, 'progress_updated', (data) => {
        emitter.emit(Events.PROGRESS_UPDATED, data);
      });

      pusher.bind(`presence-user-${user.id}`, 'image_status_changed', (data) => {
        emitter.emit(Events.IMAGE_STATUS_CHANGED, data);
      });

      pusher.bind(`presence-user-${user.id}`, 'image_clean_process_updated', (data) => {
        emitter.emit(Events.IMAGE_CLEAN_PROCESS_UPDATED, data);
      });

      pusher.bind(`presence-user-${user.id}`, 'new_notification', (data) => {
        console.log('new_notification', data);
        emitter.emit(Events.NEW_NOTIFICATION, data);
      });

      return () => {
        pusher.unbind(`presence-user-${user.id}`, 'progress_added');
        pusher.unbind(`presence-user-${user.id}`, 'progress_updated');
        pusher.unbind(`presence-user-${user.id}`, 'image_status_changed');
        pusher.unbind(`presence-user-${user.id}`, 'image_clean_process_updated');
        pusher.unbind(`presence-user-${user.id}`, 'new_notification');
        pusher.unsubscribe(`presence-user-${user.id}`);
        pusher.disconnect();
      };
    }

    return () => {};
  }, [user]);

  useEffect(() => {
    const listener = emitter.addListener(Events.FORCE_LOGOUT, logout);

    return () => {
      listener.remove();
    };
  }, [logout]);

  const contextValue = useMemo(() => ({
    user,
    setUser,
    loginWithGoogle,
    loading,
    login,
    signup,
    logout,
    updateInfo,
    configs,
    getInfo,
    request,
  }), [
    user,
    setUser,
    loginWithGoogle,
    loading,
    login,
    signup,
    logout,
    updateInfo,
    configs,
    getInfo,
    request,
  ]);

  return (
    <AppContext.Provider
      value={contextValue}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
