import React from 'react';
import useTranslation from 'hooks/useTranslation';
import { Button } from 'react-bootstrap';
import { FloorPlanFiles, FloorPlanFilesProvider, ContinueButtonWrapper } from 'components/Common/FloorPlanFiles';
import styles from './FilesSelection.module.scss';

export default function FilesSelection({
  onContinue,
  files,
  setFiles,
  urls,
  setUrls,
}) {
  const { tCreateFloorPlan } = useTranslation();

  return (
    <FloorPlanFilesProvider
      files={files}
      setFiles={setFiles}
      urls={urls}
      setUrls={setUrls}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          {tCreateFloorPlan('filesSelection.title')}
        </div>
        <div className={styles.inputWrapper}>
          <FloorPlanFiles />
        </div>
        <ContinueButtonWrapper>
          {({ disabled }) => (
            <Button
              disabled={disabled}
              className={styles.continueButton}
              onClick={onContinue}
            >
              {tCreateFloorPlan('filesSelection.continue')}
            </Button>
          )}
        </ContinueButtonWrapper>
      </div>
    </FloorPlanFilesProvider>
  );
}
