import React from 'react';
import { Button } from 'react-bootstrap';
import logo from 'assets/generated/images/logo.png';
import { useHistory } from 'react-router';
import { useAppContext } from 'components/AppContext';
import useTranslation from '../../hooks/useTranslation';

export default function LandingHeader() {
  const { tCommon } = useTranslation();
  const history = useHistory();
  const { user } = useAppContext();

  const login = () => {
    history.push('/get-started');
  };

  return (
    <div className="landing-header">
      <img
        className="logo"
        alt="Logo"
        src={logo}
        onClick={() => {
          history.push('/');
          window.scroll(0, 0);
        }}
        height={56}
      />
      <div style={{
        color: 'white',
      }}
      />
      {!user && (
        <Button
          variant="light"
          onClick={login}
        >
          {tCommon('login')}
        </Button>
      )}
    </div>
  );
}
