import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { FloorPlanPackage } from 'auto-design-common';
import floorPlanImages from 'assets/images/services/floor-plan-images.png';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { getPriceKey } from 'components/CreateFloorPlan/utils';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import * as api from 'utils/api';
import styles from './ExtraServices.module.scss';
import useFloorPlan from '../useFloorPlan';

export default function FloorPlan3DPackage() {
  const { configs, request, getInfo } = useAppContext();
  const { floorPlanId } = useParams();
  const { tFloorplanOverview, tCreateFloorPlan } = useTranslation();
  const [packages, setPackages] = useState([FloorPlanPackage.STANDARD_3D]);
  const { floorPlan, refetchFloorPlan } = useFloorPlan({
    floorPlanId,
  });
  const { showModal } = useModalContext();

  const price = useMemo(() => {
    if (!floorPlan) {
      return 0;
    }

    const base = packages.reduce((acc, p) => acc + configs[getPriceKey(p)], 0);

    return base * floorPlan.metadata.floors;
  }, [packages, configs, floorPlan]);

  const onRequest = () => {
    showModal(ModalKey.CONFIRM_PAY_EXTRA_SERVICE, {
      price,
      onAccept: async () => {
        await request(api.purchasePackages(floorPlanId, packages));
        getInfo();
        await refetchFloorPlan();
      },
    });
  };

  return (
    <div className={styles.service}>
      <div className={styles.content}>
        <img alt="Thumbnail" className={styles.thumbnail} src={floorPlanImages} />
        <div className={styles.title}>
          {tFloorplanOverview('floorPlan3D')}
        </div>
        <div className={styles.description}>
          {tFloorplanOverview('floorPlan3DDescription')}
        </div>
        <div style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}
        >
          <Form.Check
            type="checkbox"
            label={tCreateFloorPlan('information.allowInsert')}
            checked={packages.includes(FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D)}
            onClick={() => {
              if (packages.includes(FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D)) {
                setPackages([FloorPlanPackage.STANDARD_3D]);
              } else {
                setPackages([FloorPlanPackage.STANDARD_3D, FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D]);
              }
            }}
            onChange={() => {}}
          />
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.buttons}>
        <button
          onClick={onRequest}
          className={styles.button}
          type="button"
        >
          {tFloorplanOverview('request')}
          {' ('}
          {price}
          {' x '}
          <CoinIcon />
          {') '}
        </button>
      </div>
    </div>
  );
}
