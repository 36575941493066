import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import { useToastContext } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import Layout from 'components/Common/Layout';
import styles from './ApiKeys.module.scss';
import useTranslation from '../../hooks/useTranslation';

export default function ApiKeys() {
  const { tAPIKeys, tCommon } = useTranslation();

  const [keys, setKeys] = useState([]);
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();

  const fetchKeys = useCallback(async () => {
    const { success, error, result } = await request(api.getApiKeys());

    if (!success) {
      toastError(error);
    } else {
      setKeys(result);
    }
  }, [request, toastError]);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  return (
    <Layout className="api-keys">
      <>
        <div className={styles.title}>
          {tAPIKeys('title')}
        </div>
        <div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperInner}>
              <div className={styles.buttonWrapper}>
                <Button onClick={() => {
                  showModal(ModalKey.ADD_API_KEY, {
                    onDone: () => {
                      fetchKeys();
                    },
                  });
                }}
                >
                  {tCommon('add')}
                </Button>
              </div>
              <Table striped bordered hover className={styles.table}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{tCommon('description')}</th>
                    <th>{tCommon('key')}</th>
                    <th>{tCommon('createdAt')}</th>
                    <th>
                      {' '}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {keys.map((key, index) => (
                    <tr key={key.id}>
                      <td>{index + 1}</td>
                      <td>{key.description}</td>
                      <td>{key.key}</td>
                      <td>
                        {moment.utc(key.createdAt).local().format('MM-DD-yyyy HH:mm')}
                      </td>
                      <td style={{
                        width: 80,
                      }}
                      >
                        <Button
                          variant="danger"
                          onClick={async () => {
                            // eslint-disable-next-line no-restricted-globals
                            if (confirm(tAPIKeys('deleteConfirmation'))) {
                              const { success } = await request(api.deleteApiKey(key.id));

                              if (success) {
                                fetchKeys();
                              }
                            }
                          }}
                        >
                          {tCommon('delete')}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}
