/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { Button } from 'react-bootstrap';
import fx from 'glfx';
import ReactSlider from 'react-slider';
import Div100vh from 'react-div-100vh';
import moment from 'moment';
import ReactLoading from 'components/Common/ReactLoading';
import { useResizeDetector } from 'react-resize-detector';
import { Icon, dataURItoBlob } from 'auto-design-common';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import classNames from 'classnames';
import { useHideTawkWidget } from 'components/TawkContext';
import { getFileUrl } from 'utils/file';
import Watermarks from './Watermarks';
import WatermarkConfig from './WatermarkConfig';
import styles from './ResultEditor.module.scss';
import useTranslation from '../../hooks/useTranslation';

const INITIAL_FILTER_VALUES = {
  saturation: 0,
  contrast: 0,
  brightness: 0,
  hue: 0,
  shadow: 0,
  light: 0,
};

function FilterGroup({
  children,
  title,
  className,
}) {
  return (
    <div className={classNames('filter-group', className)}>
      <div className="filter-title">
        {title}
      </div>
      <div className="input">
        {children}
      </div>
    </div>
  );
}

function Filter({
  label,
  onChange,
  value,
  min,
  max,
}) {
  return (
    <div className="filter">
      {label && (
        <div className="label">{label}</div>
      )}
      <ReactSlider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
      <div className="value">
        {value}
      </div>
    </div>
  );
}

// TODO: Hung - Refactor this constant
const SmallAdjustOption = {
  SATURATION: 'SATURATION',
  CONTRAST: 'CONTRAST',
  BRIGHTNESS: 'BRIGHTNESS',
  HUE: 'HUE',
  ERASE: 'ERASE',
  SHADOW: 'SHADOW',
  LIGHT: 'LIGHT',
};

export default function FloorPlanResultEditor({
  image,
  progress,
  setEditing,
  setShowHistory,
  setDirty,
  save,
}) {
  const { tImagesResult, tCommon } = useTranslation();

  const [loading, setLoading] = useState(true);
  const canvasRef = useRef();
  const [filterValues, setFilterValues] = useState(INITIAL_FILTER_VALUES);
  const [debouncedFilterValues] = useDebounce(filterValues, 100, {
    leading: true,
    trailing: true,
  });
  const debouncedFilterValuesRef = useRef();
  debouncedFilterValuesRef.current = debouncedFilterValues;

  const { ref: photoAreaRef, height: photoAreaHeight, width: photoAreaWidth } = useResizeDetector();
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [submitting, setSubmitting] = useState(false);
  const [smallAdjustOption, setSmallAdjustOption] = useState(SmallAdjustOption.SATURATION);
  const [watermark, setWatermark] = useState({
    use: false,
    images: [],
  });

  const widthScale = size.width / photoAreaWidth;
  const heightScale = size.height / photoAreaHeight;
  const scale = 1 / (widthScale > heightScale ? widthScale : heightScale);
  const watermarksRef = useRef();
  const imageRef = useRef();

  const resetCanvas = (canvas, img) => {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  const drawImage = (canvas, img, blend = 'source-over') => {
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = blend;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  const doDrawMainCanvas = useCallback(() => {
    const { width, height } = canvasRef.current;
    const filterValues = debouncedFilterValuesRef.current;

    // Apply filter to item canvas
    const filteredCanvas = fx.canvas();
    filteredCanvas.width = width;
    filteredCanvas.height = height;
    const texture = filteredCanvas.texture(imageRef.current);
    filteredCanvas.draw(texture);
    filteredCanvas.brightnessContrast(filterValues.brightness / 100, filterValues.contrast / 100);
    filteredCanvas.hueSaturation(filterValues.hue / 100, filterValues.saturation / 100);
    filteredCanvas.update();

    // Draw canvas
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    drawImage(canvas, filteredCanvas);

    return canvas;
  }, []);

  const drawMainCanvas = useCallback(() => {
    const canvas = doDrawMainCanvas();
    const { width, height } = canvasRef.current;
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(canvas, 0, 0);
  }, [doDrawMainCanvas]);

  useEffect(() => {
    setWatermark(progress.metadata.watermark || {
      use: false,
      images: [],
    });
    setFilterValues(progress.metadata.filterValues || INITIAL_FILTER_VALUES);
  }, [progress]);

  useEffect(() => {
    setLoading(true);

    const destroyed = false;

    const loadImage = (id) => {
      if (!id) {
        return Promise.resolve(null);
      }

      const image = new Image();
      image.crossOrigin = true;
      image.src = getFileUrl(id);

      return new Promise(resolve => {
        image.onload = () => {
          resolve(image);
        };
      });
    };

    const setCanvasSize = (canvas, width, height) => {
      canvas.height = height;
      canvas.width = width;
    };

    loadImage(progress.metadata.outputFileId)
      .then(outputImage => {
        imageRef.current = outputImage;
        const width = outputImage.width;
        const height = outputImage.height;

        if (destroyed) {
          return;
        }

        setSize({
          height,
          width,
        });

        setCanvasSize(canvasRef.current, width, height);

        resetCanvas(canvasRef.current, outputImage);
        drawMainCanvas();

        setLoading(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [image, progress, setDirty, drawMainCanvas]);

  useHideTawkWidget();

  useEffect(() => {
    // Redraw whenever filters change or toggle hide background
    if (!loading) {
      drawMainCanvas();
    }
  }, [drawMainCanvas, debouncedFilterValues, loading]);

  const renderSaturation = () => (
    <FilterGroup
      title={tImagesResult('saturation').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.saturation}
        onChange={(value) => {
          setDirty(true);
          setFilterValues({
            ...filterValues,
            saturation: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderContrast = () => (
    <FilterGroup
      title={tImagesResult('contrast').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.contrast}
        onChange={(value) => {
          setDirty(true);
          setFilterValues({
            ...filterValues,
            contrast: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderBrightness = () => (
    <FilterGroup
      title={tImagesResult('brightess').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.brightness}
        onChange={(value) => {
          setDirty(true);
          setFilterValues({
            ...filterValues,
            brightness: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderHue = () => (
    <FilterGroup
      title={tImagesResult('hue').toUpperCase()}
    >
      <Filter
        min={-100}
        max={100}
        value={filterValues.hue}
        onChange={(value) => {
          setDirty(true);
          setFilterValues({
            ...filterValues,
            hue: value,
          });
        }}
      />
    </FilterGroup>
  );

  const renderSaveButton = () => (
    <Button
      className="save-button"
      disabled={submitting}
      onClick={async () => {
        setSubmitting(true);

        const outputCanvas = doDrawMainCanvas(false);

        canvasRef.current.toBlob(maskBlob => {
          outputCanvas.toBlob(async (outputBlob) => {
            let data = {
              outputBlob,
              maskBlob,
              watermark,
              filterValues,
              hideBackground: false,
            };

            if (watermark.use) {
              const watermarkDataURL = await watermarksRef.current.getDataURL();
              const watermarkBlob = dataURItoBlob(watermarkDataURL);
              data = {
                ...data,
                watermarkBlob,
              };
            }

            await save(data);
            setSubmitting(false);
          });
        });
      }}
    >
      {tCommon('save')}
    </Button>
  );

  return (
    <Div100vh className="result-editor">
      <div className="left">
        <div className="header">
          <div className="buttons">
            <div
              className="back"
              onClick={() => {
                setEditing(false);
              }}
            >
              <Icon
                src={xIcon}
                fill="#212529"
              />
            </div>
          </div>
          <div className="info">
            <div className="title">
              {tImagesResult('editingPhoto')}
            </div>
            <div className="date">
              {tImagesResult('version')}
              :
              {' '}
              {moment.utc(progress.createdAt).local().format('MM-DD-yyyy HH:mm')}
              <span
                className="change-button"
                onClick={() => setShowHistory(true)}
              >
                {tCommon('change')}
              </span>
            </div>
          </div>
        </div>
        <div
          className="photo-area"
          ref={photoAreaRef}
        >
          <div
            className="canvas"
            style={{
              transform: `scale(${scale})`,
              position: 'relative',
              width: size.width,
              height: size.height,
            }}

          >
            {loading && (
              <ReactLoading />
            )}
            {watermark.use && (
              <Watermarks
                ref={watermarksRef}
                size={size}
                images={watermark.images}
                erasing={false}
                onChange={(images) => {
                  setWatermark({
                    ...watermark,
                    images,
                  });
                }}
              />
            )}
            <canvas
              ref={canvasRef}
              style={{
                zIndex: 2,
              }}
              className={styles.mainCanvas}
            />
          </div>
        </div>
      </div>
      <div className="adjust">
        <div className="title">
          {tImagesResult('adjust')}
        </div>
        <div className="content">
          {renderSaturation()}
          {renderContrast()}
          {renderBrightness()}
          {renderHue()}
          <WatermarkConfig
            loading={loading}
            data={watermark}
            onChange={setWatermark}
          />
        </div>
        <div className="adjust-footer">
          {renderSaveButton()}
        </div>
      </div>

      <div className="small-adjust">
        <div className="filter-area">
          {smallAdjustOption === SmallAdjustOption.SATURATION && renderSaturation()}
          {smallAdjustOption === SmallAdjustOption.CONTRAST && renderContrast()}
          {smallAdjustOption === SmallAdjustOption.BRIGHTNESS && renderBrightness()}
          {smallAdjustOption === SmallAdjustOption.HUE && renderHue()}
        </div>
        <div className="tools">
          <div className="filter-selections">
            {[
              SmallAdjustOption.SATURATION,
              SmallAdjustOption.CONTRAST,
              SmallAdjustOption.BRIGHTNESS,
              SmallAdjustOption.HUE,
              progress.metadata.shadowOutputFileId && SmallAdjustOption.SHADOW,
              progress.metadata.lightOutputFileId && SmallAdjustOption.LIGHT,
            ].filter(val => !!val).map(option => (
              <div
                key={option}
                className={classNames('filter-selection', smallAdjustOption === option && 'active')}
                onClick={() => {
                  setSmallAdjustOption(option);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
        {renderSaveButton()}
      </div>
    </Div100vh>
  );
}
