import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { FloorPlanPackage } from 'auto-design-common';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { getPriceKey } from 'components/CreateFloorPlan/utils';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import * as api from 'utils/api';
import use2DPackages from 'hooks/use2DPackages';
import styles from './ExtraServices.module.scss';
import useFloorPlan from '../useFloorPlan';

export default function FloorPlan2DPackage() {
  const { configs, request, getInfo } = useAppContext();
  const { floorPlanId } = useParams();
  const { tFloorplanOverview, tCreateFloorPlan } = useTranslation();
  const [packages, setPackages] = useState([FloorPlanPackage.STANDARD_2D]);
  const { floorPlan, refetchFloorPlan } = useFloorPlan({
    floorPlanId,
  });
  const { showModal } = useModalContext();
  const { PACKAGES_2D } = use2DPackages();

  const displayed2DPackage = useMemo(() => PACKAGES_2D.find(p => p.id === packages[0]), [PACKAGES_2D, packages]);

  const price = useMemo(() => {
    if (!floorPlan) {
      return 0;
    }

    const base = packages.reduce((acc, p) => acc + configs[getPriceKey(p)], 0);

    return base * floorPlan.metadata.floors;
  }, [packages, configs, floorPlan]);

  const onRequest = () => {
    showModal(ModalKey.CONFIRM_PAY_EXTRA_SERVICE, {
      price,
      onAccept: async () => {
        await request(api.purchasePackages(floorPlanId, packages));
        getInfo();
        await refetchFloorPlan();
      },
    });
  };

  const toggle2dPackage = (id) => {
    setPackages([id, ...packages.slice(1)]);
  };

  return (
    <div className={styles.service}>
      <div className={styles.content}>
        <img alt="Thumbnail" className={styles.thumbnail} src={displayed2DPackage.img} />
        <div className={styles.title}>
          {displayed2DPackage.name}
        </div>
        <div className={styles.description}>
          {tFloorplanOverview('floorPlan2DDescription')}
        </div>
        <Button
          variant="link"
          onClick={() => {
            showModal(ModalKey.FLOOR_PLAN_2D_PACKAGES, {
              packages: PACKAGES_2D,
              defaultSelectedPackage: displayed2DPackage,
              onSelect: (p) => {
                toggle2dPackage(p.id);
              },
            });
          }}
        >
          {tCreateFloorPlan('packages.changePreference')}
        </Button>
        <div style={{
          paddingLeft: 16,
          paddingRight: 16,
          marginTop: 8,
          marginBottom: 8,
        }}
        >
          <Form.Check
            type="checkbox"
            label={tCreateFloorPlan('information.allowInsert')}
            checked={packages.includes(FloorPlanPackage.INSERT_FURNITURE_2D)}
            onClick={() => {
              if (packages.includes(FloorPlanPackage.INSERT_FURNITURE_2D)) {
                setPackages([packages[0]]);
              } else {
                setPackages([packages[0], FloorPlanPackage.INSERT_FURNITURE_2D]);
              }
            }}
            onChange={() => {}}
          />
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.buttons}>
        <button
          onClick={onRequest}
          className={styles.button}
          type="button"
        >
          {tFloorplanOverview('request')}
          {' ('}
          {price}
          {' x '}
          <CoinIcon />
          {') '}
        </button>
      </div>
    </div>
  );
}
