import React, { useState } from 'react';
import standard3dImg from 'assets/images/create-floor-plan/standard_3d.png';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { FloorPlanPackage } from 'auto-design-common';
import useTranslation from 'hooks/useTranslation';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import use2DPackages from 'hooks/use2DPackages';
import styles from './Packages.module.scss';

function PackageItem({ selected, onClick, name, img, price }) {
  return (
    <div
      className={classNames(styles.package, selected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.radio} />
          <div className={styles.name}>
            {name}
          </div>

        </div>
        {' '}
        <div className={styles.price}>
          <div className={styles.number}>
            {price}
            {' '}
            x
            {' '}
          </div>
          <CoinIcon />
        </div>
      </div>
      <div className={styles.thumbnail}>
        <img src={img} alt={name} />
      </div>
    </div>
  );
}

export default function Packages({
  packages,
  setPackages,
  onContinue,
}) {
  const { tCreateFloorPlan } = useTranslation();
  const { configs } = useAppContext();
  const { showModal } = useModalContext();
  const { PACKAGES_2D, PACKAGE_2D_IDS } = use2DPackages();

  const [displayed2DPackage, setDisplayed2DPackage] = useState(PACKAGES_2D[0]);

  const toggle2dPackage = (id) => {
    if (packages.includes(id)) {
      setPackages(packages.filter(p => p !== id && p !== FloorPlanPackage.INSERT_FURNITURE_2D && p !== FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D));
    } else {
      setPackages([...packages.filter(p => !PACKAGE_2D_IDS.includes(p)), id]);
    }
  };

  const toggle3dPackage = (id) => {
    if (packages.includes(id)) {
      setPackages(packages.filter(p => p !== id && p !== FloorPlanPackage.INSERT_FURNITURE_2D && p !== FloorPlanPackage.INSERT_CAMERAS_AND_FURNITURE_3D));
    } else {
      setPackages([...packages, id]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {tCreateFloorPlan('packages.choosePackages')}
      </div>

      <div className={styles.content}>
        <div className={styles.left}>
          <PackageItem
            key={displayed2DPackage.id}
            name={displayed2DPackage.name}
            img={displayed2DPackage.img}
            selected={packages.includes(displayed2DPackage.id)}
            onClick={() => {
              toggle2dPackage(displayed2DPackage.id);
            }}
            price={displayed2DPackage.price}
          />
          <div className={styles.or}>{tCreateFloorPlan('packages.or')}</div>
          <Button
            variant="link"
            onClick={() => {
              showModal(ModalKey.FLOOR_PLAN_2D_PACKAGES, {
                packages: PACKAGES_2D,
                defaultSelectedPackage: displayed2DPackage,
                onSelect: (p) => {
                  toggle2dPackage(p.id);
                  setDisplayed2DPackage(p);
                },
              });
            }}
          >
            {tCreateFloorPlan('packages.changePreference')}
          </Button>
        </div>
        <div className={styles.separator} />
        <div className={styles.right}>
          <PackageItem
            key="standard_3d"
            name="3D Floor Plan"
            img={standard3dImg}
            selected={packages.includes('standard_3d')}
            onClick={() => {
              toggle3dPackage('standard_3d');
            }}
            price={configs.floor_plan_standard_3d_price}
          />
        </div>
      </div>

      <Button
        onClick={onContinue}
        disabled={packages.length === 0}
      >
        {tCreateFloorPlan('packages.continue')}
      </Button>
    </div>
  );
}
