/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import classNames from 'classnames';
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import coinIcon from 'assets/images/Auto-Coin.svg';
import plusIcon from 'assets/images/ic-line-plus.svg';
import rightArrowIcon from 'assets/images/ic-line-arrow-right.svg';
import { Icon } from 'auto-design-common';
import { INSTRUCTION_2D_VIDEO, INSTRUCTION_360_VIDEO, SidebarMenuState } from 'constants/common';
import { useAppContext } from 'components/AppContext';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { ReactComponent as BubbleIcon } from 'assets/images/ic-line-bubble-single.svg';
import configs from 'configuration';
import bookIcon from 'assets/images/tab-icons/book.svg';
import buildingIcon from 'assets/images/tab-icons/building-3.svg';
import locationTickIcon from 'assets/images/tab-icons/location-tick.svg';
import oceanProtocalIcon from 'assets/images/tab-icons/ocean-protocol.svg';
import videoSquareIcon from 'assets/images/tab-icons/video-square.svg';
import infoIcon from 'assets/images/ic-line-info.svg';
import floorPlanIcon from 'assets/images/ic-floor-plan.svg';
import useTranslation from '../../../hooks/useTranslation';
import useSearchLocations from '../useSearchLocations';
import Locations from './Locations';
import styles from './Sidebar.module.scss';

function NavItem({
  item,
  isActive,
  onOpenDetails,
}) {
  return (
    <div
      className={classNames(
        styles.navItem,
        isActive && styles.navItemActive,
      )}
    >
      <div className={classNames(
        styles.navItemIndicator,
        isActive && styles.navItemIndicatorActive,
      )}
      />
      <div className={styles.navItemInner}>
        <div className={styles.navItemLeft}>
          {isActive && (
          <div className="active-dot" />
          )}
          <img
            src={item.icon}
            alt={item.title}
            width={20}
            height={20}
          />
          <span
            className={styles.navItemTitle}
            onClick={item.onClick}
          >
            {item.title}
            {item.badge ? item.badge : null}
          </span>
        </div>
        {item.hasDetails && (
          <img
            alt="open-details"
            className="open-details"
            src={rightArrowIcon}
            onClick={onOpenDetails}
          />
        )}
      </div>

    </div>
  );
}

export default function Sidebar({
  menuState,
  setMenuState,
}) {
  const { tSidebar, tBetates, tAddDesign } = useTranslation();
  const location = useLocation();
  const { locations } = useSearchLocations({
    searchText: '',
  });
  const history = useHistory();
  const { user } = useAppContext();
  const [details, setDetails] = useState(null);
  const { showModal } = useModalContext();

  const path = location.pathname;

  const isActiveItem = (item) => path.startsWith(item.path);

  const SERVICE_NAV_ITEM = useMemo(() => ({
    key: 'services',
    path: '/services',
    title: tSidebar('navItems.services'),
    onClick: () => {
      history.push('/services');
    },
    icon: buildingIcon,
  }), [history, tSidebar]);

  const NAV_ITEMS = useMemo(() => [
    !configs.showOnlyVirtualStaging && {
      key: 'floor-plans',
      path: '/floor-plans',
      title: tSidebar('navItems.floorPlans'),
      onClick: () => {
        history.push('/floor-plans');
      },
      icon: floorPlanIcon,
    },
    {
      key: 'locations',
      path: '/locations',
      title: tSidebar('navItems.virtualStaging'),
      hasDetails: locations.length > 0,
      onClick: () => {
        history.push('/locations/empty');
        setDetails('locations');
        setMenuState(SidebarMenuState.DETAILS);
      },
      icon: locationTickIcon,
    },
    !configs.disablePanoImage && {
      key: 'tours',
      path: '/tours',
      title: tSidebar('navItems.tours'),
      badge: (
        <Badge className={classNames(styles.badge, styles.badgeNew)}>
          {tSidebar('new')}
        </Badge>
      ),
      onClick: () => {
        history.push('/tours');
      },
      icon: oceanProtocalIcon,
    },
    {
      key: 'catalog',
      path: '/catalog',
      title: tSidebar('navItems.catalog'),
      onClick: () => {
        history.push('/catalog');
      },
      icon: bookIcon,
    },
    {
      key: '2d-instructions',
      title: tSidebar('navItems.2d-instructions'),
      onClick: () => {
        window.open(INSTRUCTION_2D_VIDEO);
      },
      icon: videoSquareIcon,
    },
    !configs.disablePanoImage && {
      key: '360-instructions',
      title: tSidebar('navItems.360-instructions'),
      onClick: () => {
        window.open(INSTRUCTION_360_VIDEO);
      },
      icon: videoSquareIcon,
    },
  ].filter(i => !!i), [history, locations.length, tSidebar, setMenuState]);

  useEffect(() => {
    if (menuState === SidebarMenuState.COLLAPSED) {
      setDetails(null);
    }
  }, [menuState]);

  useEffect(() => {
    if (location.pathname === '/locations/empty' && locations.length > 0) {
      setDetails('locations');
      setMenuState(SidebarMenuState.DETAILS);
    }
  }, [location.pathname, locations.length, setMenuState]);

  return (
    <div
      className={classNames(styles.sidebar, menuState === SidebarMenuState.COLLAPSED && styles.sidebarCollapsed)}
    >
      <div className={styles.content}>
        <div className={styles.navItems}>
          <NavItem
            item={SERVICE_NAV_ITEM}
            isActive={isActiveItem(SERVICE_NAV_ITEM)}
          />
          <div className={styles.separator} />
          {NAV_ITEMS.map(item => (
            <NavItem
              key={item.key}
              item={item}
              isActive={isActiveItem(item)}
              onOpenDetails={() => {
                setDetails(item.key);
                setMenuState(SidebarMenuState.DETAILS);
              }}
            />
          ))}
        </div>

      </div>
      <div className={styles.bottomInfo}>
        <div className={styles.balance}>
          <div className={styles.title}>
            <div>{tSidebar('info.yourCoins')}</div>
            <img
              alt="balance"
              src={coinIcon}
            />
            <OverlayTrigger
              placement="bottom-start"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="coin-info-tooltip" {...props}>
                  <div className="tooltip-content">
                    <div className="tooltip-title">
                      {tAddDesign('whatAreCoins')}
                    </div>
                    <ul>
                      <li>
                        {tAddDesign('spendCoin')}
                      </li>
                      <li>
                        {tAddDesign('everyImageCosts')}
                        {' '}
                        {configs.image_price}
                        {' '}
                        {tBetates('coin', {
                          count: configs.image_price,
                        })}
                      </li>
                      <li>
                        {tAddDesign('earlyBeta')}
                      </li>
                    </ul>
                  </div>
                </Tooltip>
              )}
            >
              <img
                alt="info"
                src={infoIcon}
                className="info"
              />
            </OverlayTrigger>
          </div>
          <div className={styles.number}>
            {user.balance}
          </div>
        </div>
        <Button
          variant="secondary"
          className={styles.bottomInfoButton}
          onClick={() => {
            showModal(ModalKey.TOP_UP);
          }}
        >
          <Icon
            fill="#212529"
            src={plusIcon}
          />
          {tSidebar('button.addMoreCoins')}
        </Button>
        <Button
          variant="secondary"
          className={styles.bottomInfoButton}
          onClick={() => {
            showModal(ModalKey.INVITE_FRIENDS);
          }}
          style={{
            width: '100%',
            marginTop: 16,
          }}
        >
          <BubbleIcon />
          {tSidebar('button.inviteFriends')}
        </Button>
      </div>
      {details === 'locations' && (
        <Locations
          onClose={() => {
            setDetails(null);
            setMenuState(SidebarMenuState.MENU);
          }}
        />
      )}

    </div>
  );
}
