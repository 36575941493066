import { useQuery } from '@tanstack/react-query';
import React from 'react';
import * as api from 'utils/api';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import { Button } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { DEFAULT_MAX_FLOOR_PLAN_CAMERAS } from 'constants/common';
import useTranslation from 'hooks/useTranslation';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import styles from './AddCameraButton.module.scss';

export default function AddCameraButton({
  imageId,
  numCameras,
  onAddCamera,
}) {
  const { configs, getInfo, user, request } = useAppContext();
  const { tCameraList } = useTranslation();
  const { showModal } = useModalContext();

  const { data: image, refetch } = useQuery({
    queryKey: ['image', imageId],
    queryFn: () => api.getImage(imageId),
  });

  if (!image) {
    return null;
  }

  if (numCameras < (image.configs?.maxCameras || DEFAULT_MAX_FLOOR_PLAN_CAMERAS)) {
    return (
      <Button
        className={styles.createButton}
        onClick={() => {
          onAddCamera();
        }}
      >
        <span
          style={{
            fontSize: 32,
          }}
        >
          +
        </span>
        {tCameraList('addCamera')}
      </Button>
    );
  }

  return (
    <Button onClick={() => {
      const showConfirm = () => {
        showModal(ModalKey.CONFIRM_PURCHASE_EXTRA_CAMERA, {
          price: configs.floor_plan_extra_camera_price,
          onAccept: async () => {
            await request(api.purchaseExtraCamera(imageId));
            getInfo();
            await refetch();
          },
        });
      };

      if (user.balance >= configs.floor_plan_extra_camera_price) {
        showConfirm();
      } else {
        showModal(ModalKey.TOP_UP, {
          onDone: showConfirm,
        });
      }
    }}
    >
      Pay
      {' '}
      {configs.floor_plan_extra_camera_price}
      {' '}
      x
      <CoinIcon />
      for 1 extra camera
    </Button>
  );
}
