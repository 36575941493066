import React from 'react';
import Layout from 'components/Common/Layout';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from 'utils/api';
import { FloorPlanStatus } from 'auto-design-common';
import useTranslation from 'hooks/useTranslation';
import FloorPlanStatusBadge from 'components/Common/FloorPlanStatusBadge';
import { Button } from 'react-bootstrap';
import styles from './FloorPlans.module.scss';

export default function FloorPlans() {
  const { data: floorPlans } = useQuery({
    queryKey: 'floorPlans',
    queryFn: () => api.getFloorPlans(),
  });
  const { tFloorPlanPackageShort, tFloorPlans } = useTranslation();
  const history = useHistory();

  return (
    <Layout>
      <div className={styles.title}>
        {tFloorPlans('title')}
        <Button onClick={() => {
          history.push('/create-floor-plan');
        }}
        >
          {tFloorPlans('addFloorPlan')}
        </Button>
      </div>

      <div className={styles.listWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                {tFloorPlans('status')}
              </th>
              <th>
                {tFloorPlans('name')}
              </th>
              <th>
                {tFloorPlans('floors')}
              </th>
              <th>
                {tFloorPlans('packages')}
              </th>
              <th>
                {tFloorPlans('createdAt')}
              </th>
            </tr>
          </thead>
          <tbody>
            {floorPlans?.map(f => (
              <tr
                key={f.id}
                onClick={() => {
                  if ([FloorPlanStatus.READY, FloorPlanStatus.EXTRA_COST_APPROVAL, FloorPlanStatus.CREATED, FloorPlanStatus.REQUEST_FILES].includes(f.status)) {
                    history.push(`/floor-plans/${f.id}`);
                  }
                }}
              >
                <td>
                  <FloorPlanStatusBadge status={f.status} />
                </td>
                <td>{f.name}</td>
                <td>{f.metadata.floors}</td>
                <td>
                  {f.packages.map(p => (
                    <div className={styles.package} key={p}>
                      {tFloorPlanPackageShort(p)}
                    </div>
                  ))}
                </td>
                <td>{moment.utc(f.createdAt).local().format('MM-DD-yyyy HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
