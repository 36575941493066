import React, { useState, useMemo } from 'react';
import { Modal, Form } from 'react-bootstrap';
import coinIcon from 'assets/images/Auto-Coin.svg';
import { useAppContext } from 'components/AppContext';
import NextButton from 'components/Common/NextButton';
import { ModalKey } from 'constants/modal';
import _ from 'lodash';
import { formatCurrency } from 'utils/currency';
import useTranslation from '../../hooks/useTranslation';

export default function TopUp({
  onClose,
  showModal,
  onDone,
}) {
  const { useTranslationOrigin, tBetates } = useTranslation();
  const { t: tTopUp } = useTranslationOrigin('translation', { keyPrefix: 'modal.topUp' });

  const { configs } = useAppContext();
  const [quantity, setQuantity] = useState(10);

  const appliedPrice = useMemo(() => {
    if (quantity >= 1 && quantity <= 9) {
      return configs.coin_rate_1_9;
    }

    if (quantity >= 10 && quantity <= 20) {
      return configs.coin_rate_10_20;
    }

    return configs.coin_rate_21_up;
  }, [configs.coin_rate_1_9, configs.coin_rate_10_20, configs.coin_rate_21_up, quantity]);

  const total = useMemo(() => appliedPrice * quantity, [appliedPrice, quantity]);

  const noDiscountTotal = configs.coin_rate_1_9 * quantity;
  const hasDiscount = total < noDiscountTotal;

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-top-up"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tTopUp('purchase')}
          {' '}
          {tBetates('coin_other')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={coinIcon}
          alt="coin"
          className="coin-icon"
        />

        <div className="right">
          <div className="title">
            {tBetates('name')}
            {' '}
            {_.capitalize(tBetates('coin_other'))}
          </div>
          <div className="control">
            <div className="rate">
              {hasDiscount && (
                <div className="no-discount-price">
                  {formatCurrency(configs.coin_rate_1_9 / 100, configs.currency_format)}
                </div>
              )}
              {formatCurrency(appliedPrice / 100, configs.currency_format)}
            </div>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <div className="x">x</div>
            <Form.Group>
              <Form.Control
                type="number"
                value={quantity}
                onChange={e => setQuantity(parseInt(e.target.value))}
              />
            </Form.Group>
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="total-container">
          <div className="total-title">{tTopUp('total')}</div>
          <div className="total-number">
            {formatCurrency((Number.isNaN(total) ? 0 : total) / 100, configs.currency_format)}

            {hasDiscount && (
              <div className="no-discount-total">
                {formatCurrency((Number.isNaN(noDiscountTotal) ? 0 : noDiscountTotal) / 100, configs.currency_format)}
              </div>
            )}
          </div>
        </div>
        <NextButton
          text={tTopUp('button')}
          onClick={() => {
            showModal(ModalKey.CHECKOUT, {
              quantity,
              onDone,
            });
          }}
          disabled={quantity <= 0 || Number.isNaN(quantity)}
        />
      </Modal.Footer>
    </Modal>
  );
}
