import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import darkLogo from 'assets/generated/images/logo-dark.png';
import menuIcon from 'assets/images/ic-line-menu.svg';
import closeIcon from 'assets/images/ic-line-x-mark.svg';
import { SidebarMenuState } from 'constants/common';
import useTranslation from 'hooks/useTranslation';
import { useAppContext } from 'components/AppContext';
import { useHistory } from 'react-router';
import configs from 'configuration';
import { useHandleClickOutside } from 'auto-design-common';
import emptyAvatar from 'assets/images/empty-avatar.jpeg';
import Sidebar from './Sidebar';
import Footer from './Footer';
import styles from './Layout.module.scss';
import NotificationDropdown from './NotificationDropdown';

export default function Layout({
  className,
  children,
  contentStyle,
}) {
  const [menuState, setMenuState] = useState(SidebarMenuState.COLLAPSED);
  const [showInfoMenu, setShowInfoMenu] = useState(false);
  const { tSidebar, tCommon } = useTranslation();
  const { user, logout } = useAppContext();
  const history = useHistory();
  const infoRef = useRef();
  const infoIconRef = useRef();

  const toggleInfo = () => {
    setShowInfoMenu(!showInfoMenu);
    setMenuState(SidebarMenuState.COLLAPSED);
  };

  useHandleClickOutside([infoRef, infoIconRef], () => {
    setShowInfoMenu(false);
  });

  return (
    <div className={classNames('main', styles.main, className)}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div
            className={styles.sidebarToggle}
            onClick={() => {
              setMenuState(s => (s === SidebarMenuState.COLLAPSED ? SidebarMenuState.MENU : SidebarMenuState.COLLAPSED));
            }}
          >
            {menuState === SidebarMenuState.COLLAPSED ? (
              <img src={menuIcon} alt="Menu" />
            ) : (
              <img src={closeIcon} alt="Menu" />
            )}
          </div>
          <img
            src={darkLogo}
            alt="Logo"
            className={styles.logo}
            height={42}
          />
        </div>
        <div className={styles.right}>
          <NotificationDropdown />
          <img
            ref={infoIconRef}
            className={styles.avatar}
            alt="Avatar"
            src={user.avatar || emptyAvatar}
            onClick={toggleInfo}
          />
        </div>
        {showInfoMenu && (
        <div className={styles.infoMenu} ref={infoRef}>
          <div
            className={styles.infoMenuItem}
            onClick={() => {
              history.push('/profile');
            }}
          >
            {tSidebar('info.viewProfile')}
          </div>
          <div
            className={styles.infoMenuItem}
            onClick={() => {
              history.push('/api-keys');
            }}
          >
            {tSidebar('info.apiKeys')}
          </div>
          <div
            className={styles.infoMenuItem}
            onClick={() => {
              history.push('/webhooks');
            }}
          >
            {tSidebar('info.webhooks')}
          </div>
          <div
            className={styles.infoMenuItem}
            onClick={() => {
              history.push('/transaction-history');
            }}
          >
            {tSidebar('info.transactions')}
          </div>
          <div
            className={styles.infoMenuItem}
            onClick={() => {
              window.location.href = configs.privacyPolicyUrl;
            }}
          >
            {tCommon('privacyPolicy')}
          </div>
          <div
            className={classNames(styles.infoMenuItem, styles.negative)}
            onClick={logout}
          >
            {tCommon('logOut')}
          </div>
        </div>
        )}
      </div>
      <div className={styles.sideBarAndContent}>
        <Sidebar menuState={menuState} setMenuState={setMenuState} />
        <div className={classNames(styles.rightPart, 'right-part')}>
          <div
            className={classNames(styles.content, 'content')}
            style={contentStyle}
          >
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
