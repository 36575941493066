import Layout from 'components/Common/Layout';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import gradientImg from 'assets/images/services/gradiant.png';
import virtualStagingImages from 'assets/images/services/virtual-staging-images.png';
import floorPlanImages from 'assets/images/services/floor-plan-images.png';
import photoEditingImage from 'assets/images/services/photo-editing.png';
import otherImage from 'assets/images/services/other-image.png';
import useTranslation from 'hooks/useTranslation';
import { Button } from 'react-bootstrap';
import { ReactComponent as ArrowRight } from 'assets/images/ic-line-arrow-right-white.svg';
import config from 'configuration';
import styles from './Services.module.scss';

export default function Services() {
  const { tServices } = useTranslation();
  const history = useHistory();

  return (
    <Layout>
      <div className={classNames('container', styles.container)}>
        <div className={styles.virtualStaging}>
          <img className={styles.background} src={gradientImg} alt="Background" />

          <div className={styles.content}>
            <img className={styles.images} src={virtualStagingImages} alt="Images" />
            <div className={styles.text}>
              <div className={styles.title}>
                {tServices('virutalStaging.title')}
              </div>
              <div className={styles.description}>
                {tServices('virutalStaging.description')}
              </div>
              <Button onClick={() => {
                history.push('/add-design');
              }}
              >
                {tServices('tryItNow')}
              </Button>
            </div>

          </div>
        </div>
        {!config.showOnlyVirtualStaging && (
          <>
            <div className={styles.floorPlan}>
              <div className={styles.text}>
                <div className={styles.title}>
                  {tServices('floorPlan.title')}
                </div>
                <div className={styles.description}>
                  {tServices('floorPlan.description')}
                </div>
                <Button onClick={() => {
                  history.push('/create-floor-plan');
                }}
                >
                  {tServices('tryItNow')}
                </Button>
              </div>
              <img className={styles.images} src={floorPlanImages} alt="Images" />
            </div>
            <div className={styles.lastRow}>
              <div className={classNames(styles.lastRowItem, styles.photoEditing)}>
                <img className={styles.img} src={photoEditingImage} alt="Background" />
                <div className={styles.title}>
                  {tServices('photoEditing.title')}
                </div>
                <div className={styles.description}>
                  {tServices('photoEditing.description')}
                </div>
                <div
                  className={styles.tryItButton}
                  onClick={() => {
                    window.open('https://www.auto-photoediting.ai/');
                  }}
                >
                  <div className={styles.icon}>
                    <ArrowRight />
                  </div>
                  {tServices('tryItNow')}
                </div>
              </div>
              <div className={classNames(styles.lastRowItem, styles.others)}>
                <img className={styles.img} src={otherImage} alt="Background" />
                <div className={styles.title}>
                  {tServices('otherServices.title')}
                </div>
                <div className={styles.description}>
                  {tServices('otherServices.description')}
                </div>
                <div
                  className={styles.tryItButton}
                  onClick={() => {
                    window.open('https://betates.com');
                  }}
                >
                  <div className={styles.icon}>
                    <ArrowRight />
                  </div>
                  {tServices('otherServices.visitBetates')}
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </Layout>
  );
}
