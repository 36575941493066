import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Stepper, Step } from 'react-form-stepper';
import Layout from 'components/Common/Layout';
import { useToastContext } from 'auto-design-common';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import Packages from './Packages';
import FilesSelection from './FilesSelection';
import Information from './Information';
import styles from './CreateFloorPlan.module.scss';
import { getPriceKey } from './utils';

const Screen = {
  PACKAGES: 0,
  FILE_SELECTION: 1,
  INFORMATION: 2,
};

export default function CreateFloorPlan() {
  const { tCreateFloorPlan } = useTranslation();
  const { configs } = useAppContext();
  const { toastError } = useToastContext();
  const [screen, setScreen] = useState(Screen.PACKAGES);
  const [packages, setPackages] = useState([]);
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [info, setInfo] = useState({
    name: '',
    locationName: '',
    selectedLocation: null,
    floors: 1,
    userNotes: '',
  });
  const { getInfo, request } = useAppContext();
  const { showModal } = useModalContext();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const price = useMemo(() => {
    const base = packages.reduce((acc, p) => acc + configs[getPriceKey(p)], 0);

    return base * info.floors;
  }, [packages, configs, info.floors]);

  const onSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      const { result: floorPlan, success, error } = await request(api.createFloorPlan({
        name: info.name,
        packages,
        fileUrls: urls.map(u => ({
          type: 'url',
          url: u.url,
        })),
        floors: info.floors,
        userNotes: info.userNotes,
      }));

      if (!success) {
        throw error;
      }

      // eslint-disable-next-line guard-for-in
      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        const { key } = await api.uploadFile(file.file, false);
        // eslint-disable-next-line no-await-in-loop
        const { error } = await request(api.uploadFloorPlanFile(floorPlan.id, {
          fileKey: key,
          fileType: file.file.type,
          fileName: file.file.name,
        }));
        if (error) {
          throw error;
        }
      }

      const { error: finishUploadError } = await request(api.finishUploadFloorPlan(floorPlan.id));

      if (finishUploadError) {
        throw finishUploadError;
      }

      getInfo();
      showModal(ModalKey.FILES_UPLOADED, {
        onClose: () => {
          history.push(`/floor-plans/${floorPlan.id}`);
        },
      });
    } catch (e) {
      toastError(e);
    }

    setSubmitting(false);
  }, [getInfo, info, request, toastError, urls, files, showModal, packages, history]);

  return (
    <Layout>
      <div className={styles.totalPrice}>
        {tCreateFloorPlan('totalPrice')}
        {' '}
        <span className={styles.number}>
          {price}
          {' '}
          x
          {' '}
        </span>
        <CoinIcon />
      </div>
      <div className={styles.stepperWrapper}>

        <div className={styles.stepper}>
          <Stepper
            activeStep={screen}
            styleConfig={{
              activeBgColor: '#ff9c34',
              completedBgColor: '#fcb86f',
            }}
            connectorStyleConfig={{
              activeColor: '#fcb86f',
              completedColor: '#fcb86f',
            }}
            connectorStateColors
          >
            <Step
              label="Packages"
              onClick={() => {
                setScreen(Screen.PACKAGES);
              }}
            />
            <Step
              label="Files"
              onClick={() => {
                setScreen(Screen.FILE_SELECTION);
              }}
            />
            <Step label="Information" />
          </Stepper>
        </div>
      </div>

      {screen === Screen.PACKAGES && (
        <Packages
          packages={packages}
          setPackages={setPackages}
          onContinue={() => {
            setScreen(Screen.FILE_SELECTION);
          }}
        />
      )}

      {screen === Screen.FILE_SELECTION && (
        <FilesSelection
          files={files}
          setFiles={setFiles}
          urls={urls}
          setUrls={setUrls}
          onContinue={() => {
            setScreen(Screen.INFORMATION);
          }}
        />
      )}
      {screen === Screen.INFORMATION && (
        <Information
          packages={packages}
          setPackages={setPackages}
          files={files}
          urls={urls}
          info={info}
          setInfo={setInfo}
          onSubmit={onSubmit}
          submitting={submitting}
        />
      )}
    </Layout>
  );
}
