import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from 'components/Common/Layout';
import Empty from './Empty';
import Location from './Location';

export default function Locations() {
  return (
    <Layout className="locations">
      <Switch>
        <Route path="/locations/empty">
          <Empty />
        </Route>
        <Route path="/locations/:locationId">
          <Location />
        </Route>
        <Redirect to="/locations/empty" />
      </Switch>
    </Layout>
  );
}
