import React, { useContext, useMemo, useState, useCallback } from 'react';
import { ModalKey } from 'constants/modal';
import TopUp from './Modals/TopUp';
import Checkout from './Modals/Checkout';
import ItemDetail from './Modals/ItemDetail';
import EditLocation from './Modals/EditLocation';
import DeleteLocation from './Modals/DeleteLocation';
import WhatWeDo from './Modals/WhatWeDo';
import ContactForService from './Modals/ContactForService';
import ImageUploaded from './Modals/ImageUploaded';
import VersionHistory from './Modals/VersionHistory';
import EditImage from './Modals/EditImage';
import ConfirmSwitchingVersion from './Modals/ConfirmSwitchingVersion';
import PanoImageIntro from './Modals/PanoImageIntro';
import ResetPassword from './Modals/ResetPassword';
import TourPublishSuccessfully from './Modals/TourPublishSuccessfully';
import VRNotSupported from './Modals/VRNotSupported';
import TourVersions from './Modals/TourVersions';
import AddWatermark from './Modals/AddWatermark';
import InviteFriends from './Modals/InviteFriends';
import AddApiKey from './Modals/AddAPIKey';
import AddWebhook from './Modals/AddWebhook';
import AutoStagingSelectBundles from './Modals/AutoStagingSelectBundles';
import FilesUploaded from './Modals/FilesUploaded';
import FloorPlan3DVersionHistory from './Modals/FloorPlan3DVersionHistory';
import FloorPlan2dStyles from './Modals/FloorPlan2dStyles';
import ConfirmPayExtraService from './Modals/ConfirmPayExtraService';
import FloorPlan2DPackages from './Modals/FloorPlan2DPackages';
import ConfirmPurchaseExtraCamera from './Modals/ConfirmPurchaseExtraCamera';

const ModalContext = React.createContext();

const MODAL_MAP = {
  [ModalKey.TOP_UP]: TopUp,
  [ModalKey.CHECKOUT]: Checkout,
  [ModalKey.ITEM_DETAIL]: ItemDetail,
  [ModalKey.EDIT_LOCATION]: EditLocation,
  [ModalKey.DELETE_LOCATION]: DeleteLocation,
  [ModalKey.WHAT_WE_DO]: WhatWeDo,
  [ModalKey.CONTACT_FOR_SERVICE]: ContactForService,
  [ModalKey.IMAGE_UPLOADED]: ImageUploaded,
  [ModalKey.VERSION_HISTORY]: VersionHistory,
  [ModalKey.EDIT_IMAGE]: EditImage,
  [ModalKey.CONFIRM_SWITCHING_VERSION]: ConfirmSwitchingVersion,
  [ModalKey.PANO_IMAGE_INTRO]: PanoImageIntro,
  [ModalKey.RESET_PASSWORD]: ResetPassword,
  [ModalKey.TOUR_PUBLISH_SUCCESSFULLY]: TourPublishSuccessfully,
  [ModalKey.VR_NOT_SUPPORTED]: VRNotSupported,
  [ModalKey.TOUR_VERSIONS]: TourVersions,
  [ModalKey.ADD_WATERMARK]: AddWatermark,
  [ModalKey.INVITE_FRIENDS]: InviteFriends,
  [ModalKey.ADD_API_KEY]: AddApiKey,
  [ModalKey.ADD_WEBHOOK]: AddWebhook,
  [ModalKey.AUTO_STAGING_SELECT_BUNDLES]: AutoStagingSelectBundles,
  [ModalKey.FILES_UPLOADED]: FilesUploaded,
  [ModalKey.FLOOR_PLAN_3D_VERSION_HISTORY]: FloorPlan3DVersionHistory,
  [ModalKey.FLOOR_PLAN_2D_STYLES]: FloorPlan2dStyles,
  [ModalKey.CONFIRM_PAY_EXTRA_SERVICE]: ConfirmPayExtraService,
  [ModalKey.FLOOR_PLAN_2D_PACKAGES]: FloorPlan2DPackages,
  [ModalKey.CONFIRM_PURCHASE_EXTRA_CAMERA]: ConfirmPurchaseExtraCamera,
};

export function ModalContextProvider({
  children,
}) {
  const [modal, setModal] = useState(null);

  const showModal = useCallback((key, props) => {
    setModal({
      key,
      props,
    });
  }, [setModal]);

  const contextValue = useMemo(() => ({
    modal,
    showModal,
    setModal,
  }), [
    modal,
    showModal,
    setModal,
  ]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  return useContext(ModalContext);
}

export function ModalContainer() {
  const { modal, setModal, showModal } = useModalContext();

  const onClose = useCallback((...params) => {
    modal.props?.onClose?.(...params);
    setModal(null);
  }, [modal, setModal]);

  const ModalComponent = MODAL_MAP[modal?.key];

  if (!ModalComponent) {
    return null;
  }

  return (
    <ModalComponent
      {...modal.props}
      onClose={onClose}
      showModal={showModal}
    />
  );
}
