import { useTranslation as useTranslationOrigin } from 'react-i18next';
import i18next from 'i18next';

export default function useTranslation() {
  const { t: tGetStarted } = useTranslationOrigin('translation', { keyPrefix: 'auth.getStarted' });
  const { t: tLogin } = useTranslationOrigin('translation', { keyPrefix: 'auth.login' });
  const { t: tSignUp } = useTranslationOrigin('translation', { keyPrefix: 'auth.signup' });
  const { t: tResetPassword } = useTranslationOrigin('translation', { keyPrefix: 'auth.resetPassword' });
  const { t: tHomePage } = useTranslationOrigin('translation', { keyPrefix: 'homepage' });
  const { t: tLocations } = useTranslationOrigin('translation', { keyPrefix: 'location' });
  const { t: tProgress } = useTranslationOrigin('translation', { keyPrefix: 'progress' });
  const { t: tCatalog } = useTranslationOrigin('translation', { keyPrefix: 'catalog' });
  const { t: tProfile } = useTranslationOrigin('translation', { keyPrefix: 'profile' });
  const { t: tAPIKeys } = useTranslationOrigin('translation', { keyPrefix: 'apiKeys' });
  const { t: tWebhooks } = useTranslationOrigin('translation', { keyPrefix: 'webhooks' });
  const { t: tAddDesign } = useTranslationOrigin('translation', { keyPrefix: 'addDesign' });
  const { t: tImagesResult } = useTranslationOrigin('translation', { keyPrefix: 'imagesView' });
  const { t: tTourEditor } = useTranslationOrigin('translation', { keyPrefix: 'tourEditor' });
  const { t: tMyTours } = useTranslationOrigin('translation', { keyPrefix: 'myTours' });
  const { t: tPaymentSuccessful } = useTranslationOrigin('translation', { keyPrefix: 'paymentSuccessful' });

  const { t: tPanoViewer } = useTranslationOrigin('translation', { keyPrefix: 'panoViewer' });
  const { t: tSidebar } = useTranslationOrigin('translation', { keyPrefix: 'sidebar' });

  const { t: tCommon } = useTranslationOrigin('translation', { keyPrefix: 'common' });
  const { t: tError } = useTranslationOrigin('translation', { keyPrefix: 'error' });
  const { t: tSuccess } = useTranslationOrigin('translation', { keyPrefix: 'success' });

  const { t: tBetates } = useTranslationOrigin('translation', { keyPrefix: 'betates' });

  const { t: tApiError } = useTranslationOrigin('translation', { keyPrefix: 'apiError' });

  const { t: tCreateFloorPlan } = useTranslationOrigin('translation', {
    keyPrefix: 'createFloorPlan',
  });

  const { t: tFloorplanOverview } = useTranslationOrigin('translation', {
    keyPrefix: 'floorplanOverview',
  });

  const { t: tEditor } = useTranslationOrigin('translation', {
    keyPrefix: 'editor',
  });

  const { t: t2dEditor } = useTranslationOrigin('translation', {
    keyPrefix: 'editor2d',
  });

  const { t: tFloorPlanStatus } = useTranslationOrigin('translation', {
    keyPrefix: 'floorPlanStatus',
  });

  const { t: tStatusBadge } = useTranslationOrigin('translation', {
    keyPrefix: 'statusBadge',
  });

  const { t: tFloorPlanPackageShort } = useTranslationOrigin('translation', {
    keyPrefix: 'floorPlanPackageShort',
  });

  const { t: tFloorPlans } = useTranslationOrigin('translation', {
    keyPrefix: 'floorPlans',
  });

  const { t: tFloorPlan2dStylesModal } = useTranslationOrigin('translation', {
    keyPrefix: 'modal.floorPlan2dStyles',
  });

  const { t: tConfirmPayExtraService } = useTranslationOrigin('translation', {
    keyPrefix: 'modal.confirmPayExtraService',
  });

  const { t: tServices } = useTranslationOrigin('translation', {
    keyPrefix: 'services',
  });

  const { t: tCameraList } = useTranslationOrigin('translation', { keyPrefix: 'editor.cameraList' });

  const { t: tConfirmPurchaseExtraCamera } = useTranslationOrigin('translation', {
    keyPrefix: 'modal.confirmPurchaseExtraCamera',
  });

  const { t: tTransactionHistory } = useTranslationOrigin('translation', {
    keyPrefix: 'transactionHistory',
  });

  const { t: tNotification } = useTranslationOrigin('translation', {
    keyPrefix: 'notification',
  });

  return {
    useTranslationOrigin,

    tGetStarted,
    tLogin,
    tSignUp,
    tResetPassword,
    tHomePage,
    tLocations,
    tProgress,
    tCatalog,
    tProfile,
    tAPIKeys,
    tWebhooks,
    tAddDesign,
    tImagesResult,
    tTourEditor,
    tMyTours,
    tPaymentSuccessful,

    tSidebar,
    tPanoViewer,

    tCommon,
    tError,
    tSuccess,

    tBetates,

    tApiError,
    tCreateFloorPlan,
    tFloorplanOverview,
    tEditor,
    t2dEditor,
    tFloorPlanStatus,
    tStatusBadge,
    tFloorPlanPackageShort,
    tFloorPlans,
    tFloorPlan2dStylesModal,
    tConfirmPayExtraService,
    tServices,
    tCameraList,
    tConfirmPurchaseExtraCamera,
    tTransactionHistory,
    tNotification,
  };
}

export function getTranslation(translations, fallback) {
  const lang = i18next.resolvedLanguage;

  const found = translations?.find(t => t.language === lang);

  return found?.text || fallback;
}

export const i18n = i18next;
