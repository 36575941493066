import React, { useRef, useState, useMemo, useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getBase64Strings } from 'exif-rotate-js';
import classNames from 'classnames';
import { useToastContext } from 'auto-design-common';
import { Button } from 'react-bootstrap';
import DropFileContainer from 'components/Common/DropFileContainer';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as FileIcon } from 'assets/images/create-floor-plan/file.svg';
import { ReactComponent as CloseButtonIcon } from 'assets/images/create-floor-plan/close-button.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/ic-line-download.svg';
import isURL from 'validator/lib/isURL';
import styles from './FloorPlanFiles.module.scss';

const FloorPlanContext = React.createContext();

export function FloorPlanFilesProvider({
  files,
  setFiles,
  urls,
  setUrls,
  children,
}) {
  const { tError } = useTranslation();
  const { toastError } = useToastContext();
  const [newUrl, setNewUrl] = useState('');
  const [newUrlError, setNewUrlError] = useState(null);
  const [urlError, setUrlError] = useState({});

  const submittedNewUrlRef = useRef(false);

  const submitNewUrl = useCallback((e) => {
    if (submittedNewUrlRef.current) {
      return;
    }

    if (!newUrl.trim()) {
      return;
    }

    if (isURL(newUrl.trim())) {
      setUrls(urls => [...urls, {
        id: uuidv4(),
        url: newUrl.trim(),
      }]);
      setNewUrl('');
      setNewUrlError(null);
      submittedNewUrlRef.current = true;
      e.target.blur();
      setTimeout(() => {
        submittedNewUrlRef.current = false;
      });
    } else {
      setNewUrlError('Invalid URL');
    }
  }, [newUrl, setUrls]);

  const handleSelectFile = useCallback(async (file) => {
    if (file.size > 200000000) {
      toastError(tError('fileTooLarge200MB'));
    }

    if (['image/png', 'image/jpeg'].includes(file.type)) {
      const [data] = await getBase64Strings([file], {
        maxSize: 20000,
        quality: 1,
      });

      setFiles(files => [...files, {
        id: uuidv4(),
        file,
        thumbnailData: data,
      }]);
    } else {
      setFiles(files => [...files, {
        id: uuidv4(),
        file,
      }]);
    }
  }, [setFiles, tError, toastError]);

  const disabled = newUrlError || Object.values(urlError).filter(e => !!e).length > 0 || (files.length + urls.length === 0);

  const value = useMemo(() => ({
    newUrl,
    setNewUrl,
    newUrlError,
    setNewUrlError,
    urlError,
    setUrlError,
    files,
    setFiles,
    urls,
    setUrls,
    disabled,
    submitNewUrl,
    handleSelectFile,
  }), [newUrl,
    setNewUrl,
    newUrlError,
    setNewUrlError,
    urlError,
    setUrlError,
    files,
    setFiles,
    urls,
    setUrls,
    disabled,
    submitNewUrl,
    handleSelectFile,
  ]);

  return (
    <FloorPlanContext.Provider value={value}>
      {children}
    </FloorPlanContext.Provider>
  );
}

const useFloorPlanContext = () => React.useContext(FloorPlanContext);

export function FloorPlanFiles({
  readOnly,
}) {
  const {
    newUrl,
    setNewUrl,
    newUrlError,
    setNewUrlError,
    urlError,
    setUrlError,
    files,
    setFiles,
    urls,
    setUrls,
    submitNewUrl,
    handleSelectFile,
  } = useFloorPlanContext();
  const fileInputRef = useRef();
  const { tCreateFloorPlan } = useTranslation();

  return (
    <>
      <input
        multiple
        ref={fileInputRef}
        type="file"
        style={{
          display: 'none',
        }}
        onChange={async (e) => {
          for (const file of e.target.files) {
          // eslint-disable-next-line no-await-in-loop
            await handleSelectFile(file);
          }
          e.target.value = '';
        }}
      />
      <DropFileContainer
        onDropFile={async (files) => {
          for (const file of files) {
            // eslint-disable-next-line no-await-in-loop
            await handleSelectFile(file);
          }
        }}
      >
        {() => (
          <div className={styles.dropArea}>
            {!readOnly && (
            <div className={styles.instruction}>
              {' '}
              {tCreateFloorPlan('filesSelection.drag')}
              {' '}
              <Button
                className={styles.browseButtton}
                variant="link"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                {tCreateFloorPlan('filesSelection.browse')}
              </Button>
            </div>
            )}

            {files.length > 0 && (
              <div className={styles.files}>
                {files.map(file => (
                  <div key={file.id} className={styles.file}>
                    <div className={styles.thumbnail}>
                      {file.downloadUrl && (
                        <a href={file.downloadUrl} className={styles.downloadButton}>
                          <DownloadIcon />
                        </a>
                      )}

                      {!file.readOnly && (
                        <div
                          className={styles.removeFileButton}
                          onClick={() => {
                            setFiles(files => files.filter(f => f.id !== file.id));
                          }}
                        >
                          <CloseButtonIcon />
                        </div>
                      )}
                      {!file.thumbnailData && (
                      <div className={styles.defaultThumbnail}>
                        <FileIcon />
                      </div>
                      )}
                      {file.thumbnailData && (
                        <img src={file.thumbnailData} alt={file.name} className={styles.image} />
                      )}
                    </div>
                    <div className={styles.name}>
                      {file.file.name}
                    </div>
                  </div>
                ))}
              </div>
            )}

          </div>

        )}

      </DropFileContainer>
      <div className={styles.urls}>
        {urls.map((url) => (
          <div key={url.id}>
            {url.readOnly && (
            <div className={classNames(styles.url, styles.readonlyUrl)}>
              <a href={url.url} target="_blank" rel="noreferrer">
                {url.url}
              </a>
            </div>
            )}
            {!url.readOnly && (
            <input
              className={classNames(styles.url, urlError[url.id] && styles.urlError)}
              placeholder={tCreateFloorPlan('filesSelection.addMatterpotUrl')}
              value={url.url}
              onChange={(e) => {
                if (e.target.value.trim() === '') {
                  setUrls(urls => urls.filter(u => u.id !== url.id));
                } else {
                  setUrls(urls => urls.map(u => {
                    if (u.id === url.id) {
                      return {
                        ...u,
                        url: e.target.value,
                      };
                    }

                    return u;
                  }));

                  if (!isURL(e.target.value.trim())) {
                    setUrlError({
                      ...urlError,
                      [url.id]: 'Invalid URL',
                    });
                  } else {
                    setUrlError({
                      ...urlError,
                      [url.id]: undefined,
                    });
                  }
                }
              }}
              onBlur={() => {
                setUrls(urls => urls.map(u => {
                  if (u.id === url.id) {
                    return {
                      ...u,
                      url: u.url.trim(),
                    };
                  }

                  return u;
                }));
              }}
            />
            )}

          </div>
        ))}

        {!readOnly && (
          <input
            className={classNames(styles.url, newUrlError && styles.urlError)}
            placeholder={tCreateFloorPlan('filesSelection.addMatterpotUrl')}
            onChange={(e) => {
              setNewUrl(e.target.value);
              setNewUrlError(null);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                submitNewUrl(e);
              }
            }}
            onBlur={(e) => {
              submitNewUrl(e);
            }}
            value={newUrl}
          />
        )}

      </div>
      {newUrlError && <div className={styles.newUrlErrorMessage}>{newUrlError}</div>}
    </>
  );
}

export function ContinueButtonWrapper({ children }) {
  const { disabled } = useFloorPlanContext();

  return children({ disabled });
}
