import React from 'react';
import { FloorPlanStatus } from 'auto-design-common';
import { ExtraCostApprovalBadge, FailedBadge, ProcessingBadge, ReadyBadge, RefundedBadge, RequestFilesBadge, RequestRefundBadge } from './StatusBadge';

const MAP = {
  [FloorPlanStatus.CREATED]: ProcessingBadge,
  [FloorPlanStatus.READY]: ReadyBadge,
  [FloorPlanStatus.FAILED]: FailedBadge,
  [FloorPlanStatus.REFUNDED]: RefundedBadge,
  [FloorPlanStatus.EXTRA_COST_APPROVAL]: ExtraCostApprovalBadge,
  [FloorPlanStatus.REQUEST_REFUND]: RequestRefundBadge,
  [FloorPlanStatus.REQUEST_FILES]: RequestFilesBadge,
};

export default function FloorPlanStatusBadge({ status }) {
  const Comp = MAP[status];

  if (!Comp) {
    return null;
  }

  return <Comp />;
}
