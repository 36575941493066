import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useTranslation from '../../hooks/useTranslation';

export default function ImageUploaded({
  onClose,
}) {
  const { useTranslationOrigin } = useTranslation();
  const { t: tImageUploadModal } = useTranslationOrigin('translation', { keyPrefix: 'modal.imageUpload' });

  const history = useHistory();

  return (
    <Modal
      show
      backdrop="static"
      id="modal-delete-location"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tImageUploadModal('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {tImageUploadModal('content_1')}
        </div>
        <div>
          {tImageUploadModal('content_2')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            history.push('/add-design');
            onClose();
          }}
        >
          {tImageUploadModal('uploadMore')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
