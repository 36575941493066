import React from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Modal } from 'react-bootstrap';
import config from 'configuration';
import ReactLoading from 'components/Common/ReactLoading';
import { createTopUpOrder, captureOrder } from 'utils/api';
import { useAppContext } from 'components/AppContext';
import { useHistory } from 'react-router';
import useTranslation from '../../hooks/useTranslation';

function Checkout({
  onClose,
  quantity,
  onDone,
}) {
  const { useTranslationOrigin } = useTranslation();
  const { t: tCheckout } = useTranslationOrigin('translation', { keyPrefix: 'modal.checkout' });

  const [{ isPending }] = usePayPalScriptReducer();
  const { getInfo } = useAppContext();
  const history = useHistory();

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-check-out"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tCheckout('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PayPalButtons
          style={{ layout: 'vertical' }}
          createOrder={async () => {
            const { paypalOrderId } = await createTopUpOrder(quantity);
            return paypalOrderId;
          }}
          onApprove={async (data) => {
            await captureOrder(data.orderID);
            await getInfo();
            if (onDone) {
              onDone();
            } else {
              history.push('/payment-successful');
            }
            onClose();
          }}
        />
        {(isPending) && (
          <div className="full-div-loading">
            <ReactLoading />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default (props) => {
  const { configs } = useAppContext();
  return (
    <PayPalScriptProvider options={{
      'client-id': config.paypalClientId,
      currency: configs.currency_code,
    }}
    >
      <Checkout {...props} />
    </PayPalScriptProvider>
  );
};
