import React, { useState } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { Icon } from 'auto-design-common';
import searchIcon from 'assets/images/ic-search.svg';
import xIcon from 'assets/images/ic-line-x-mark.svg';
import useTranslation from '../../../hooks/useTranslation';

export default function SelectLinkedImage({
  onCancel,
  renderedImages,
  onSelect,
}) {
  const { tTourEditor } = useTranslation();
  const [searchText, setSearchText] = useState('');

  return (
    <div className="select-linked-image">
      <div className="select-linked-image-header">
        <div className="title">
          {tTourEditor('selectLinkedImage.title')}
        </div>
        <div
          className="cancel"
          onClick={onCancel}
        >
          <Icon
            src={xIcon}
            fill="#212529"
          />
        </div>
      </div>
      <Form.Group className="form-group search">
        <InputGroup className="search-input-group">
          <InputGroup.Text>
            <img
              alt="showPassword"
              className="u-cursorPointer"
              src={searchIcon}
            />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
      <div className="linked-images">
        {renderedImages
          .filter(image => image.name.toLowerCase().includes(searchText.trim().toLowerCase()))
          .map(image => (
            <div
              key={image.id}
              className="image"
              onClick={(e) => {
                onSelect(e, image);
              }}
            >
              <img
                src={image.thumbnail}
                alt={image.name}
              />
              <div className="title">
                {image.name}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
