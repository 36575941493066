import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import React, { useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import styles from './FloorPlan2DPakages.module.scss';

function PackageItem({ selected, onClick, name, img, price }) {
  return (
    <div
      className={classNames(styles.package, selected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.radio} />
          <div className={styles.name}>
            {name}
          </div>

        </div>
        {' '}
        <div className={styles.price}>
          <div className={styles.number}>
            {price}
            {' '}
            x
            {' '}
          </div>
          <CoinIcon />
        </div>
      </div>
      <div className={styles.thumbnail}>
        <img src={img} alt={name} />
      </div>
    </div>
  );
}

export default function FloorPlan2DPackages({
  packages,
  onClose,
  onSelect,
  defaultSelectedPackage,
}) {
  const { tCreateFloorPlan } = useTranslation();
  const [selectedPackage, setSelectedPackage] = useState(defaultSelectedPackage);

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tCreateFloorPlan('packages.changePreference')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {packages.map(p => (
            <div className="col-md-6">
              <PackageItem
                key={p.id}
                name={p.name}
                img={p.img}
                selected={p === selectedPackage}
                onClick={() => {
                  setSelectedPackage(p);
                }}
                price={p.price}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          onSelect(selectedPackage);
          onClose();
        }}
        >
          {tCreateFloorPlan('packages.select')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
