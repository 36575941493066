import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ReactComponent as CoinIcon } from 'assets/images/Auto-Coin.svg';
import useTranslation from '../../hooks/useTranslation';

export default function ConfirmPayExtraService({
  onClose,
  price,
  onAccept,
}) {
  const [submitting, setSubmitting] = useState(false);
  const { tConfirmPayExtraService, tCommon } = useTranslation();

  return (
    <Modal
      show
      backdrop="static"
      id="modal-delete-location"
      size="md"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {tConfirmPayExtraService('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tConfirmPayExtraService('description')}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          variant="secondary"
          onClick={onClose}
        >
          {tCommon('cancel')}
        </Button>
        <Button
          disabled={submitting}
          variant="danger"
          onClick={async () => {
            setSubmitting(true);
            await onAccept();
            onClose();
          }}
        >
          {tConfirmPayExtraService('pay')}
          {' '}
          {price}
          {' x '}
          <CoinIcon />
        </Button>

      </Modal.Footer>
    </Modal>
  );
}
